import type { IAsset } from '../../types/stac-types';
import {
  getFilenameFromURL,
  downloadFileFromURL,
  copyToClipBoard,
} from 'utils/common/CommonUtils';
import type { TrackingEvent } from 'utils/hooks/analytics/useAnalytics';
import { Button, Icon, Tooltip } from 'opencosmos-ui';
import classNames from 'classnames';
import {
  hasAssetRoleOverview,
  hasAssetRoleThumbnail,
} from 'datacosmos/utils/stac';
import { useLocalisation } from 'utils/hooks/useLocalisation';

const getAssetTypeIcon = (assetType: string) => {
  if (assetType.includes('xml') || assetType.includes('json')) {
    return 'FileTxt';
  }
  return 'File';
};

export const AssetItem = (props: {
  previewable: boolean;
  itemID: string;
  id: string;
  data: IAsset;
  sendInfo: (ev: TrackingEvent) => void;
  isAssetDisplayed: (id: string) => boolean;
  handleToggleDisplayAsset: (id: string) => void;
  isHighResPermissionGranted: boolean;
  collectionID?: string;
  onDownload?: (href: string, filename: string) => string | void;
  token?: string;
}) => {
  const {
    previewable,
    itemID,
    id,
    data,
    collectionID,
    sendInfo,
    handleToggleDisplayAsset,
    isAssetDisplayed,
    onDownload = downloadFileFromURL,
    token,
  } = props;
  const { translate } = useLocalisation();
  const title = data.title ?? id;
  const isDownloadDisabled =
    !hasAssetRoleOverview(data) &&
    !hasAssetRoleThumbnail(data) &&
    !props.isHighResPermissionGranted;
  const filename = `${itemID}_${getFilenameFromURL(data.href) ?? ''}`;
  const openInNewTab = data.roles?.some((role) =>
    ['metadata', 'thumbnail'].includes(role)
  );
  return (
    <div className="w-full flex justify-between gap-1">
      <Tooltip
        content={translate(
          `datacosmos.catalogAndItems.assets.${
            openInNewTab ? 'openInNewTab' : 'download'
          }`
        )}
        className="block flex-1 overflow-hidden"
        isDisabled={isDownloadDisabled}
      >
        <a
          className={classNames(
            {
              'pointer-events-none': isDownloadDisabled,
            },
            'py-1 hover:bg-item-hover dark:hover:bg-item-dark-hover flex justify-start gap-2 text-sm items-center flex-1'
          )}
          href={isDownloadDisabled ? '#' : data.href}
          key={data.href}
          target="_blank"
          rel="noreferrer"
          download={openInNewTab ? undefined : filename}
          data-testid="asset-title"
        >
          <Icon icon={getAssetTypeIcon(data?.type ?? 'File')} />
          <span className="text-item-contrast dark:text-item-dark-contrast flex-1 overflow-hidden text-ellipsis">
            {title}
          </span>
        </a>
      </Tooltip>
      <div className="flex items-center gap-1">
        {previewable && (
          <Tooltip
            content={
              isAssetDisplayed(id)
                ? translate('datacosmos.catalogAndItems.assets.removeFromMap')
                : translate('datacosmos.catalogAndItems.assets.addToMap')
            }
          >
            <a
              className="px-2 py-1 hover:bg-item-hover dark:hover:bg-item-dark-hover flex justify-start gap-2 text-sm items-center h-full"
              data-testid="view-asset-button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleToggleDisplayAsset(id);
              }}
            >
              <Icon icon={isAssetDisplayed(id) ? 'EyeCrossed' : 'Eye'} />
            </a>
          </Tooltip>
        )}
        {previewable && token && (
          <Tooltip
            content={translate(
              'datacosmos.catalogAndItems.assets.copyLinkForGISTool'
            )}
            isDisabled={isDownloadDisabled}
          >
            <Button
              iconOnly
              icon={'ArrowExport'}
              isMinimal
              isTransparent
              size="base"
              className={classNames('p-1', {
                'opacity-50 pointer-events-none': isDownloadDisabled,
              })}
              onPress={() => {
                copyToClipBoard(`${data.href}?token=${token}`);
              }}
              isDisabled={isDownloadDisabled}
            />
          </Tooltip>
        )}
        <Tooltip
          content={translate('datacosmos.catalogAndItems.assets.download')}
          isDisabled={isDownloadDisabled}
        >
          <a
            className={classNames(
              {
                'opacity-50 pointer-events-none': isDownloadDisabled,
              },
              'px-2 py-1 hover:bg-item-hover dark:hover:bg-item-dark-hover flex justify-start gap-2 text-sm items-center h-full'
            )}
            download={filename}
            data-testid="download-asset-button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (collectionID && !isDownloadDisabled) {
                onDownload(data.href, filename);
                sendInfo({
                  action: `Download asset`,
                  item: 'Download asset button',
                  type: 'Click',
                  module: 'DataCosmos',
                  dimensions: {
                    dimension1: `${collectionID}/${itemID}`,
                    dimension3: `${itemID}`,
                  },
                  download: data.href,
                  additionalParams: {
                    item: itemID,
                    asset: title,
                  },
                });
              }
            }}
          >
            <Icon icon="Download" />
          </a>
        </Tooltip>
      </div>
    </div>
  );
};
export default AssetItem;
