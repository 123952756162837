import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { PERMISSION_TYPE_LIST } from '_api/administration/permissions';
import type { PermissionType } from '_api/administration/permissions';
import { useState } from 'react';
import type {
  IRole,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';
import { toaster } from 'toaster';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import ResourceAndRoleAllocation from '../../../../shared/components/ResourceAndRoleAllocation';

interface IProps {
  getAvailableRolesByType: (type: PermissionType) => IRole[];
  user: IUserWithPermissions;
  setResourceId: (resourceId: string | number) => void;
  setSelectedPermission: (permission: PermissionType) => void;
  selectedPermission?: PermissionType;
  resourceId: string | number;
  assignRole: (userId: string, roleIds: number[]) => Promise<void>;
  assignGlobalRole: (userId: string, roleIds: number[]) => Promise<void>;
  getAssignments: () => Promise<void>;
}

const AddPermission = ({
  getAvailableRolesByType,
  user,
  setResourceId,
  setSelectedPermission,
  selectedPermission,
  resourceId,
  assignRole,
  assignGlobalRole,
  getAssignments,
}: IProps) => {
  const [selectedRole, setSelectedRole] = useState<IRole | null>(null);

  const handleAdd = async () => {
    if (selectedPermission === 'global') {
      if (!selectedRole) {
        toaster.show({
          message: 'Select a role',
          intent: Intent.DANGER,
          icon: IconNames.ERROR,
        });
        return;
      }
      await assignGlobalRole(user.id, [selectedRole.id]);

      void getAssignments();

      toaster.show({
        message: clientTranslate('userPage.assignedPermissions.success.added'),
        intent: Intent.SUCCESS,
      });
      return;
    }

    if (resourceId === '') {
      toaster.show({
        message: clientTranslate(
          'userPage.assignedPermissions.errors.notEmpty'
        ),
        intent: Intent.DANGER,
        icon: IconNames.ERROR,
      });
      return;
    }

    if (!selectedRole) {
      toaster.show({
        message: clientTranslate('userPage.assignedPermissions.selectARole'),
        intent: Intent.DANGER,
        icon: IconNames.ERROR,
      });
      return;
    }

    await assignRole(user.id, [selectedRole.id]);
    toaster.show({
      message: clientTranslate('userPage.assignedPermissions.success.added'),
      intent: Intent.SUCCESS,
    });

    void getAssignments();
    return;
  };

  const permissionTypes = PERMISSION_TYPE_LIST as unknown as PermissionType[];

  return (
    <div>
      <h1 className="font-bold text-lg mt-5 border-t py-2">
        {clientTranslate('userPage.addResource')}
      </h1>
      <ResourceAndRoleAllocation
        getAvailableRolesByType={getAvailableRolesByType}
        setResourceId={setResourceId}
        setSelectedPermission={setSelectedPermission}
        selectedPermission={selectedPermission}
        resourceId={resourceId}
        handleAdd={handleAdd}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        permissionTypes={permissionTypes}
      />
    </div>
  );
};

export default AddPermission;
