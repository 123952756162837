import {
  Button,
  Input,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
  TextField,
} from 'opencosmos-ui';
import type {
  Organisation,
  OrganisationJoinPolicy,
} from '_api/administration/types';
import { updateOrganisation } from '_api/organisations/service';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  organisation?: Organisation;
  disableBilling: boolean;
  refetchOrganisation: () => void;
}

const AdministrationOrganisationDetails = (props: IProps) => {
  const { translate } = useLocalisation();

  return (
    <div>
      <h2 className="text-lg mt-6">{translate('organisationsPage.details')}</h2>
      <div className="pl-2">
        <h3 className="font-bold mt-4">
          {translate('organisationsPage.discoverability')}
        </h3>
        <Select
          className="mt-2 w-full"
          label={translate('organisationsPage.joinPolicy')}
          description={translate('organisationsPage.joinPolicyDescription')}
          selectedKey={props.organisation?.join_policy}
          fill
          onSelectionChange={async (policy) => {
            const res = await updateOrganisation({
              params: {
                id: props.organisation?.id.toString()!,
              },
              body: {
                join_policy: policy as unknown as OrganisationJoinPolicy,
              },
            });

            if (res.status === 200) {
              props.refetchOrganisation();
            }
          }}
        >
          <Button icon="ChevronDown" iconPlacement="right" className="w-full">
            <SelectValue />
          </Button>
          <Popover
            className="shadow-lg bg-neutral-100 overflow-auto"
            maxHeight={250}
          >
            <ListBox>
              <ListBoxItem id="JOIN">
                {translate(
                  'onboarding.createOrganisation.form.fields.howToJoin.options.join'
                )}
              </ListBoxItem>
              <ListBoxItem id="REQUEST">
                {translate(
                  'onboarding.createOrganisation.form.fields.howToJoin.options.requestToJoin'
                )}
              </ListBoxItem>
              <ListBoxItem id="INVITE">
                {translate(
                  'onboarding.createOrganisation.form.fields.howToJoin.options.onInvite'
                )}
              </ListBoxItem>
            </ListBox>
          </Popover>
        </Select>
        <TextField
          className="mt-2"
          label={translate('organisationsPage.domain')}
          value={props.organisation?.domain}
          isDisabled
        >
          <Input type="text" />
        </TextField>
      </div>
    </div>
  );
};

export default AdministrationOrganisationDetails;
