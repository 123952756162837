const translation = {
  common: {
    project_name: 'PROJECTNAME',
    lorem_ipsum:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel lacus diam. Nunc egestas, elit ut pretium dapibus, nisi purus hendrerit velit, et tincidunt turpis massa non ante. Donec eget nunc tempor, ultricies libero pulvinar, faucibus dolor.',
  },
  currentTime: 'Current Time',
  validation: {
    header: {
      GS: 'Ground Stations',
      PI: 'Points of Interest',
      RI: 'Region of Interest',
    },
    point: {
      lat: 'Latitude',
      lng: 'Longitude',
    },
    'paths-field': {
      regions: 'Regions',
      draw_title: 'Draw new region use drawing manager',
    },
    'path-field': {
      region: 'Region',
    },
    'text-field': {
      names: {
        name: 'Name',
        altitude: 'Altitude',
        elevationAngle: 'Elevation Angle',
        tle1: 'TLE1',
        tle2: 'TLE2',
        date: 'Date',
        semimajorAxis: 'Semi-major Axis',
        eccentricity: 'Eccentricity',
        inclination: 'Inclination',
        rightAscensionOfAscendingNode: 'Right Ascension of Ascending Node',
        argumentOfPerigee: 'Argument of Perigee',
        trueAnomaly: 'True Anomaly',
      },
    },
    errors: {
      type_not_empty: 'Type cannot be empty.',
      name_not_empty: 'Name cannot be empty.',
      name_not_unique: 'Name has to be unique.',
      self_intersecting: 'Polygon path is self intersecting.',
      failed_drawing: 'Could not draw {{type}}. Please try again',
      lat_error: 'Latitude has to to be within bounds [-90,90].',
      lng_error: 'Longitude has to to be within bounds [-180,180].',
      alt_error: 'Altitude has to be greater than 0.',
      poi_elevation_ang_error:
        'Elevation Angle has to to be within bounds [0,90].',
      roi_elevation_ang_error:
        'Elevation Angle has to to be within bounds [15,90].',
      error: 'Error.',
      semimajorAxis: 'has to to be within bounds [6800, 1000000].',
      degrees: 'has to to be within bounds [-360, 360].',
      eccentricity: 'has to to be within bounds [0, 1].',
      tle: 'TLE cannot be empty.',
      criterion_error: 'Select Min|Max criterion.',
      object_error: 'Must select an object.',
      objective_name:
        'One of objects has an incorrect name. Compare with the events table.',
      periapsis_error:
        'The semi-major axis/eccentricity must generate a periapsis altitude ',
      apoapsis_error:
        'The semi-major axis/eccentricity must generate an apoapsis altitude ',
    },
    save_title: 'Save item to store',
  },
  module_msd: {
    module_name: 'Mission design',
    step_payload_configurator: 'Payload',
    step_mission_geometry: 'Mission Geometry',
    step_ground_segment: 'Ground Segment',
    step_satellite_configurator: 'Platform',
    step_modes: 'Modes',
    payload_definition: {
      detailed_title: 'Payload Characteristics',
      detailed_view: {
        general_tab_title: 'General',
        physical_tab_title: 'Physical',
        thermal_tab_title: 'Thermal',
        modes_tab_title: 'Modes',
        general_title: 'General Characteristics',
        physical_title: 'Physical Characteristics',
        thermal_title: 'Thermal Characteristics',
        modes_title: 'Operational Modes',
      },
    },
    mission_geometry: {
      orbit: 'Orbit',
      orbit_plural: 'Orbits',
    },
    ground_segment: {
      save: 'Save Ground Segment',
    },
    satellite: {
      modes: {
        single: 'Mode',
        plural: 'Modes',
        full: 'Satellite Operational Mode',
        satellite_mode: 'Satellite Mode',
      },
    },
  },
  module_ops: {
    module_name: 'Operations',
    telemetry: 'Telemetry Visualiser',
    library: 'Library',
    gss: 'Ground Station Scheduler',
    rti: 'Real-Time Interface',
    scripting: 'Scripting',
    scheduling: 'Schedule',
  },
  module_pdgs: {
    module_name: 'Processing',
    processing: 'Payload Processing',
  },
  module_data: {
    module_name: 'DataCosmos',
    search: 'Search',
    scenario: 'Project items',
    application: 'Applications',
    docs: 'Documentation',
    views: 'Views',
  },
  'events-table': {
    header: {
      type: 'Type',
      name: 'Name',
    },
    types: {
      PI: 'Point',
      RI: 'Region',
    },
  },
  constraints: {
    GENERIC_CONSTELLATION: {
      title: 'Generic Constellation',
      header: {
        Satellites: 'Satellites',
        Name: 'Name',
        Orbit_Definition: 'Orbit Definition',
      },
      satellite: {
        orbit_type: 'Orbit type',
        type: {
          KEPLERIAN: 'Keplerian Elements',
          TLE: 'TLE',
        },
      },
      form_title: 'Satellite:',
    },
    LAUNCH_OPPORTUNITIES: {
      title: 'Launch Opportunities',
    },
    WALKER_CONSTELLATION: {
      title: 'Walker Constellation',
      altitude: 'Altitude',
      numberOfSatellites: 'Number of satellites',
      numberOfPlanes: 'Number of planes',
      inclination: 'Inclination',
      Parameter: 'Parameter',
      Value: 'Value',
      Optimise: 'Optimise',
      Boundaries: 'Boundaries',
      measurement: {
        m: '[m]',
        km: '[km]',
        deg: '[deg]',
        h: '[h]',
      },
      errors: {
        value: 'Incorrect value!',
      },
    },
  },
  objectives: {
    title: 'Goals',
    form_title: 'Optimisation Objective:',
    table: {
      name: 'Name',
      type: 'Type',
      criterion: 'Criterion',
      max: 'Maximise',
      min: 'Minimise',
    },
    types: {
      title: 'Objective Types',
      mission_metric: 'Mission Metric',
      ground_segment_event: 'Ground Segment Event',
      point_of_interest_event: 'Point of Interest Event',
      region_of_interest_event: 'Region of Interest Event',
    },
    object: {
      title: 'Object',
      all_ground_stations: 'All Ground Stations',
    },
    event: {
      title: 'Event',
      visibility: 'Visibility',
      illuminated_visibility: 'Illuminated Visibility',
    },
    scope: {
      title: 'Scope',
      coverage: 'Coverage',
      revisit: 'Revisit',
    },
    metric: {
      title: 'Metric',
      number_of_satellites: 'Number of Satellites',
      number_of_planes: 'Number of Planes',
      number_of_ground_stations: 'Number of Ground Stations',
      incidence: 'Incidence [%]',
      avg_duration: 'Avg. Duration [s]',
      max_duration: 'Max. Duration [s]',
      min_duration: 'Min. Duration [s]',
      duration_std_deviation: 'Duration Std. Deviation [s]',
      average: 'Avg. Duration [s]',
      maximum: 'Max. Duration [s]',
      minimum: 'Min. Duration [s]',
      std: 'Duration Std. Deviation [s]',
    },
  },
  SOSTable: {
    launchOpportunities: {
      id: 'ID',
      launcher: 'Launcher',
      date: 'Date',
      altitude: 'Altitude [km]',
      inclination: 'Inclination [deg]',
      ltan: 'LTAN  [h]',
      sso: 'SSO',
    },
    groundStations: {
      name: 'Name',
      custom: 'Custom',
      elevationAngle: 'Min Elevation',
      lat: 'Latitude [deg]',
      lon: 'Longitude [deg]',
      altitude: 'Altitude [m]',
    },
    select: 'Select',
    optimise: 'Optimise',
    yes: 'Yes',
    no: 'No',
  },
  results: {
    title: 'Results table',
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    json: 'JSON',
    parameter: 'Parameter',
    objective: 'Objective',
  },
  simulate: {
    title: 'Progress',
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    json: 'JSON',
  },
  optimization_table: {
    optimize: 'Optimize',
    save: 'JSON',
  },
  events_table: {
    type: 'Type',
    name: 'Name',
  },
  alert: {
    ok: 'OK',
    cancel: 'Cancel',
    remove: 'Remove',
    'switch-astrum':
      'You are about to switch the astrum. The events, goals, orbits will be reset. Proceed?',
    'switched-to': 'Switched to {{astrum}}',
  },
  geometry: {
    satelliteMode: 'Satellite Mode',
    satellitesPerPlane: 'Satellites per plane',
    firstPlaneRaan: 'First plane RAAN',
    relativeSpacing: 'Relative spacing',
    type: 'Type',
    parameterisation: 'Parameterisation',
    tle_objects_label: 'Select object',
    KEPLERIAN: 'Keplerian',
    STATIONARY: 'Stationary',
    SYNCHRONOUS: 'Synchronous',
    POLAR: 'Polar',
    TLE: 'TLE',
    WALKER_CONSTELLATION: 'Walker constellation',
    ltan: 'LTAN',
    raan: 'RAAN',
    semimajorAxis: 'Semi-major Axis',
    eccentricity: 'Eccentricity',
    inclination: 'Inclination',
    longitude: 'Longitude',
    trueAnomaly: 'True Anomaly',
    rightAscensionOfAscendingNode: 'Right Ascension of Ascending Node',
    argumentOfPerigee: 'Argument of Perigee',
    altitude: 'Altitude',
    numberOfSatellites: 'Number of satellites',
    numberOfPlanes: 'Number of planes',
    mission_metric: 'Mission Metric',
    ground_segment_event: 'Ground Segment Event',
    point_of_interest_event: 'Point of Interest Event',
    region_of_interest_event: 'Region of Interest Event',
    save: 'Save Mission Geometry',
    measurement: {
      m: '[m]',
      km: '[km]',
      deg: '[deg]',
      rad: '[rad]',
      h: '[h]',
    },
    errors: {
      value: 'Incorrect value!',
    },
  },
  api: {
    error: 'Error in the simulation, please send your scenario to {{email}}',
  },
  userPage: {
    addResource: 'Add resource',
    resource: 'Resource',
    role: 'Role',
    resourceIdPlaceholder: 'Type resource id',
    users: 'Users',
    showHiddenRoles: 'Show hidden roles',
    downloadAll: 'Download all',
    downloadAllTooltip: 'Download all users as CSV',
    downloadAllFilename: 'users_{{date}}.csv',
    roles: 'Roles',
    searchPlaceholder:
      'Search users by name or email, (e.g: John Doe, *@company.com)',
    buttons: {
      add: 'Add',
    },
    assignedPermissions: {
      title: 'Assigned permissions',

      placeholders: {
        resourceId: 'Type resource id',
        resourceName: 'Search by resource name or resource id',
        resourceType: 'Search by resource type',
      },
      areYouSureYouWantToRemove:
        'Are you sure you want to remove ALL permissions for the selected resource?',

      selectARole: 'Select a role',

      success: {
        added: `Role has been added successfully`,
      },

      errors: {
        notEmpty: 'Resource ID must not be empty',
        noRole: 'Select a role',
        noResultsForResourceType: 'No results for resource type',
        cannotSaveEmptyUser: 'Cannot save empty user',
        cannotSaveUserwithoutRole: 'Cannot save user without role',
        incorrectEmail: 'Email is incorrect!',
        userDoesNotExist: 'User does not exist',
      },
    },
  },
  rolesPage: {
    perrmissionType: {
      datacosmos_app: 'Data Platform - Application Roles',
      datacosmos_scenario: 'Data Platform - Scenario Roles',
      datacosmos_stac_collection: 'Data Platform - STAC Collection Roles',
      datacosmos_view: 'Data Platform - View Roles',
      datacosmos_stac_item: 'Data Platform - STAC Item Roles',
      global: 'Global Roles',
      mission: 'Mission Roles',
      organisation: 'Organisation Roles',
      programme: 'Programme Roles',
    },
    viewAttachments: 'View role attachments',
    closeAttachments: 'Close role attachments',
    addAttachment: 'Add Role Attachment',
    noAttachments: 'No attachments added',
    visibleAndAssignable: 'Visible and assignable by non-admin users',
    addRole: 'Add Role',
    newRole: 'New Role',
  },
  organisationsPage: {
    searchPlaceholder: 'Search organisations by name',
    noOrganisation: 'There is no organisation found with Id: {organisationId}',
    name: 'Name',
    joinPolicy: 'Join Policy',
    domain: 'Domain',
    joinPolicyType: {
      JOIN: 'Join',
      REQUEST: 'Request',
      INVITE: 'Invite',
    },
    joinPolicyDescription:
      'The join policy determines if users can discover the organisation and how they can join it.',
    details: 'Details',
    discoverability: 'Organisation Discoverability',
    billing: 'Billing',
    billingDescription:
      'Billing administrators can manage the billing details and list the invoices by clicking the button below.',
    goToBilling: 'Go to billing page',
    programmes: 'Programmes',
  },
  onboarding: {
    continueToPlatform: {
      buttonPrompt: 'Continue to platform',
      joinBtn: 'Join now',
      joined: 'Joined',
      acceptInvite: 'Accept invite',
      requestBtn: 'Request to join',
    },
    createOrganisation: {
      title: 'Join or create an organisation',
      titleJoin: 'Join an organisation',
      subtitle_zero: 'No organisation was found matching your email domain',
      subtitle_one: 'One organisation was found matching your email domain',
      subtitle_other:
        '{{count}} organisations were found matching your email domain',
      description:
        'Some organisations might be hidden. If you cannot find the organization you are looking for, please contact the organisation administrator to request an invite.',
      buttonPrompt: 'Create a new organisation',
      personalButtonPrompt: 'Continue as personal account',
      form: {
        title: 'Create an organisation',
        fields: {
          name: 'Organisation Name',
          domain: 'Organisation Domain',
          howToJoin: {
            title: 'How to join (based on email)?',
            options: {
              onInvite:
                'Invite - Users require an invite to see this organisation',
              requestToJoin:
                'Request - Users on the same domain can request to join',
              join: 'Join - Users on the same domain can join without approval',
            },
          },
        },
        buttons: {
          next: 'Next',
          setupBillingButtonText: 'Next: setup billing',
        },
        errors: {
          domainConnotBeEmpty: 'Domain cannot be empty',
          invalidDomain: 'Invalid organisation domain',
        },
      },
    },
    setupBilling: {
      title: 'Setup billing',
      description:
        'In order to setup billing you will be redirected to Stripe website',
      buttons: {
        skipForNow: 'Skip for now',
        goToStripe: 'Go to stripe',
      },
    },
  },
  datacosmos: {
    header: {
      freeEditingMode: 'Free editing mode',
      userManual: 'User manual',
      upgradePermission: 'Upgrade',
      group: 'Group',
      missingProjectTitle: 'Click to select Project',
      thumbnail: 'Thumbnail',
      menu: {
        orders: 'Orders',
        theme: {
          title: 'Theme',
          dark: 'Dark',
          light: 'Light',
          auto: 'Auto',
        },
        rolesManager: 'Roles manager',
        users: 'Users',
        logout: 'Logout',
        matomo: 'User stats dashboard',
        grafana: 'Admin dashboard',
        keycloak: 'Users administration',
        views: 'Views',
        organisations: 'Organisations',
        tooltip: {
          help: 'Help',
          userManual: 'User manual',
          home: 'Inicio',
          organisations: 'Organisations',
          roles: 'Roles',
          users: 'Users',
        },
      },
    },
    bottomBar: {
      coordinates: 'Coordinates',
      selectedCoordinateSystem: 'Selected Coordinate System',
    },
    map: {
      addNew: {
        buttonTitle: 'Add new',
        name: 'Name',
        URL: 'OGC TMS-compliant URL',
        buttons: {
          close: 'Close',
          add: 'Add',
        },
      },
      urlMessage:
        'URL must include placeholders {z}, {x}, & {y} in correct positions',
      zoomAlertForLowReslutionImages: {
        title: 'Image resolution limited',
        description:
          'You do not have full resolution access to some images on the map',
      },
    },
    fetchErrors: {
      views: {
        cannotGet: 'Could not get views',
        cannotGetOne: 'Could not get view',
        cannotPut: 'Could not put view',
        cannotDelete: 'Could not delete view',
        cannotPost: 'Could not post view',
        noViewsAvailable: 'There are no views available for this query',
      },

      activities: {
        cannotGetByMissionId: 'Could not get activities by mission id',
        cannotPatch: 'Could not patch activity',
      },

      scenarios: {
        cannotGetMultiple: 'Could not get projects',
        cannotGetOne: 'Could not get project',
        cannotPut: 'Could not edit project',
        cannotDelete: 'Could not delete project',
        cannotPost: 'Could not post project',
        cannotAddToScenario: 'Could not add item to project',
        cannotRemoveFromScenario: 'Could not delete item from project',
        cannotSearch: 'Could not search project items',
      },

      stac: {
        cannotSearch: 'Failed to search DataCosmos catalog',
        cannotDeleteItem: 'Could not delete Item',
        cannotPostItem: 'Could not create Item',
        cannotPutItem: 'Could not update Item',
      },

      osm: {
        cannotGet: 'Could not fetch region info',
      },

      samplingPixels: {
        cannotGet: 'Could not get STAC item pixels',
      },

      samplingMatrix: {
        cannotGet: 'Could not get STAC item covariance matrix',
      },

      orders: {
        price: {
          cannotGet: "Cannot get image's price",
        },
        orderList: {
          cannotGet: 'Cannot get order list',
        },
        customers: {
          cannotGet: 'Could not get customer by id',
        },
        order: {
          cannotPost: 'Could not create order',
          cannotPostPreferences: 'Could not create preferences',
          cannotPatch: 'Could not update order',
          noOrderSelected: 'No order selected',
          noOrderSelectedDescription: 'Select an order to view it here',
          noOrdersFound: 'No orders found',
          noOrdersFoundFiltersDescription:
            'No orders found with the current filters',
          noOrdersFoundDescription: 'We could not find any orders',
          cannotGetPaymentImage: 'Could not get payment image',
          noActionAfterPayment: 'No actions for paid orders',
        },
        checkout: {
          cannotGetURL: 'Could not get checkout URL',
        },
      },
      geopdf: {
        postFile: {
          cannotPost: 'Could not upload file',
          enviError:
            'The ENVI file must include ".hdr + .dat". These files cannot be uploaded separately.',
          shpError:
            'The SHP file must include ".shp + .shx + .dbf". These files cannot be uploaded separately.',
        },
      },
      storage: {
        getRequestError: 'Could not get file',
        putRequestError: 'Could not upload file',
      },
    },

    fetchSuccesses: {
      activities: {
        successfullyPatched: 'Activity successfully updated',
      },
      orders: {
        successfullyCreated: 'Order successfully created',
        imageUploaded: 'Image uploaded successfully',
      },
      postFile: {
        successfullyUploaded: 'File successfully uploaded',
      },
    },
    upgradePermissionsDialog: {
      upgradePermissionsHeader: 'Intermediate user level upgrade',
      permissionsBody:
        'Thanks for using CONIDA geoinformation platform. Unfortunately, your current user level does not allow you to see full resolution images. We need a bit more information about you to upgrade your access and allow you to use full resolution data.',
      typeOfUser: 'Please select the type of organisation you work for:',
      userType: {
        institutional: 'Public institutions',
        institutionalTooltipText:
          'Organisations that are part of the Peruvian government.',
        private: 'Private entities',
        privateTooltipText: 'Peruvian companies and individuals',
      },
      sendButtonText: 'Request Upgrade',
      sendEmailText: 'Send email',
      ignoreIfEmailSent:
        'If you have already sent an email, resubmission is not required',
      downloadAndFillDocument: `${'In order to proceed, please read through the <a href="https://cdn.www.gob.pe/uploads/document/file/1225819/dir_014_compressed__1_.pdf?v=1597110644" target="_blank" rel="noreferrer" class="font-bold underline text-accent"> Directive</a>. Once completed, please download, print, sign, and send the following <a href="https://cdn.www.gob.pe/uploads/document/file/1299316/Anexo%205.3.pdf?v=1599794505" target="_blank" rel="noreferrer" class="font-bold underline text-accent"> document</a> to the email address xxx@conida.gob.pe. Make sure to use the title "Request to upgrade to intermediate user" and include the email addresses of the users.'}`,
    },
    viewsDialog: {
      returnToFreeEditingMode: 'Return to free editing mode',
      views: 'Views',
      view: 'View',
      sharedWith: 'Shared with: {{with}}',
      createdAt: 'Created at: {{at}}',
      lastUpdated: 'Last updated: {{at}}',
      openView: 'Open view',
      deleteView: 'Delete view',
      shareView: 'Share view',
      showAllViews: 'Show all views',
    },

    geopdfDialog: {
      createGeoPDF: 'Create GeoPDF',
      geoPDFHeader: 'Enter GeoPDF details',
      nonTiffWarning:
        'Layers without .tiff assets will be excluded from download',
      paperSizePlaceholder: 'Select paper size',
      paperSizeLabel: 'Paper size',
      orientationPlaceholder: 'Select orientation',
      orientationLabel: 'Orientation',
      dpiPlaceholder: 'Select DPI',
      descriptionPlaceholder: 'Enter description',
      descriptionLabel: 'Description',
      title: 'Title',
      titlePlaceholder: 'Enter title',
      errors: {
        title: 'Title cannot be empty',
      },
      orientationValues: {
        portrait: 'Portrait',
        landscape: 'Landscape',
      },
      legend: 'Legend',
    },
    cancelRequestDialog: {
      title: 'Cancel Request',
      areYouSure:
        'Are you sure you want to cancel {{type}} request `{{name}}`?',
      longWarning:
        'This cannot be undone. Type reason for cancellation in the box above and click "Confirm" if you are sure.',
      placeholder: 'Enter reason for cancellation',
    },
    cancelActivityDialog: {
      title: 'Cancel Activity',
      areYouSure:
        'Are you sure you want to cancel this {{duration}} activity on `{{date}}` ?',
      note: 'This activity worth {{price}} will not be charged',
    },
    optionsDialog: {
      changeFormat: 'Change format',
      includeWatermark: 'Include watermark',
      animatedGifWarning:
        'One or more added layers are not in current map bounds',
    },
    saveViewDialog: {
      overwrite: 'Overwrite view {{name}}',
      save: 'Save as new view',

      inputs: {
        name: 'Name:',
        namePlaceholder: 'View name...',
        description: 'Description:',
        descriptionPlaceholder: 'View description...',
      },
    },
    projectsDialog: {
      title: 'Open a project',
      openProject: 'Open project',
      selectProject: 'Select a project',
      deleteProject: 'Delete project',
      editProject: 'Edit project',
      shareProject: 'Share project',
      createNewProject: 'Create new project',
      organisation: 'Organisation',
      description: 'Description',
    },
    deleteViewDialog: {
      areYouSure: 'Are you sure you want to delete view',
      longWarning:
        'You are about to delete this view, and will lose all your saved layers. This cannot be undone. Type "delete" in the box below and click "Confirm" if you are sure you wish to proceed.',
    },
    deleteProjectDialog: {
      warning: 'WARNING!',
      areYouSure: 'Are you sure you want to delete project {{project}}?',
      longWarning:
        'You are about to delete this project. You will lose links to all images in the project. This cannot be undone. Type "delete" in the box below and click "Confirm" if you are sure you wish to proceed.',
      errors: {
        deletionFailed: 'Deletion failed',
      },
      success: 'Project deleted successfully',
      placeholder: "Type 'delete' to confirm your choice",
    },
    editProjectDialog: {
      title: 'Edit project',
      name: 'Name',
      description: 'Description',
      labels: 'Labels',
      key: 'Key',
      value: 'Value',
      keyPlaceholder: 'Project key',
      valuePlaceholder: 'Project value',
      errors: {
        name: 'Name Must not be empty',
        description: 'Description Must not be empty',
        organisation: 'Organisation Must not be empty',
        organisationCannotBeChanged:
          'Once created, projects cannot be moved to a different organisation',
      },
      successfullyEdited: 'Project {{name}} was successfully updated',
    },
    addNewProjectDialog: {
      name: 'Name',
      description: 'Description',
      organisation: 'Organisation',
      select: 'Select',
      labels: 'Labels',
      key: 'Key',
      value: 'Value',
      keyPlaceholder: 'Project key',
      valuePlaceholder: 'Project value',
      errors: {
        name: 'Name Must not be empty',
        description: 'Description Must not be empty',
        organisation: 'Organisation Must not be empty',
        organisationCannotBeChanged:
          'Once created, projects cannot be moved to a different organisation',
      },
      successfullyCreated: 'Project {{name}} created successfully',
    },
    locationDetailsDialog: {
      title: 'Location details',
      message: 'Uploaded file is not georeferenced',
      label: 'Enter coordinates in lng, lat format',
      markLocationBtnText: 'Mark location on map',
    },

    buttons: {
      cancel: 'Cancel',
      save: 'Save',
      edit: 'Edit',
      confirm: 'Confirm',
      overwrite: 'Overwrite',
      draw: 'Draw',
      update: 'Update',
      purchase: 'Purchase',
      request: 'Request',
      upload: 'Upload',
      download: 'Download',
      enable: 'Enable',
      disable: 'Disable',
      completeOrder: 'Complete order',
      payByCard: 'Pay via Credit or Debit Card',
      payByBank: 'Pay via Bank Transfer',
      goToOrders: 'Go to Orders page',
      accept: 'Accept',
      filters: 'Filters',
      closeFilters: 'Close filters',
      drawAoiForStatistics: 'Draw AoI for statistics',
      removeAoiForStatistics: 'Remove AoI',
      searchByRegion: 'Search by region',
      searchByPoint: 'Search by point',
      delete: 'Delete',
      backToSearch: 'Back to search',
      resetAndClose: 'Reset and Close',
      close: 'Close',
      continueToPayment: 'Continue to payment',
      downloadParameters: 'Download parameters',
      uploadParameters: 'Upload parameters',
      takeScreenshot: 'Take screenshot',
      createNew: 'Create new',
      configure: 'Configure',
      refresh: 'Refresh',
    },

    catalogAndItems: {
      catalog: 'Catalog',
      group: 'Group',
      moreInfo: 'More info',
      project: 'Project',
      buyFullResImages: 'Buy full resolution images',

      assets: {
        openInNewTab: 'Open file in a new tab',
        download: 'Download file',
        removeFromMap: 'Remove asset from map',
        addToMap: 'Display asset on map',
        downloadAllAssets: 'Download all assets',
        copyLinkForGISTool: 'Copy link for GIS tool',
      },

      metadata: {
        sensors: 'Sensors: ',
        resolution: 'Resolution: ',
        minPixelValue: 'Minimum:',
        maxPixelValue: 'Maximum:',
        meanValue: 'Mean:',
        stdValue: 'Standard Deviation:',
        variance: 'Variance:',
        percentile25: '25th Percentile:',
        percentile75: '75th Percentile:',
        showStatistics: 'Show statistics',
        noHistogramData: 'No histogram data',
        statistics: 'Statistics',
        summaryStatistics: 'Summary Statistics',
        yAxisLabel: 'Frequency',
        xAxisLabel: 'Value',
        oza: 'Oza: ',
        noStatistics: {
          title: ' No statistics available',
          description: 'There are no statistics available for this item.',
        },
        copyLink: 'Copy link',
        copied: 'Copied',
        calculateCovariance: 'Calculate covariance',

        bandColors: {
          red: 'Red',
          green: 'Green',
          blue: 'Blue',
          gray: 'Gray',
        },
        values: {
          Satellite: 'Satellite',
          Vector: 'Vector',
          Insight: 'Insight',
          coastal: 'Coastal',
          blue: 'Blue',
          green: 'Green',
          red: 'Red',
          yellow: 'Yellow',
          pan: 'Pan',
          rededge: 'Red Edge',
          rededge1: 'Red Edge 1',
          rededge2: 'Red Edge 2',
          rededge3: 'Red Edge 3',
          nir: 'NIR',
          nir08: 'NIR 0.8 µm',
          nir09: 'NIR 0.9 µm',
          cirrus: 'Cirrus',
          swir16: 'SWIR 1.6 µm',
          swir22: 'SWIR 2.2 µm',
          lwir: 'LWIR',
          lwir11: 'LWIR 11 µm',
          lwir12: 'LWIR 12 µm',
          Summer: 'Summer',
          Winter: 'Winter',
          Autumn: 'Autumn',
          Spring: 'Spring',
          Rainy: 'Rainy',
          Dry: 'Dry',
          'Satellite Imagery': 'Satellite Imagery',
          Algorithm: 'Algorithm',
          full: 'Full',
          limited: 'Limited',
          Multispectral: 'Multispectral',
          Hyperspectral: 'Hyperspectral',
          Radar: 'Radar',
          UAV: 'UAV',
        },
      },
      metadataModal: {
        collection: 'Collection',
        constellation: 'Constellation',
        created: 'Created',
        datacosmos_scenario: 'Project',
        datetime: 'Datetime',
        direction: 'Direction',
        eo_bands: 'Bands',
        eo_cloud_cover: 'Cloud Coverage Percentage',
        end_datetime: 'End Datetime',
        gsd: 'Gsd',
        instruments: 'Instruments',
        item: 'Item',
        opencosmos_copyright: 'Copyright',
        opencosmos_data_area_km2: 'Data Area Km2',
        opencosmos_high_resolution_read_permission:
          'High Resolution Read Permission',
        opencosmos_product_type: 'Product Type',
        opencosmos_rid: 'Rid',
        opencosmos_season: 'Season',
        opencosmos_sensor_type: 'Sensor Type',
        opencosmos_video: 'Video',
        opencosmos_source: 'Source',
        pitch: 'Pitch',
        placeholder: 'Search metadata...',
        platform: 'Platform',
        price_currency: 'Price Currency',
        price: 'Price',
        processing_level: 'Processing Level',
        product_id: 'Product Id',
        product_level: 'Product Level',
        receive_station_id: 'Receive Station Id',
        proj_epsg: 'EPSG',
        properties: 'Properties',
        resolution: 'Resolution',
        roll: 'Roll',
        sat_platform_international_designator:
          'Satellite Platform International Designator',
        satellite: 'Satellite',
        scale: 'Scale',
        scene_id: 'Scene Id',
        scene_path: 'Scene Path',
        scene_row: 'Scene Row',
        season: 'Season',
        sensor_id: 'Sensor Id',
        sensor_type: 'Sensor Type',
        sentinel_grid_square: 'Sentinel Grid Square',
        sentinel_latitude_band: 'Sentinel Latitude Band',
        sentinel_utm_zone: 'Sentinel Utm Zone',
        simera_calibration: 'Simera Calibration',
        simera_capture_mode: 'Simera Capture Mode',
        simera_compression: 'Simera Compression',
        simera_relative_correction: 'Simera Relative Correction',
        simera_session_id: 'Simera Session Id',
        start_datetime: 'Start Datetime',
        strip_id: 'Strip Id',
        sun_glint: 'Sun Glint',
        title: 'STAC metadata',
        triggerButton: 'Show metadata',
        updated: 'Updated',
        values: 'Values',
        video: 'Video',
        view_incidence_angle: 'Incidence Angle',
        view_sun_elevation: 'Sun Elevation',
        yaw: 'Yaw',
        links: {
          self: 'Self Item',
          parent: 'Parent Collection',
          license: 'License',
          derived_from: 'Source item',
          convertedfrom: 'Converted from',
        },
      },

      covarianceMatrixModal: {
        title: 'Covariance matrix',
        noValuesAvailableMessage: 'No values available',
        bandsTitle: 'Covariance matrix between the bands {{allBands}}',
      },

      amountAvailable: '{{amount}} / {{total}} available',

      addToProjectPopup: {
        title: 'Select a project to add items to',
        dropdownInitialItem: 'Select a project',
        errors: {
          selectAProject: 'Select a project',
        },
      },

      errors: {
        noResults: {
          title: 'No results',
          description: 'This project is empty',
        },
        fetchingFailed: {
          title: 'Something went wrong',
          description: "Couldn't fetch items",
        },
        forbidden: {
          title: 'Something went wrong',
          description: "You don't have access to this Project",
        },
        nothingMatchesFilters: 'No features match specified filters',
        noAssetsAvailable: 'No assets available',
        nothingMatchesSearchedItem: 'No asset found',
      },

      purchaseDialog: {
        title: 'Title',
        location: 'Location',
        date: 'Date',
        userIdLabel: 'Enter the user ID',
        userIdDescription:
          'This identifier must be that of the owner of the bank account from which the payment will be made, and will help link the bank transfer with the generated order, in order to apply the payment to it.',
        subtotal: 'Subtotal: {{subtotal}} {{currency}}',
        discount: 'Discount: {{discount}} {{currency}}',
        totalPrice: 'Total price',
        price: 'Price',
        noPrice: 'Unable to fetch price, please contact us for more details',

        orderCheckout: 'Order checkout',
        payingViaBankTransfer: 'Paying via bank transfer',

        paymentViaCardComplete: 'Payment via Debit or Credit Card completed',

        dataPurchasedWillNotBeAvailableUntil:
          'Data purchased in this order will not be available until the payment details have been verified',
        pleaseMakeATransfer:
          'Please make the payment via bank transfer to the accounts: ',
        pleaseMakeATransferWithRef:
          'If possible, add the reference {{reference}} in the transfer.',
        verificationOfPaymentDetailsCanTake:
          'Verification of payment details can take up to 2 working days from the payment date',
        uploadProofOfPayment:
          'Once the payment has been made, please upload the proof of payment in the Orders section',
        couldNotFinalizePayment: 'Could not finalize payment',
        publicTermsAndConditionsText: 'I agree to the terms and conditions',
        privateTermsAndConditionsText: 'I agree to the terms and conditions',
      },
      qa: {
        publish: 'Publish',
        unpublish: 'Unpublish',
        confirmDialog: {
          title: 'Unpublish item',
          description: 'Are you sure you want to unpublish the item {{item}}?',
          rejectLabel: 'Reason for unpublishing',
        },
        errors: {
          publish: 'There was an error publishing the Item',
          unpublish: 'There was an error unpublishing the Item',
        },
      },
    },

    layers: {
      layers: 'Layers',
      blendMode: 'Blend mode',
      opacity: 'Opacity',
      brightness: 'Brightness',
      saturation: 'Saturation',
      contrast: 'Contrast',
      colourPicker: 'Layer colour',
      fillOpacity: 'Fill opacity',
      layerOperationDisabled: 'Applied for full resolution images',
      backToLayers: 'Back to layers',
      timeSeries: {
        title: 'Time series',
        disabledTooltip:
          'At least two layers from different dates are required to enable Time Series',
      },
      layersComparison: 'Compare layers',
      left: 'Left',
      right: 'Right',
      reset: 'Reset',
      distance: 'Distance',
      area: 'Area',
      blendModes: {
        normal: 'normal',
        multiply: 'multiply',
        screen: 'screen',
        overlay: 'overlay',
        darken: 'darken',
        'color-dodge': 'color-dodge',
        'color-burn': 'color-burn',
        'hard-light': 'hard-light',
        'soft-light': 'soft-light',
        difference: 'difference',
        exclusion: 'exclusion',
        hue: 'hue',
        saturation: 'saturation',
        color: 'color',
        luminosity: 'luminosity',
      },
      pixelOperations: {
        title: 'Pixel operation modes',
        singlePixelValue: 'Single pixel value',
        linePixelValues: 'Line pixel values',
        customLinePixelValues: 'Custom Line pixel values',
        vsWavelength: 'Single pixel value vs wavelength',
        pixelSpectralSignatures: 'Pixel spectral signatures',
        tooltip: {
          loading: 'Loading...',
          noData: 'No data',
          basicUser:
            "You don't have the necessary permissions to access Pixel Operations",
        },
        noPermissions:
          'Pixel operations are only available for purchased full resolution images',
        notGeoreferenced:
          'The pixel operation cannot be performed on this image because it is not georeferenced',
      },

      pixelOperationsChart: {
        yAxisLabel: 'Digital number',
        downloadDataAs: 'Download data as: ',
        download: 'Download data',
        wavelength: {
          noData: 'No wavelength pixel data',
          xAxisLabel: 'Wavelength',
        },

        linePixels: {
          noData: 'No line pixel data',
          horizontalPixels: 'Horizontal pixels',
          verticalPixels: 'Vertical pixels',
          xAxisLabelVertical: 'Latitude',
          xAxisLabelHorizontal: 'Longitude',
          download: 'Download {{axis}} pixel data',
        },

        customLinePixels: {
          noData: 'No custom line pixel data',
          noAOI: 'Draw a Line of Interest to see the pixel data',
          xAxisLabel: 'Position',
        },

        spectralSignature: {
          noData: 'No spectral signature data',
          noAOI: 'Draw an Area of Interest to see the spectral signature',
        },
      },
      bandAlgebra: {
        indexTab: 'Index',
        customTab: 'Custom',
        title: 'Band algebra',
        expressionNotSupported: 'Expression not supported for this satellite',
        expressionWarning:
          'This expression is only supported on the satellite it was created for',
        itemSpecificBands: 'Item Specific',
        predefined: {
          title: 'Predefined',
          index: {
            ndvi: {
              title: 'Vegetation index (NDVI)',
              description:
                'Normalised Difference Vegetation Index - indicates how much vegetation there is in an area, and the health of that vegetation',
            },

            gndvi: {
              title: 'Vegetation index (GNDVI)',
              description:
                'Green Normalised Difference Vegetation Index - provides an estimation of how much chlorophyll/photosynthetic activity there is in an area',
            },

            evi: {
              title: 'Vegetation Index (EVI)',
              description:
                'Enhanced Vegetation Index - uses the reflection region of blue light to correct for background and atmospheric noise. EVI is useful in areas with dense green vegetation, because it doesn’t become as saturated as NDVI.',
            },

            avi: {
              title: 'Vegetation index (AVI)',
              description:
                'Advanced Vegetation Index - indicates how healthy vegetation is in an area',
            },

            savi: {
              title: 'Soil Adjusted Vegetation index (SAVI)',
              description:
                'Soil Adjusted Vegetation Index - indicates how healthy vegetation is in areas with low vegetation, correcting for reflections from bare soil',
            },

            arvi: {
              title: 'Vegetation index (ARVI)',
              description:
                'Atmospherically Resistant Vegetation Index - a measure of vegetation which is less affected by atmospheric reflectance (haze, etc...)',
            },

            ndmi: {
              title: 'Moisture index (NDMI)',
              description:
                'Normalised Difference Moisture Index - measures the level of moisture in vegetation',
            },
            msi: {
              title: 'Moisture index (MSI)',
              description:
                'Moisture Stress Index - distinguishes between dry and moist vegetation',
            },
            gci: {
              title: 'Green coverage index (GCI)',
              description:
                'Green Cover Index - measures the quantity of chlorophyll in plants in an area, an indicator of stress/health of the vegetation',
            },
            nbr: {
              title: 'Burn ratio index (NBR)',
              description:
                'Normalised Burn Ratio - highlights burned areas and measures the burn severity, e.g. after a wild fire',
            },
            bsi: {
              title: 'Soil index (BSI)',
              description: 'Bare Soil Index - identifies regions of bare soil',
            },
            ndwi_gao: {
              title: 'Water index (NDWI, Gao)',
              description:
                'Normalised Difference Water Index (Gao) - measures water content in leaves',
            },
            ndwi_mcfeeters: {
              title: 'Water index (NDWI, McFeeters)',
              description:
                'Normalised Difference Water Index (McFeeters) - measures water content in bodies of water',
            },
            ndsi: {
              title: 'Snow index (NDSI)',
              description:
                'Normalised Difference Snow Index - detects the presence of snow',
            },
            ndgi: {
              title: 'Glacier index (NDGI)',
              description:
                'Normalised Difference Glacier Index - identifies glaciers',
            },
            sipi: {
              title: 'Pigment index (SIPI)',
              description:
                'Structure Insensitive Pigment Index - measures the amount of carotenoid pigments in vegetation',
            },
            sr: {
              title: 'Simple ratio index (SR)',
              description:
                'Simple Ratio Index - distinguishes between vegetated and non-vegetated areas, stressed and unstressed plants',
            },
            npcri: {
              title: 'Normalized pigment chlorophyll ratio index (npcri)',
              description:
                'Normalized pigment chlorophyll ratio index - a numerical indicator which can be used to determine crop and/or vegetation chlorophyll content. This formula is usually found within application in precession agriculture.',
            },
            fai: {
              title: 'Floating Algae Index (FAI) Sentinel 2',
              description:
                'Floating algae index (FAI) - a measure of the red-edge reflectance of floating vegetation, used to map floating macroalgae in global oceans. This <a href="https://custom-scripts.sentinel-hub.com/custom-scripts/sentinel-2/apa_script/" style="color:inherit;text-decoration:underline">expression</a> uses a constant value of 0.1873 computed from the wavelength of <a style="color:inherit;text-decoration:underline" href="https://custom-scripts.sentinel-hub.com/custom-scripts/sentinel-2/bands/"> sentinel 2 bands </a>.',
            },
          },
        },
        custom: {
          title: 'Custom algebra',
          name: 'Name',
          description: 'Description',
          save: 'Save',
          saveAndApply: 'Save and apply',
          pallet: 'Pallet',
          none: 'None',

          success: `New band algebra "{{name}}" successfully created.`,
          deleted: 'Custom algebra "{{name}}" successfully deleted.',
          deleteError: 'Could not delete algebra',

          single: {
            title: 'Single',
            showOnlyBand: 'Show only band',

            errors: {
              bandNotSelected: 'Select a band',
              noMinimum: 'Minimum Must not be empty',
              noMaximum: 'Maximum Must not be empty',
              noName: 'Name Must not be empty',
            },
          },
          rgb: {
            title: 'RGB',
            minimmum: 'Minimum',
            maximum: 'Maximum',

            errors: {
              min: 'Minimum Must not be empty',
              max: 'Maximum Must not be empty',
              r: 'R Must not be empty',
              g: 'G Must not be empty',
              b: 'B Must not be empty',
              name: 'Name Must not be empty',
            },
          },
          index: {
            title: 'Index',
            expression: 'Expression',
            expressionPlaceholder: 'Enter your expression',

            errors: {
              noExpression: 'Input an expression',
              noMinimum: 'Minimum Must not be empty',
              noMaximum: 'Maximum Must not be empty',
              noName: 'Name Must not be empty',
            },
          },
        },
      },
      vectorOperations: {
        title: 'Vector operations',
        union: 'Union',
        intersection: 'Intersection',
        difference: 'Difference',
        errors: {
          noTwoLayersSelected:
            'The operation can only be applied to two layers',
          layerNotPolygon:
            'The operation can only be applied to polygon layers',
          noOverlap: 'The selected layers do not overlap',
        },
      },
      names: {
        aoi: 'Area of Interest',
        applicationAoi: 'Application AoI',
        filtersAoi: 'Application AoI',
        statisticsAoi: 'Statistics AoI',
        region: 'Region',
        area: 'Polygonal area',
        circle: 'Circle',
        line: 'Line',
        polygon: 'Polygon',
        polyline: 'Polyline',
        rectangle: 'Rectangle',
        polygonDifference: 'Polygon-Difference',
        polygonIntersection: 'Polygon-Intersection',
        polygonUnion: 'Polygon-Union',
      },
      tools: {
        title: 'Layer Tools',
        disabled: 'Layer tools are only available for full resolution images',
        buttons: {
          tool: 'Tool',
          bands: 'Bands',
          submit: 'Submit',
        },
        errors: {
          request: 'There was an error processing your request',
        },
        scatter: {
          title: 'Scatter Plot',
          resetGraph: 'Reset graph',
          help: 'Select two or three bands to display the scatter plot',
        },
        covariance: {
          title: 'Covariance Matrix',
        },
        histogram: {
          title: 'Histogram',
        },
        selectPlaceholder: 'Select an option',
        selectAll: 'Select all',
        removeAll: 'Remove all',
      },
      exportLink: {
        title: 'Export tile layer',
        copyLink: 'Copy link',
        label: 'Export for',
        includeToken: 'Include token',
      },
    },
    rightClickMenu: {
      copyCoords: {
        copyCurrentCoords: 'Copy current coordinates',
        success: 'Copied current coordinates {{coordinates}}',
      },
      line: 'Draw a line',
      polygon: 'Draw an area (polygon)',
      circle: 'Draw an area (circle)',
      rectangle: 'Draw an area (rectangle)',
      select: 'Select this layer',
      polyline: 'Draw a polyline',
      download: 'Download {{ext}}',
    },
    tooltips: {
      header: {
        saveView: 'Save View',
        downloadScreenshot: 'Download Screenshot as {{format}}',
        collapse: 'Collapse',
        listView: 'List View',
        gridView: 'Grid View',
        uploadFile: 'Upload an image to this project',
        noUploadPermissions:
          "You don't have permission to upload images to this project",
      },
      toolbar: {
        catalog: 'Catalog',
        items: 'Project items',
        applications: 'Applications',
        layers: 'Layers menu',
        expandMinimap: 'Expand minimap',
      },

      catalogAndItems: {
        metadata: {
          platformName: 'Platform name',
          sza: 'Sun zenith angle',
          cloudCoverage: 'Cloud coverage',
          location: 'Location',
          price: 'Price',
          collectionType: 'Collection Type',
          processingLevel: 'Processing Level',
          sessionId: 'Session Id',
        },

        icons: {
          previewOnMap: 'Display preview on map',
          showHighResPreview: 'Display full resolution on map',
          removeFromMap: 'Remove from map',
          addToCart: 'Add to shopping cart',
          removeFromCart: 'Remove from shopping cart',
          addToProject: 'Add item to project',
          addedToProject: 'Item added to project',
          centreItemOnMap: 'Center map on item',
          showMetadataAssets: 'Show metadata assets',
          removeFromProject: 'Remove from project',
          imageCannotBePurchased: 'This image cannot be purchased',
        },

        removeFromProject: 'Remove selected items from {{projectName}}',
        removedFromProjectNotification:
          'Item {{item}} removed from project {{project}}',
        addToProject: 'Add selected items to {{projectName}}',
        addedToProjectNotification:
          'Item {{item}} added to project {{project}}',
        fullResAvailable: 'Full resolution images available for this item',
        downloadAsset: 'Download asset',
        disableFullRes: 'Disable full resolution image',
        showFullRes: 'Show full resolution image',

        purchaseDialog: {
          satelliteName: 'Satellite name',
          location: 'Location',
          date: 'Date',
          sunElevationAngle: 'Sun elevation angle',
          cloudCoverage: 'Cloud coverage',
          title: 'Title',
        },
      },
      layers: {
        rightClick: 'Right click the layer for more options',
        nameUnknown: 'Name unknown',
      },
    },
    filters: {
      geometry: 'Geometry',
      currentViewArea: 'Current view area only',
      uploadAoi: 'Upload area of interest',
      drawAoi: 'Draw area of interest',
      clearAoi: 'Clear area of interest',
      satellite: 'Satellite',
      dates: 'Dates',
      hours: 'Hours of the day (UTC)',
      fromHours: 'From',
      toHours: 'To',
      cloudCoverage: 'Cloud coverage (%)',
      processingLevel: 'Processing level',
      productType: {
        title: 'Product type',
        options: {
          Satellite: 'Satellite',
          Vector: 'Vector',
          Insight: 'Insight',
        },
      },
      imageBand: {
        title: 'Image band',
        options: {
          coastal: 'Coastal',
          blue: 'Blue',
          green: 'Green',
          red: 'Red',
          yellow: 'Yellow',
          pan: 'Panchromatic',
          rededge: 'Red Edge',
          rededge1: 'Red Edge 1',
          rededge2: 'Red Edge 2',
          rededge3: 'Red Edge 3',
          nir: 'NIR',
          nir08: 'NIR 0.8 µm',
          nir09: 'NIR 0.9 µm',
          cirrus: 'Cirrus',
          swir16: 'SWIR 1.6 µm',
          swir22: 'SWIR 2.2 µm',
          lwir: 'LWIR',
          lwir11: 'LWIR 11 µm',
          lwir12: 'LWIR 12 µm',
        },
      },
      seasons: {
        title: 'Seasons',
        options: {
          Summer: 'Summer',
          Winter: 'Winter',
          Autumn: 'Autumn',
          Spring: 'Spring',
          Rainy: 'Rainy',
          Dry: 'Dry',
        },
      },
      assetType: 'Asset type',
      source: {
        title: 'Source',
        options: {
          'Satellite Imagery': 'Satellite Imagery',
          Algorithm: 'Algorithm',
        },
      },
      resolution: {
        title: 'Resolution',
        options: {
          full: 'Full',
          limited: 'Limited',
        },
      },
      collectionType: {
        title: 'Collection Type',
        options: {
          Nominal: 'Nominal',
          QA: 'QA',
        },
      },
      qaStatus: {
        title: 'QA Status',
        options: {
          Rejected: 'Rejected',
        },
      },
      clearAll: 'Clear all filters',
      sza: 'SZA',
      oza: 'OZA (degrees)',
      sunGlint: 'Sun glint',
      gsd: 'GSD (m)',
      sensorType: {
        title: 'Sensor type',
        options: {
          Multispectral: 'Multispectral',
          Hyperspectral: 'Hyperspectral',
          Radar: 'Radar',
        },
      },
      platformType: {
        title: 'Platform type',
        options: {
          Satellite: 'Satellite',
          UAV: 'UAV',
        },
      },
      placeholder: 'Search...',
      startDate: 'Start date',
      endDate: 'End date',
      min: 'Min',
      max: 'Max',
      fetchRegion: 'Fetch region',
      display: 'Display',
      detailedTooltips: {
        gsd: {
          content: 'Ground sampling distance',
          description:
            'The Ground Sample Distance is the distance on ground between the centres of two adjacent pixels, equivalently the length of the side of each pixel on ground. Particularly at high roll angles, the GSD may not be be uniform across the image.',
        },
        currentViewArea: {
          content: 'Current view area',
          description:
            'Display images for the currently visible area on the map. When panning the map, the images will be updated automatically.',
        },
        satellite: {
          content: 'Filter by satellite',
          description: "Choose which satellite's images to display.",
        },
        dates: {
          content: 'Filter by dates',
          description:
            'A date range used to filter images. When active, only images taken within the selected date range will be displayed.',
        },
        sza: {
          content: 'Sun zenith angle',
          description:
            "The angle between the sun and the vertical, or the angle between the direction of the sun and a line perpendicular to the Earth's surface at a specific location and time. This angle is measured from the observer's location, and it tells you how high the sun is in the sky.",
        },
        oza: {
          content: 'Observation zenith angle',
          description:
            "OZA refers to the angle between the zenith (the point in the sky directly above an observer) and the line of sight from a satellite or sensor to a specific point on the Earth's surface.",
        },
        cloudCoverage: {
          content: 'Cloud coverage percent',
          description:
            'A measure of the extent to which the sky is covered by clouds at a specific location and time. It is expressed as a percentage and indicates the portion of the sky that is obscured or covered by clouds in relation to the total sky area',
        },
        sunGlint: {
          content: 'Sun glint amount',
          description:
            "Sun glint is a measurement of the intensity of sunlight reflected off the Earth's surface, particularly bodies of water, when observed from satellites or aircraft. It is typically expressed as a numeric value and is used in remote sensing to assess and correct for the impact of intense sunlight reflection on data quality and analysis.",
        },
        hoursOfDay: {
          content: 'Hours of the day',
          description:
            'An hour range used to filter images. When active, only images taken within the selected hour range will be displayed.',
        },
        sensorType: {
          content: 'Sensor type',
          description:
            'The type of sensor on the satellite. When active, only images taken with the selected sensor type will be displayed.',
        },
        processingLevel: {
          content: 'Processing level',
          description:
            "Processing level indicates the stage of data processing and calibration that remote sensing or satellite data has undergone. It provides insight into the data's quality and level of refinement, aiding in its interpretation and use.",
        },
        productType: {
          content: 'Product type',
          description: 'Search by product type.',
        },
        imageBand: {
          content: 'Image band',
          description:
            'Search for images which have data available for a specific band.',
        },
        assetType: {
          content: 'Asset type',
          description: 'Search by type of the asset, (e.g Raster or Vector)',
        },
        sources: {
          content: 'Sources',
          description:
            'The source of the image. When active, only images taken from the selected source will be displayed.',
        },
        resolution: {
          content: 'Resolution',
          description:
            'Search for images with a given resolution such as full or limited',
        },
        platformType: {
          content: 'Platform type',
          description:
            'Search for images taken from a specific platform type (e.g. satellite or uav)',
        },
        seasons: {
          content: 'Seasons',
          description:
            'Search for images taken during a specific season (e.g. Summer).',
        },
        collectionType: {
          content: 'Collection Type',
          description:
            'Search for images from a specific collection type (e.g. Nominal or QA).',
        },
        qaStatus: {
          content: 'QA Status',
          description: 'Search for images based on QA status (e.g. Rejected).',
        },
      },

      resolutions: {
        full: 'Full',
        limited: 'Limited',
      },
      tooltips: {
        closeFilters: 'Close Filters',
      },
      errors: {
        noFilteringByMultiple:
          'Filtering by multiple areas of interest is not supported',
      },
      sections: {
        product: 'Product',
        location: 'Location',
        period: 'Period',
        advanced: 'Advanced',
        imageProcessingLevel: 'Image Processing Level',
        sensorSpecification: 'Sensor Specification',
        observationConditions: 'Observation Conditions',
        dataSpecification: 'Data Specification',
        openCosmosInternal: 'Open Cosmos Internal',
      },
      searchByPoint: {
        coordinateSystem: 'Coordinate system',
        latitude: 'Latitude',
        longitude: 'Longitude',
        easting: 'Easting',
        northing: 'Northing',
        errors: {
          mismatchedFormat: 'Coordinates must have the same format',
          notNumbers: 'Coordinates must be numbers',
          notPairs: 'Coordinates must consist of pairs',
          degreesOrMinutesNotIntegers: 'Degrees and minutes must be integers',
        },
        placeholder: {
          geodetic: {
            x: '-2.745344 or -2 44 43.23 (DD MM SS.SS)',
            y: '26.831494 or 26 49 53.37 (DD MM SS.SS)',
          },
          projected: {
            x: '-2.745344',
            y: '26.831494',
          },
        },
      },
    },

    uploadRegion: {
      fileAlreadyPresent: 'File already present on map',
      filesSupported: '{{files}} files supported',

      geoJsonValidator: {
        invalidType:
          'Uploaded AoI type invalid - Uploaded AoI type should be one of the following: {{allowed}}',
        invalidGeometryType:
          'Uploaded AoI type invalid - Uploaded AoI geometry type should be one of the following: {{allowed}}',

        typeUnsupported:
          'Uploaded AoI type unsupported - the uploaded AoI geometry type must not be any of the following: {{disallowed}}',
        tooLarge:
          'Area too large - the uploaded AoI area must not be larger than 15,000,000 km^2',
        hasHoles:
          'Invalid uploaded AoI - the uploaded AoI has interior rings (holes) that are not supported',
        isClockwise:
          'Invalid uploaded AoI coordinates - Uploaded AoI coordinates must always be in anti-clockwise direction',
      },
    },

    orders: {
      title: 'Orders',
      start: 'Start date',
      end: 'End date',
      details: 'Details',
      buttons: {
        selectedActions: 'Selected actions',
        markAsPaid: 'Mark as paid',
        cancel: 'Cancel',
        printOrders: 'Print orders',
      },
      orderId: 'Order Id',
      orderSummary: {
        title: 'Order summary',
        description_one: '{{count}} catalog item',
        description_other: '{{count}} catalog items',
      },
      orderStatus: {
        title: 'Order status',
        PAID: 'PAID',
        UNPAID: 'UNPAID',
        CANCELLED: 'CANCELLED',
      },
      accessStatus: {
        title: 'Access status',
        downloaded: 'Downloaded',
        partiallyDownloaded: 'Partially Downloaded',
        visualised: 'Visualised',
        expiresIn: 'Access expires in',
      },
      orderAmount: {
        title: 'Total amount',
      },
      orderedOn: 'Ordered on {{date}}',
      orderedBy: 'Ordered by',
      payment: {
        id: 'Payment Id',
        method: 'Payment method',
        BANK_TRANSFER: 'Bank transfer',
        CARD: 'Card',
        NOT_REQUIRED: 'Not required',
        NONE: 'None',
        userReferenceId: 'User Id',
        transactionId: 'Transaction ID',
      },
      items: {
        title: 'Items',
        totalCost: 'Total cost',
        collection: 'Collection',
        item: 'Item',
        level: 'Level',
        requestId: 'Request Id',
        missionId: 'Mission Id',
        area: 'Area',
        paymentDetails: {
          title: 'Payment details',
          noImage: 'Payment receipt not uploaded',
        },
      },
    },

    views: {
      order: 'Order',
      by: 'By',
      sortBy: 'Sort by',
      sortByOptions: {
        'Created+Ascending': 'Oldest created',
        'Created+Descending': 'Last created',
        'Updated+Ascending': 'Oldest updated',
        'Updated+Descending': 'Last updated',
      },
      from: 'From',
      to: 'To',
      inProject: 'In project',
      allProjects: 'All',
      search: 'Search',
      tags: {
        createdAt: 'Created at {{date}}',
        updatedAt: 'Updated at {{date}}',
      },
      expandDescription: 'Expand description',
      editMode: 'Edit mode',
    },
    applications: {
      title: 'Applications',
      runs: 'Application runs',
      previousRuns: 'Previous runs',
      noRuns: 'No application runs found',
      install: 'Install',
      uninstall: 'Uninstall',
      activate: 'Activate',
      deactivate: 'Deactivate',
      global: {
        buttons: {
          open: 'Open',
          submit: 'Submit',
          measure: 'Measure',
        },
        search: {
          placeholder: 'Search for applications...',
        },
        workflows: {
          errors: {
            post: 'Could not submit workflow',
          },
          success: 'Workflow submitted successfully',
          startedOn: 'Started on {{date}} at {{time}}',
          finishedOn: 'Finished on {{date}} at {{time}}',
          estimatedDuration: 'Estimated duration: {{time}}',
          canBeTracedMessage: 'This application runs can be traced',
          status: {
            Running: 'Running',
            Failed: 'Failed',
            Completed: 'Completed',
            Succeeded: 'Succeeded',
          },
        },
        errors: {
          isGeoreferencedWithGCP:
            'WARNING: This application is not designed to process images without a valid CRS. It is recommended to use TOA, surface reflectance, orthorectified or co-registered images',
        },
      },
      'crop-image-to-area-of-interest': {
        title: 'Crop image by AoI',
        description:
          'Creates a workflow that crops an image by a given area of interest',
        shortDescription: 'Crops the image by a given area of interest',

        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
          aoi: {
            title: 'Area of interest: ',
            placeholder: 'AoI name',
          },
          targetProject: {
            title: 'Target project: ',
            description:
              ' Choose the project in which the result of the processing will be stored',
          },
        },
      },
      'toa-product': {
        title: 'TOA Reflectance',
        shortDescription:
          'Calculate Top of Atmosphere reflectance for PeruSAT-1 PRIMARY products',
        description:
          'Calculate Top of Atmosphere (TOA) values for accurate pre-atmospheric correction measurements only on PeruSAT-1 PRIMARY products. Ideal for researchers and professionals in remote sensing.',
        acronym: 'TOA',
        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
        },
      },
      'boa-product': {
        title: 'Surface Reflectance',
        shortDescription:
          'Calculate surface reflectance for PeruSAT-1 PRIMARY products',
        description:
          'Calculate surface reflectance values for accurate pre-atmospheric correction measurements only on PeruSAT-1 PRIMARY products. Ideal for researchers and professionals in remote sensing.',
        acronym: 'SUR',
        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
        },
      },
      perusatIngestion: {
        title: 'Perusat-1 Ingestion',
        shortDescription: 'Ingest Perusat-1 satellite images via FTP',
        description:
          'Streamline the ingestion of Perusat-1 satellite images by entering the FTP URL. Essential for users working with Perusat-1 imagery in analysis workflows.',
        inputs: {
          folderPath: 'FTP folder path',
          sceneId: 'Scene Id',
        },
      },
      satIngestion: {
        title: '{{name}} Ingestion',
        shortDescription: 'Ingest {{name}} satellite images via FTP',
        description:
          'Streamline the ingestion of {{name}} satellite images by entering the FTP URL. Essential for users working with {{name}} imagery in analysis workflows.',
        inputs: {
          folderPath: 'FTP folder path',
          select: 'Select',
        },
      },
      spotIngestion: {
        title: 'SPOT Ingestion',
        shortDescription: 'Ingest SPOT satellite images via FTP',
        description:
          'Streamline the ingestion of SPOT satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make SPOT imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a SPOT image',
        },
      },
      terraSatXIngestion: {
        title: 'TerraSAR-X Ingestion',
        shortDescription: 'Ingest TerraSAR-X satellite images via FTP',
        description:
          'Streamline the ingestion of TerraSAR-X satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make TerraSAR-X imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a TerraSAR-X image',
        },
      },
      pleiadesIngestion: {
        title: 'PLEIADES Ingestion',
        shortDescription: 'Ingest PLEIADES satellite images via FTP',
        description:
          'Streamline the ingestion of PLEIADES satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make PLEIADES imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a PLEIADES image',
        },
      },
      cosmoSkymedIngestion: {
        title: 'COSMO-skymed Ingestion',
        shortDescription: 'Ingest COSMO-skymed satellite images via FTP',
        description:
          'Streamline the ingestion of COSMO-skymed satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make COSMO-skymed imagery available in the platform for all users.',
        inputs: {
          tarFile: 'Enter FTP .tar path of a COSMO-skymed image',
        },
      },
      kompsat3Ingestion: {
        title: 'KompSAT-3 Ingestion',
        shortDescription: 'Ingest KompSAT-3 satellite images via FTP',
        description:
          'Streamline the ingestion of KompSAT-3 satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make KompSAT-3 imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a KompSAT-3 image',
        },
      },
      kazeoSatIngestion: {
        title: 'KazEOSAT Ingestion',
        shortDescription: 'Ingest KazEOSAT satellite images via FTP',
        description:
          'Streamline the ingestion of KazEOSAT satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make KazEOSAT imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a KazEOSAT image',
        },
      },
      vectorIngestion: {
        title: 'Vector data Ingestion',
        shortDescription: 'Ingest vector data via FTP',
        description:
          'Streamline the ingestion of vector datasources by entering the FTP folder URL in CONIDAs servers. This application is available for all users.',
        inputs: {
          zipFile:
            'Enter FTP path of a ZIP file containing GeoJSON, Shapefile or KML files',
        },
      },
      planetIngestion: {
        title: 'Planet Basemap Ingestion',
        shortDescription: 'Ingest Planet basemap tiles',
        description:
          'Streamline the ingestion of Planet basemap tiles by entering the FTP folder URL in CONIDAs servers and a metadata JSON file. This application is used to make Planet basemaps available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP path of a Planet product',
        },
      },
      changeDetection: {
        title: 'Change Detection',
        shortDescription: 'Detect changes between two satellite images',
        description:
          'Efficiently identify changes over time by comparing two full-resolution satellite images. Only available for images with a valid CRS, e.g.: TOA, surface reflectance, orthorectified or co-registered images. Essential for monitoring environmental shifts and land use changes.',
        acronym: 'CHD',
        inputs: {
          fullResImg1: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
          fullResImg2: {
            title: 'Click a second full resolution image on the map',
            placeholder: 'Full res image id...',
          },
          type: {
            title: 'Select type',
            vegetation: 'Vegetation',
            water: 'Water',
            burn: 'Burn',
            placeholder: 'Select an item',
          },
          scale: {
            title: 'Enter scale',
            placeholder: 'Eg. 5',
          },
        },
        submit: {
          success: 'Change detection submitted successfully',
          error: 'Could not submit change detection request',
        },
      },
      supervisedClassification: {
        title: 'Supervised Classification',
        shortDescription:
          'Perform supervised classification on satellite imagery',
        // Copilot generated this. Seems ok.
        description:
          'Classify satellite imagery by specifying classes on image pixels. Only available for images with a valid CRS, e.g.: TOA, surface reflectance, orthorectified or co-registered images. Ideal for researchers and professionals in remote sensing.',
        acronym: 'SCL',
        successResponse: 'Image generated successfully',
        errorResponse: 'There was an error classifying the image',
        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
          classes: {
            tableTitles: {
              name: 'Name',
              color: 'Colour',
            },
            placeholders: {
              name: 'Insert name',
            },
            buttons: {
              addClass: 'Add class',
            },
          },
          algorithm: {
            title: 'Algorithm',
            placeholder: 'Select algorithm',
            mean_squared_error: 'Mean squared error',
            support_vector_machine: 'Support vector machine',
          },
        },

        errors: {
          post: 'Could not perform supervised classification',
          noClasses: 'At least one class must be defined',
          notUnique: 'Class names must be unique',
          missingNames: 'All classes must have a name',
        },
      },
      unsupervisedClassification: {
        title: 'Unsupervised Classification',
        shortDescription:
          'Perform unsupervised classification on satellite imagery',
        description:
          'Classify satellite imagery by specifying the number of classes to use. Only available for images with a valid CRS, e.g.: TOA, surface reflectance, orthorectified or co-registered images.',
        acronym: 'UCL',
        successResponse: 'Image generated successfully',
        errorResponse: 'There was an error classifying the image',
        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
          classes: {
            label: 'Classes',
          },
          algorithm: {
            title: 'Algorithm',
            placeholder: 'Select algorithm',
            gaussian_mixture_models: 'Gaussian mixture models',
            k_means: 'K-means',
          },
        },
        errors: {
          post: 'Could not perform unsupervised classification',
          noClasses: 'The classes number should be greater than 1',
        },
      },
      'orthorectify-image': {
        title: 'Orthorectification',
        shortDescription: 'Orthorectify an image using RPC and DEM.',
        description:
          'Remove distortions or displacements caused by sensor tilt and topographic relief in PeruSAT-1 PRIMARY images, using a sensor model and elevation data',
        successResponse: 'Image generated successfully',
        errorResponse: 'There was an error processing the image',
        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
        },
        errors: {
          post: 'Could not perform orthorectification',
        },
      },
      'co-register-image': {
        title: 'Co-registration',
        shortDescription:
          'Align multiple satellite images to create a unified view',
        description:
          'Align PeruSAT-1 PRIMARY images for analysis, improving their spatial accuracy',
        successResponse: 'Image generated successfully',
        errorResponse: 'There was an error processing the image',
        inputs: {
          fullResImg: {
            activeTitle: 'Click a full resolution image on the map',
            title: 'Image',
            placeholder: 'Full res image id...',
            error: "The selected image's proccessing level is not PRIMARY",
          },
        },
        errors: {
          post: 'Could not perform co-registration',
        },
      },
      'fill-in-missing-pixels': {
        title: 'Fill in Missing Pixels',
        shortDescription:
          'Fill in missing pixels on each of the assets in a given STAC item',
        description:
          'Process each of the assets of a STAC item and interpolate all pixels that have an invalid value',
        acronym: 'FMP',
        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
        },
      },
      'generate-dem': {
        title: 'DEM Generation',
        shortDescription: 'Generate a Digital Elevation Model (DEM)',
        description:
          'Generate a Digital Elevation Model (DEM) from stereo satellite images. Ideal for researchers and professionals in remote sensing.',
        acronym: 'DEM',
        inputs: {
          leftImage: {
            title: 'Click a PRIMARY image on the map',
            placeholder: 'Left image...',
            error: "The selected image's proccessing level is not PRIMARY",
          },
          rightImage: {
            title: 'Click a second PRIMARY image on the map',
            placeholder: 'Right image...',
          },
          stereoAlgorithm: {
            label: 'Stereo algorithm',
            placeholder: 'Select algorithm...',
            asp_bm: 'ASP Block Matching',
            asp_mgm: 'ASP More Global Matching',
            asp_sgm: 'ASP Semi-Global Matching',
            asp_final_mgm: 'ASP Final More Global Matching',
          },
          subpixelMode: {
            label: 'Subpixel mode',
            placeholder: 'Select mode...',
          },
          demReference: {
            label: 'DEM reference',
            error: 'There was an error uploading the file',
            uploading: 'Uploading...',
            uploaded: 'Reference file correctly uploaded',
          },
        },
      },
      aerosolParameters: {
        profile: {
          label: 'Aerosol Profile',
          options: {
            '0': 'No Aerosols',
            '1': 'Continental',
            '2': 'Maritime',
            '3': 'Urban',
            '5': 'Desert',
            '6': 'Biomass Burning',
            '7': 'Stratospheric',
          },
        },
        opticalThickness: {
          label: 'Optical Thickness',
        },
      },
    },
    tours: {
      mainAppTour: {
        catalog: 'Catalog',
        project: 'Projects',
        applications: 'Applications',
        layers: 'Layers',
        endTour: 'End tour',
        catalogDescription:
          'Shows the list of scenes available in the catalog that match the specified filters. These scenes can be selected to perform different operations. A variety of buttons will appear adjacent to data items to enable those operations. Common operations include: <br></br> <ol> <li> 1. Displaying low resolution preview on the map </li> <li> 2. Centring the map on a particular scene </li> <li> 3. Purchasing a scene </li><li> 4. Adding a scene to a project.</li></ol>',
        projectDescription:
          'Organize relevant scenes in your work using projects. Collaborate seamlessly with team members by sharing your projects.',
        applicationsDescription:
          'Here, you will find algorithms, from simple to advanced, that can be run on data but which cannot be achieved with any other tool in DataCosmos. This menu lists a collection of in-house and 3rd party image processing tools which you may activate to further derive insights into the selected data. <br></br> You can open the application, choose the settings, and run it on data, typically data that is already displayed within DataCosmos. Once the application has run, its outputs will appear back in your project, and you can then display them over the map, allowing further analysis to take place. ',
        layersDescription:
          'Any visuals that overlayed to the map are represented by a Layer in this menu. The layers menu contains a set of tools to help with this, and to start to draw insights from the data. Layers may be reordered, shown/hidden, made partially transparent or have “blend modes” applied. <br></br> Additionally, the displayed spectral band for multispectral data can be changed, and bands can be combined from the Band algebra menu to create indices or false colour imagery',
      },
    },
    cart: {
      imageRemoved: 'Image removed from cart: {{id}} - {{title}}',
      imageAdded: 'Image added to cart: {{id}} - {{title}}',
      errors: {
        emptyCartPurchase: 'Cannot complete purchase of empty cart',
        responseError: 'Create order did not return expected response',
      },
    },
    resources: {
      common: {
        backToMain: 'Back to main',
        description: 'Description',
        users: {
          title: 'Users',
          addUser: 'Add User',
        },
        permissions: {
          title: 'Permissions',
          userGroupPermissions: 'User group permissions',
          additionalLinkedPermissions:
            'Additional linked permissions to this resource role',
          noRoleAssignments: 'No role assignments found',
          addRoleAttachments: 'Add role attachments',
          noRolesFound: 'No roles found',
          roleAssignmentDeleted: 'Role assignment was deleted',
          userRoleRemoved: "User's role was removed",
          userRoleRemoveError:
            "Something went wrong when trying to remove a user's role",
        },
      },
      project: {
        title: 'Project',
        organisation: 'Organisation',
      },
      datacosmos_app: 'Data Platform - Application',
      datacosmos_scenario: 'Data Platform - Scenario',
      datacosmos_stac_collection: 'Data Platform - STAC Collection',
      datacosmos_view: 'Data Platform - View',
      datacosmos_stac_item: 'Data Platform - STAC Item',
      global: 'Global',
      mission: 'Mission',
      organisation: 'Organisation',
      programme: 'Programme',
    },
  },
} as const;

export default translation;
