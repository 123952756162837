import { v1 as uuid } from 'uuid';

import type { IStacItem } from 'datacosmos/types/stac-types';
import type { IBandAlgebraLayerOptions } from './bandAlgebraLayer';
import type { IGeoJSONLayerOptions } from './geojsonLayer';

/**
 * LayerSourceType reflects what kind of information is contained in the layer
 * NOTE: The enum is mapped to numbers to differentiate between the enum and
 * strings at runtime.
 */
export enum LayerSourceType {
  ASSET = 1, // Stac asset displayed on map (including overview and visual)
  ASSET_OUTLINE = 2, // asset outline displayed when hovering an asset
  TASKING_OPPORTUNITIES = 3, // for tasking opportunities
  TASKING_REGIONS = 4, // for tasking regions of interest
  ALGEBRA_LAYER = 5, //for create layer with expression, color or/and rescale
  AREA_OF_INTEREST = 7, //for geojson shapes uploaded by the user in the catalog page
  GEOMETRY_LAYER = 6, //for lines, polygons and circles drawn by user for measuring purpouses
  APPLICATION_AOI = 8, //for geojson shapes uploaded or drawn by the user in the application page
  OVERLAY = 9, //for overlays such as images and videos
  STS_REQUEST_AOI = 10, //for areas of interest fetched from existing tasking requests
  STS_ACTIVITY_SWATH = 11, //for swaths fetched from existing activities
  PIXEL_MODE_MARKER = 12, //for marking the selected pixel in pixel mode
  STATISTICS_AOI = 13, // aoi for statistics
  CLASSIFICATION_MARKER = 14, // marker for supervised classification CONDATA app
  SHIFT_IMAGE_POINT_MARKER = 15, // marker for shift image app's selected image pixel
  COREGISTRATION_MARKER = 16, // marker for co-registration app's match location
  UPLOAD_FILE_MARKER = 17, // marker for non-georeferenced file's location
  CUSTOM_FILE_MARKER = 18, // for files uploaded by the user
}

/** Generic layer options */
export interface ILayerOptions {
  visible: boolean;
  opacity: number;
  blendMode: string;
  isSelected?: boolean;
  bandAlgebraType?: string;
  zIndex?: number;
}

export interface ILayerProperty {
  name:
    | keyof ILayerOptions
    | keyof IBandAlgebraLayerOptions
    | keyof IGeoJSONLayerOptions;
  value?: string | number;
}

export interface ILayerDisableEditingOptions {
  toggleEditingRef?: boolean;
}
/**
 * Layer represents an item displayed on the map. In datacosmos, layers have
 * options that can be adjusted.
 */
export abstract class Layer {
  id: string;
  options: ILayerOptions;
  sourceType: LayerSourceType;
  layerClass: string | undefined;

  /**
   * newDataCosmosLayer creates a new datacosoms layer
   */
  constructor(sourceType: LayerSourceType) {
    this.id = uuid();
    this.options = {
      visible: true,
      opacity: 1,
      blendMode: 'normal',
    };
    this.sourceType = sourceType;

    return;
  }

  clone() {
    const protype = Object.getPrototypeOf(this) as unknown as object;
    const objectWithPrototype = Object.create(protype) as unknown as object;
    return Object.assign(objectWithPrototype, this) as this;
  }

  /**
   * returns the layer's name
   */
  abstract getName(): string;

  /**
   * returns the layer's description
   */
  abstract getDescription(): string | null;

  /**
   * returns true if the layer is related to the STAC item provided
   */
  abstract containsSTACItem(item: IStacItem): boolean;

  /**
   * returns true if the layer is related to the STAC item provided
   */
  abstract containsSTACAsset(item: IStacItem, assetKey: string): boolean;

  /**
   * returns true if the layer is displaying the given STAC item with the given band algebra expression
   */
  abstract containsBandAlgebra(item: IStacItem, expression: string): boolean;

  /**
   * returns the solar zenith angle for the layer, if any is defined
   */
  abstract sza(): Number | undefined;

  /**
   * returns the href (path) to the item displayed in the layer, if any exists
   */
  abstract href(): string | undefined;

  /**
   * Returns a copy of the current object with the selected options updates.
   */
  cloneWithOptions(options: Partial<ILayerOptions>) {
    const newObject = this.clone();
    newObject.options = { ...newObject.options, ...options };
    return newObject;
  }
}
