/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { StacItem } from 'datacosmos/types/stac-types';
import { isAssetPreviewable } from 'datacosmos/utils/stac';
import moment from 'moment';
import ListItem from '_atoms/ListItem/ListItem';
import { ListOrientation } from '_molecules/UnorderedList/UnorderedList';
import GridCard from '_organisms/GridCard/GridCard';
import useStacInfo from 'datacosmos/utils/hooks/useStacInfo';
import bbox from '@turf/bbox';

//Images
import Polygon from 'images/datacosmos/Measures_area.svg';
import Line from 'images/datacosmos/Measures.svg';
import Circle from 'images/datacosmos/Circle.svg';
import AOI from 'images/datacosmos/Custom_AOI.svg';
import Unknown from 'images/datacosmos/Help.svg';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { STACLayerFactory } from 'datacosmos/entities/stacLayer';
import { Item } from 'react-stately';
import Select2 from '_molecules/Select2/Select2';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import DownloadLayerMenu from 'datacosmos/components/RightClickMenu/DownloadLayer';
import { useFilters } from 'datacosmos/stores/FiltersProvider';
import { Tooltip, Button } from 'opencosmos-ui';
import { useState } from 'react';
import ExportStacItemLink from './ExportStacItemLink';

type IProps = {
  layer: Layer;
  readOnly?: boolean;
};
const LayerGridCard = ({ layer, readOnly }: IProps) => {
  const { replaceLayer, removeLayer, replaceSpecificLayerWithAnother } =
    useMapLayers();
  const { disableEditing, setViewToFitBbox } = useMap();

  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const { areaOfInterest, setAreaOfInterest } = useFilters();

  const [isOpenExportLink, setIsOpenExportLink] = useState<boolean>(false);

  const renderDownloadAsFormat = (
    l: LineLayer | PolygonLayer | CircleLayer
  ) => (
    <DownloadLayerMenu
      layer={l}
      defaultValue={'kml'}
      className="flex max-w-[200px]"
    />
  );

  const renderStacItemLayerCard = (stacLayer: SingleBandSTACLayer) => {
    const {
      cloudCoverage,
      getSelectedAsset,
      handleBandRemove,
      handleBandSelect,
      selectedBands,
      sunElevationAngle,
      thumbnailAsset,
      previewUrl,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useStacInfo(stacLayer);
    Boolean(stacLayer.item.tileURL);
    return (
      <>
        <GridCard
          onClick={() => {}}
          title={{
            content: moment(stacLayer.item.date()).format('YYYY MMM DD'),
          }}
          image={{
            src: thumbnailAsset
              ? thumbnailAsset.href
              : '/images/datacosmos/img-placeholder.svg',
            position: 'left',
            sizeInPx: 100,
            previewImgUrl: previewUrl === null ? undefined : previewUrl,
          }}
          info={{
            orientation: ListOrientation.HorizontalTwoColumns,
            content: (
              <>
                <ListItem
                  icon="Satellite"
                  content={stacLayer.item.title()}
                  className="text-item-contrast-inactive stroke-item-contrast-inactive"
                  onClick={() => {}}
                />
                <ListItem
                  icon="SunAngle"
                  content={sunElevationAngle}
                  className="text-item-contrast-inactive stroke-item-contrast-inactive"
                  onClick={() => {}}
                />

                {stacLayer instanceof BandAlgebraSTACLayer ? (
                  <ListItem
                    icon="BandAlgebra"
                    content={stacLayer.expression}
                    className="text-item-contrast-inactive stroke-item-contrast-inactive truncate max-w-label w-min overflow-ellipsis"
                    onClick={() => {}}
                    noTruncate={true}
                  />
                ) : (
                  <ListItem
                    icon="Cloud"
                    content={cloudCoverage}
                    className="text-item-contrast-inactive stroke-item-contrast-inactive"
                    onClick={() => {}}
                  />
                )}
              </>
            ),
          }}
          rightIcons={[
            {
              icon: stacLayer.options.visible ? 'Eye' : 'EyeCrossed',
              onClick: () => {
                replaceLayer(
                  stacLayer.cloneWithOptions({
                    visible: !stacLayer.options.visible,
                  })
                );
              },
              shown: true,
            },
            {
              icon: 'Center',
              onClick: () => {
                setViewToFitBbox(stacLayer.item.bbox);
              },
              shown: true,
            },
            {
              icon: 'ArrowExport',
              onClick: () => {
                setIsOpenExportLink(true);
              },
              shown: Boolean(stacLayer.item.tileURL),
            },
            {
              icon: 'Cross',
              onClick: () => {
                removeLayer(stacLayer.id);
              },
              shown: true,
              isDisabled: readOnly,
            },
          ]}
          actionElement={
            <Select2
              items={Object.entries(stacLayer.item.assets).filter(([, asset]) =>
                isAssetPreviewable(asset)
              )}
              onSelectionChange={(key) => {
                const toRemove = selectedBands.find((b) => b[0] === key);
                const toAdd = Object.entries(stacLayer.item.assets).find(
                  (item) => item[0] === key
                );
                if (toRemove !== undefined) {
                  handleBandRemove(toRemove);
                } else if (toAdd !== undefined) {
                  handleBandSelect(toAdd);
                }
                const l = STACLayerFactory({
                  item: new StacItem(stacLayer.item),
                  assetKey: key.toString(),
                  expression: undefined,
                });
                replaceSpecificLayerWithAnother(l, stacLayer, true);
              }}
              selectedItem={getSelectedAsset()}
              className="max-w-[200px] min-w-[200px]"
              fill
            >
              {(item) => (
                <Item key={item[0]} textValue={item[1].title}>
                  <div className="flex items-center justify-between gap-1">
                    <div className="flex flex-col">
                      <span>{item[1].title ?? item[0]}</span>
                      {item[1].title && (
                        <span className="text-xs">{item[0]}</span>
                      )}
                    </div>
                    <Tooltip
                      content={translate(
                        'datacosmos.tooltips.catalogAndItems.downloadAsset'
                      )}
                    >
                      <Button
                        icon="Download"
                        isMinimal
                        isTransparent
                        onPress={() => {
                          window.open(item[1].href, '_blank');

                          sendInfo({
                            action: `Download asset: ${
                              item[1].title ?? item[0]
                            }}`,
                            item: 'Download asset button',
                            type: 'Click',
                            module: 'DataCosmos',
                            download: item[1].href,
                            additionalParams: {
                              asset: item[1].title ?? item[0],
                            },
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                </Item>
              )}
            </Select2>
          }
          indicators={[
            {
              icon: 'ImageBrightness',
              shown: stacLayer.isBrightnessApplied?.(),
            },
            {
              icon: 'ImageSaturation',
              shown: stacLayer.isSaturationApplied?.(),
            },
            { icon: 'ImageContrast', shown: stacLayer.isContrastApplied?.() },
          ]}
        />
        <ExportStacItemLink
          isOpen={isOpenExportLink}
          setIsOpen={setIsOpenExportLink}
          stacItem={stacLayer.item}
        />
      </>
    );
  };

  const getLineAndPolygonLayerImgSrc = (
    lLayer: LineLayer | PolygonLayer
  ): string => {
    if (lLayer instanceof LineLayer) {
      return Line;
    }
    if (
      lLayer.sourceType === LayerSourceType.APPLICATION_AOI ||
      lLayer.sourceType === LayerSourceType.AREA_OF_INTEREST
    ) {
      return AOI;
    }
    return Polygon;
  };

  const renderLineAndPolygonLayerCards = (lLayer: LineLayer | PolygonLayer) => (
    <GridCard
      onClick={() => {}}
      title={{ content: lLayer.getName(), noTruncate: true }}
      info={{
        content: (
          <>
            <ListItem
              content={lLayer.getDescription() ?? ''}
              noTruncate
              onClick={() => {}}
            />
            <ListItem
              content={lLayer.getMeasurements()}
              noTruncate
              onClick={() => {}}
            />
          </>
        ),
        orientation: ListOrientation.Horizontal,
      }}
      image={{
        src: getLineAndPolygonLayerImgSrc(lLayer),
        position: 'left',
        sizeInPx: 100,
        className: 'dark:invert',
      }}
      rightIcons={[
        {
          icon: lLayer.options.visible ? 'Eye' : 'EyeCrossed',
          onClick: () => {
            replaceLayer(
              lLayer.cloneWithOptions({
                visible: !lLayer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(lLayer.data));
          },
          shown: true,
        },
        {
          icon: 'Cross',
          onClick: () => {
            removeLayer(lLayer.id);
            const shouldRemoveEditingPolygonLayer =
              lLayer instanceof PolygonLayer;
            if (shouldRemoveEditingPolygonLayer) {
              disableEditing(lLayer.getLeafletLayerMetadata(), {
                toggleEditingRef: shouldRemoveEditingPolygonLayer,
              });
              if (
                lLayer.sourceType === LayerSourceType.AREA_OF_INTEREST &&
                areaOfInterest
              ) {
                setAreaOfInterest(undefined);
              }
            }
          },
          shown: true,
        },
      ]}
      actionElement={renderDownloadAsFormat(lLayer)}
    />
  );

  const renderCircleLayerCard = (circleLayer: CircleLayer) => (
    <GridCard
      onClick={() => {}}
      title={{ content: circleLayer.getName(), noTruncate: true }}
      info={{
        content: (
          <>
            <ListItem
              content={circleLayer.getDescription() ?? ''}
              noTruncate
              onClick={() => {}}
            />
            <ListItem
              content={circleLayer.getMeasurements()}
              noTruncate
              onClick={() => {}}
            />
            <ListItem
              content={circleLayer.getRadius()}
              noTruncate
              onClick={() => {}}
            />
          </>
        ),
        orientation: ListOrientation.Horizontal,
      }}
      image={{
        src: Circle,
        position: 'left',
        sizeInPx: 100,
        className: 'dark:invert',
      }}
      rightIcons={[
        {
          icon: circleLayer.options.visible ? 'Eye' : 'EyeCrossed',
          onClick: () => {
            replaceLayer(
              circleLayer.cloneWithOptions({
                visible: !circleLayer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(circleLayer.data));
          },
          shown: true,
        },
        {
          icon: 'Cross',
          onClick: () => {
            removeLayer(circleLayer.id);
            disableEditing(circleLayer.getLeafletLayerMetadata(), {
              toggleEditingRef: true,
            });
            if (
              circleLayer.sourceType === LayerSourceType.AREA_OF_INTEREST &&
              areaOfInterest
            ) {
              setAreaOfInterest(undefined);
            }
          },
          shown: true,
        },
      ]}
      actionElement={renderDownloadAsFormat(circleLayer)}
    />
  );

  const renderUnknownLayerCard = (unknownLayer: Layer) => (
    <GridCard
      onClick={() => {}}
      title={{
        content: unknownLayer?.getName(),
        noTruncate: true,
      }}
      info={{
        content: (
          <ListItem
            icon="Info"
            content={unknownLayer.getDescription() ?? ''}
            onClick={() => {}}
            noTruncate
            className="mt-1"
          />
        ),
        orientation: ListOrientation.Horizontal,
      }}
      image={{
        src: Unknown,
        position: 'left',
        sizeInPx: 100,
      }}
      rightIcons={[
        {
          icon: unknownLayer.options.visible ? 'Eye' : 'EyeCrossed',
          onClick: () => {
            replaceLayer(
              unknownLayer.cloneWithOptions({
                visible: !unknownLayer.options.visible,
              })
            );
          },
          shown: true,
        },

        {
          icon: 'Cross',
          onClick: () => {
            removeLayer(unknownLayer.id);
          },
          shown: true,
        },
      ]}
    />
  );

  if (
    layer instanceof SingleBandSTACLayer ||
    layer instanceof BandAlgebraSTACLayer ||
    layer instanceof OutlineLayer
  ) {
    return renderStacItemLayerCard(layer as SingleBandSTACLayer);
  } else if (layer instanceof LineLayer || layer instanceof PolygonLayer) {
    return renderLineAndPolygonLayerCards(layer);
  } else if (layer instanceof CircleLayer) {
    return renderCircleLayerCard(layer);
  }
  return renderUnknownLayerCard(layer);
};

export default LayerGridCard;
