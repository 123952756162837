import DangerButton from '_molecules/Button/DangerButton';
import PrimaryButton from '_molecules/Button/PrimaryButton';
import OrderCard from '_organisms/OrderCard/OrderCard';
import OrderItemCard from '_organisms/OrderItemCard/OrderItemCard';
import React, { useCallback, useMemo } from 'react';
import { DateInput } from 'ui/DateInput';
import { Header } from 'ui/Header';
import { ItemTabContainer } from 'ui/ItemContainer';
import { Navbar } from 'ui/Navbar';
import { Screen } from 'ui/Screen';
import { Text } from 'ui/Text';
import { useOrdersScreenData } from './useOrdersScreenData';
import { NonIdealState } from '@blueprintjs/core';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button } from 'opencosmos-ui';
import {
  CompositeDetailsItem,
  DetailsItem,
} from 'pages/shared/components/Item';
import InputFileButton from 'components/buttons/InputFileButton';
import { downloadFile } from 'utils/common/CommonUtils';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import InfiniteScroll from 'react-infinite-scroller';

const OrdersScreen = () => {
  const {
    ordersByDate,
    isOrdersLoading,
    toggleOrderCheck,
    checkedOrder,
    setToDateFilter,
    setFromDateFilter,
    fromDateFilter,
    toDateFilter,
    toggleOrderSelect,
    isOrderSelected,
    selectedOrder,
    customer,
    isCustomerLoading,
    isAdmin,
    markOrderAsPaid,
    markOrderAsCancelled,
    getOrderTotalCost,
    isOrderChecked,
    uploadOrderPaymentImage,
    selectedOrderPaymentImage,
    isSelectedOrderPaymentImageLoading,
    isImageUploaded,
    hasMoreOrders,
    currentPage,
    loadNextPage,
    isNextOrdersLoading,
    getOrders,
    setIsOrdersLoading,
    shouldClearAllDateSegments,
    setShouldClearAllDateSegments,
    subject,
    isSubjectLoading,
    getOrderAccessStatus,
    isMarkingAsPaid,
    isMarkingAsCancelled,
  } = useOrdersScreenData();

  const { translate } = useLocalisation();

  const withNoOrderSelected = (children: React.ReactNode) => {
    if (!selectedOrder) {
      return (
        <NonIdealState
          title={
            <span className="dark:text-item-dark-contrast">
              {translate('datacosmos.fetchErrors.orders.order.noOrderSelected')}
            </span>
          }
          description={translate(
            'datacosmos.fetchErrors.orders.order.noOrderSelectedDescription'
          )}
          className="dark:text-item-dark-contrast"
          icon="info-sign"
        />
      );
    }

    return children;
  };

  const withNoOrdersFoundInDateRange = (children: React.ReactNode) => {
    if (
      ordersByDate &&
      Object.keys(ordersByDate).length === 0 &&
      (fromDateFilter || toDateFilter)
    ) {
      return (
        <NonIdealState
          icon="info-sign"
          title={
            <span className="dark:text-item-dark-contrast">
              {translate('datacosmos.fetchErrors.orders.order.noOrdersFound')}
            </span>
          }
          description={translate(
            'datacosmos.fetchErrors.orders.order.noOrdersFoundFiltersDescription'
          )}
          className="dark:text-item-dark-contrast"
        />
      );
    }

    return children;
  };

  const withNoOrdersFetched = (children: React.ReactNode) => {
    if (
      ordersByDate &&
      Object.keys(ordersByDate).length === 0 &&
      (!fromDateFilter || !toDateFilter)
    ) {
      return (
        <NonIdealState
          icon="info-sign"
          title={
            <span className="dark:text-item-dark-contrast">
              {translate('datacosmos.fetchErrors.orders.order.noOrdersFound')}
            </span>
          }
          description={translate(
            'datacosmos.fetchErrors.orders.order.noOrdersFoundDescription'
          )}
          className="dark:text-item-dark-contrast"
        />
      );
    }

    return children;
  };

  const withLoadingOrders = (children: React.ReactNode) => {
    if (isOrdersLoading) {
      return (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      );
    }

    return children;
  };

  const selectedOrderTotalCost = useMemo(() => {
    if (!selectedOrder) {
      return;
    }
    return getOrderTotalCost(selectedOrder);
  }, [selectedOrder, getOrderTotalCost]);

  const downloadReciept = useCallback(() => {
    if (!selectedOrderPaymentImage) {
      return;
    }
    downloadFile(
      selectedOrderPaymentImage as BlobPart,
      `payment-order-${selectedOrder?.external_payment_id}.png`
    );
  }, [selectedOrder, customer, selectedOrderPaymentImage, downloadFile]);

  const isMarkAsPaidAllowed = useMemo(() => {
    if (!selectedOrder?.external_payment_id) {
      return true;
    }
    return Boolean(selectedOrderPaymentImage);
  }, [selectedOrder, selectedOrderPaymentImage]);

  return (
    <Screen>
      <Navbar hideThemesInUserMenu={false} />
      <div className="flex color-surface overflow-hidden gap-2 orders-container">
        <div className="w-[66%] orders-section">
          <div className="flex justify-between items-center gap-2 px-2">
            <Header size="h1">{translate('datacosmos.orders.title')}</Header>
            <ItemTabContainer>
              <DateInput
                setValue={async (from) => {
                  setFromDateFilter(from);
                  setIsOrdersLoading(true);
                  await getOrders(1, from, toDateFilter);
                  setIsOrdersLoading(false);
                }}
                value={fromDateFilter}
                title={translate('datacosmos.orders.start')}
                shouldClearAllDateSegments={shouldClearAllDateSegments}
                setShouldClearAllDateSegments={setShouldClearAllDateSegments}
              />
              <DateInput
                setValue={async (to) => {
                  setToDateFilter(to);
                  setIsOrdersLoading(true);
                  await getOrders(1, fromDateFilter, to);
                  setIsOrdersLoading(false);
                }}
                value={toDateFilter}
                title={translate('datacosmos.orders.end')}
                shouldClearAllDateSegments={shouldClearAllDateSegments}
                setShouldClearAllDateSegments={setShouldClearAllDateSegments}
              />
              {(fromDateFilter || toDateFilter) && (
                <Button
                  icon="Cross"
                  iconOnly
                  className={'no-print'}
                  onPress={async () => {
                    setIsOrdersLoading(true);
                    setShouldClearAllDateSegments(true);
                    await getOrders(1);
                    setIsOrdersLoading(false);
                    setShouldClearAllDateSegments(false);
                    setFromDateFilter(undefined);
                    setToDateFilter(undefined);
                  }}
                />
              )}
            </ItemTabContainer>
            <Button
              onPress={() => window.print()}
              icon="Print"
              className={'no-print'}
            >
              {translate('datacosmos.orders.buttons.printOrders')}
            </Button>
          </div>

          {checkedOrder.every((or) => or.status !== 'CANCELLED') ? (
            <div className="no-print">
              {checkedOrder.length > 0 &&
                checkedOrder.every((or) => or.status === 'UNPAID') && (
                  <ItemTabContainer className="flex justify-end gap-1">
                    <Text>
                      {translate('datacosmos.orders.buttons.selectedActions')}
                    </Text>
                    {isAdmin &&
                      checkedOrder.every((or) => !or.external_payment_id) && (
                        <PrimaryButton
                          text={translate(
                            'datacosmos.orders.buttons.markAsPaid'
                          )}
                          onPress={async () => {
                            if (checkedOrder.length === 0) {
                              return;
                            }
                            await Promise.all(
                              checkedOrder.map((o) => markOrderAsPaid(o))
                            );
                          }}
                        />
                      )}
                    <DangerButton
                      text={translate('datacosmos.orders.buttons.cancel')}
                      onPress={async () => {
                        if (checkedOrder.length === 0) {
                          return;
                        }
                        await Promise.all(
                          checkedOrder.map((o) => markOrderAsCancelled(o))
                        );
                      }}
                    />
                  </ItemTabContainer>
                )}
            </div>
          ) : (
            <></>
          )}

          <div
            className="p-2 overflow-auto overflow-x-hidden h-screen max-h-[85%] orders-list"
            data-testid="orders-list"
          >
            <InfiniteScroll
              initialLoad={false}
              loadMore={() => {
                if (isOrdersLoading) {
                  return;
                }

                void loadNextPage(currentPage + 1);
              }}
              hasMore={hasMoreOrders}
              useWindow={false}
              style={{
                display: 'flex',
                flexFlow: 'column',
                height: '100%',
              }}
            >
              {withLoadingOrders(
                withNoOrdersFetched(
                  withNoOrdersFoundInDateRange(
                    Object.entries(ordersByDate ?? {}).map(([date, orders]) => (
                      <div key={date} className="flex flex-col gap-2 mb-2">
                        <Header size="h3">{date}</Header>
                        {orders.map((o) => (
                          <OrderCard
                            onClick={() => {
                              toggleOrderSelect(o);
                            }}
                            key={o.id}
                            fieldsToShow={['all']}
                            order={o}
                            isSelected={isOrderSelected(o)}
                            checkbox={{
                              onChange: () => {
                                toggleOrderCheck(o);
                              },
                              isSelected: isOrderChecked(o),
                              isDisabled: o.status === 'PAID',
                            }}
                            orderCost={getOrderTotalCost(o)}
                            orderAccessStatus={getOrderAccessStatus(o)}
                            showImageBadge={async () => {
                              if (!isAdmin) {
                                return false;
                              }
                              const showBadge = await isImageUploaded(o);
                              return showBadge;
                            }}
                          />
                        ))}
                      </div>
                    ))
                  )
                )
              )}

              {isNextOrdersLoading ? (
                <div className="flex justify-center items-center">
                  <Spinner />
                </div>
              ) : null}
            </InfiniteScroll>
          </div>
        </div>

        {/* Details */}
        <div className="overflow-auto overflow-x-hidden no-print block mx-auto w-[33%]">
          {withNoOrderSelected(
            <div className=" flex flex-col gap-4 p-2">
              <div className="flex justify-between items-center">
                <Header size="h2">
                  {translate('datacosmos.orders.details')}
                </Header>
                {selectedOrder?.status !== 'CANCELLED' &&
                  selectedOrder?.status === 'UNPAID' && (
                    <ItemTabContainer className="gap-1 items-center mt-2">
                      {isAdmin && (
                        <PrimaryButton
                          text={
                            !isMarkingAsPaid ? (
                              translate('datacosmos.orders.buttons.markAsPaid')
                            ) : (
                              <Spinner size={14} />
                            )
                          }
                          disabled={!isMarkAsPaidAllowed}
                          onPress={async () => {
                            if (!selectedOrder) {
                              return;
                            }

                            await markOrderAsPaid(selectedOrder);
                          }}
                        />
                      )}
                      <DangerButton
                        text={
                          !isMarkingAsCancelled ? (
                            translate('datacosmos.orders.buttons.cancel')
                          ) : (
                            <Spinner size={14} />
                          )
                        }
                        onPress={async () => {
                          if (!selectedOrder) {
                            return;
                          }

                          await markOrderAsCancelled(selectedOrder);
                        }}
                      />
                    </ItemTabContainer>
                  )}
              </div>

              <div className="min-w-min flex flex-col gap-2">
                <DetailsItem
                  title={translate('datacosmos.orders.orderId')}
                  value={selectedOrder?.id ?? '-'}
                />
                <DetailsItem
                  title={translate('datacosmos.orders.orderStatus.title')}
                  value={
                    selectedOrder?.status
                      ? translate(
                          `datacosmos.orders.orderStatus.${selectedOrder.status}`
                        )
                      : '-'
                  }
                />
                <DetailsItem
                  title={translate('datacosmos.orders.payment.method')}
                  value={
                    selectedOrder?.payment_method
                      ? translate(
                          `datacosmos.orders.payment.${selectedOrder.payment_method}`
                        )
                      : '-'
                  }
                />
                <DetailsItem
                  title={translate('datacosmos.orders.payment.id')}
                  value={selectedOrder?.payment_reference_number ?? '-'}
                />

                <DetailsItem
                  title={translate('datacosmos.orders.payment.userReferenceId')}
                  value={selectedOrder?.external_payment_id ?? '-'}
                />

                <DetailsItem
                  title={translate('datacosmos.orders.payment.transactionId')}
                  value={selectedOrder?.transaction_id ?? '-'}
                />

                {selectedOrder && (
                  <DetailsItem
                    title={translate(
                      'datacosmos.orders.accessStatus.downloaded'
                    )}
                    value={
                      getOrderAccessStatus(selectedOrder)
                        ? translate(
                            `datacosmos.orders.accessStatus.${getOrderAccessStatus(
                              selectedOrder
                            )}` as unknown as TemplateStringsArray
                          )
                        : '-'
                    }
                  />
                )}
                <CompositeDetailsItem
                  title={translate('datacosmos.orders.orderedBy')}
                  element={
                    isCustomerLoading || isSubjectLoading ? (
                      <div className="flex justify-end">
                        <Spinner size={12} />
                      </div>
                    ) : (
                      subject?.name ?? selectedOrder?.created_by?.split('|')[1]
                    )
                  }
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <Header size="h2">
                    {translate('datacosmos.orders.items.title')}{' '}
                  </Header>
                  <div className="text-item-contrast dark:text-item-dark-contrast font-semibold">
                    {translate('datacosmos.orders.items.totalCost')}:{' '}
                    {selectedOrderTotalCost}
                  </div>
                </div>
                {selectedOrder?.order_line_items.map((item) => (
                  <OrderItemCard orderItem={item} key={item.item} />
                ))}
                {selectedOrder?.type === 'IMAGE' &&
                  selectedOrder?.external_payment_id && (
                    <div>
                      <div className="flex justify-between items-center">
                        <Header size="h2">
                          {translate(
                            'datacosmos.orders.items.paymentDetails.title'
                          )}
                        </Header>
                        {!isAdmin &&
                          selectedOrder?.status === 'UNPAID' &&
                          !selectedOrderPaymentImage && (
                            <InputFileButton
                              id="upload-payment-reciept-btn"
                              text={translate('datacosmos.buttons.upload')}
                              accept="image/*"
                              onChange={async (e) => {
                                if (!selectedOrder) {
                                  return;
                                }
                                const uploadedFiles = e.target.files;

                                if (!uploadedFiles?.length) {
                                  return;
                                }
                                const formData = new FormData();
                                for (const file of uploadedFiles) {
                                  formData.append('image', file);
                                }

                                await uploadOrderPaymentImage(
                                  selectedOrder,
                                  formData
                                );
                              }}
                            />
                          )}
                      </div>
                      {selectedOrder?.type === 'IMAGE' &&
                        selectedOrder?.external_payment_id && (
                          <>
                            {isSelectedOrderPaymentImageLoading ? (
                              <div className="flex justify-center items-center my-3">
                                <Spinner />
                              </div>
                            ) : selectedOrderPaymentImage ? (
                              <div className="flex flex-col gap-2">
                                <img
                                  src={URL.createObjectURL(
                                    selectedOrderPaymentImage
                                  )}
                                  className="w-full h-full"
                                />
                                {isAdmin && selectedOrderPaymentImage && (
                                  <Button
                                    icon="Download"
                                    fill
                                    onPress={() => downloadReciept()}
                                  >
                                    {translate('datacosmos.buttons.download')}
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <p className="my-2">
                                {translate(
                                  'datacosmos.orders.items.paymentDetails.noImage'
                                )}
                              </p>
                            )}
                          </>
                        )}
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Screen>
  );
};

export default OrdersScreen;
