import {
  COLORMAP,
  type BandAlgebraTypeLayerOption,
} from 'datacosmos/entities/bandAlgebraLayer';

import ndvi_thumbnail from 'images/datacosmos/ndvi-thumbnail.jpeg';
import gnvdi_thumbnail from 'images/datacosmos/gnvdi-thumbnail.png';
import avi_thumbnail from 'images/datacosmos/avi-thumbnail.jpeg';
import savi_thumbnail from 'images/datacosmos/savi-thumbnail.png';
import arvi_thumbnail from 'images/datacosmos/arvi-thumbnail.png';
import ndmi_thumbnail from 'images/datacosmos/ndmi-thumbnail.jpeg';
import msi_thumbnail from 'images/datacosmos/msi-thumbnail.jpeg';
import nbr_thumbnail from 'images/datacosmos/nbr-thumbnail.png';
import bsi_thumbnail from 'images/datacosmos/bsi-thumbnail.png';
import ndwi_gao_thumbnail from 'images/datacosmos/ndwi_gao-thumbnail.png';
import ndwi_mcfeeters_thumbnail from 'images/datacosmos/ndwi_mcfeeters-thumbnail.png';
import ndsi_thumbnail from 'images/datacosmos/ndsi-thumbnail.jpeg';
import sipi_thumbnail from 'images/datacosmos/sipi-thumbnail.png';
import sr_thumbnail from 'images/datacosmos/sr-thumbnail.png';
import npcri_thumbnail from 'images/datacosmos/npcri-thumbnail.png';
import evi_thumbnail from 'images/datacosmos/evi-thumbnail.png';
import fai_thumbnail from 'images/datacosmos/fai-thumbnail.png';
import gci_thumbnail from 'images/datacosmos/gci-thumbnail.jpg';
import ndgi_thumbnail from 'images/datacosmos/ndgi-thumbnail.jpg';

import { clientTranslate } from 'utils/hooks/useLocalisation';

/**
 * Satellites that support bands
 */
enum SATELLITE_NAMES {
  SENTINEL_2 = 'sentinel-2',
  GEOSAT_2 = 'geosat-2',
  LANDSAT_8 = 'landsat-8',
}

export enum EXPRESSION_NAMES {
  NDVI = 'NDVI',
  GNDVI = 'GNDVI',
  AVI = 'AVI',
  SAVI = 'SAVI',
  ARVI = 'ARVI',
  NDMI = 'NDMI',
  MSI = 'MSI',
  GCI = 'GCI',
  NBR = 'NBR',
  BSI = 'BSI',
  NDWI_GAO = 'NDWI_GAO',
  NDWI_MCFEETERS = 'NDWI_MCFEETERS',
  NDSI = 'NDSI',
  NDGI = 'NDGI',
  SIPI = 'SIPI',
  SR = 'SR',
  NPCRI = 'NPCRI',
  EVI = 'EVI',
  FAI = 'FAI',
}

export const COMMON_BAND_NAMES = [
  'coastal',
  'red',
  'green',
  'blue',
  'yellow',
  'pan',
  'rededge1',
  'rededge2',
  'rededge3',
  'nir',
  'nir08',
  'nir09',
  'cirrus',
  'swir16',
  'swir22',
  'lwir',
  'lwir11',
  'lwir12',
];

//Band names are taken from here https://github.com/stac-extensions/eo#common-band-names
export interface ISatelliteWithBands {
  [satellite: string]: {
    coastal?: string;
    blue?: string;
    green?: string;
    red?: string;
    yellow?: string;
    pan?: string;
    rededge1?: string;
    rededge2?: string;
    rededge3?: string;
    nir?: string;
    nir08?: string;
    nir09?: string;
    cirrus?: string;
    swir16?: string;
    swir22?: string;
    lwir?: string;
    lwir11?: string;
    lwir12?: string;
    'nir 3N'?: string;
    'nir 3B'?: string;
    'swir 4'?: string;
    'swir 5'?: string;
    'swir 6'?: string;
    'swir 7'?: string;
    'swir 8'?: string;
    'swir 9'?: string;
    'tir 10'?: string;
    'tir 11'?: string;
    'tir 12'?: string;
    'tir 13'?: string;
    'tir 14'?: string;
  };
}

export interface IExpressionDetails {
  id?: string;
  algebraType: BandAlgebraTypeLayerOption;
  expressionName?: EXPRESSION_NAMES;
  uiName: string;
  recommendedColormap: COLORMAP;
  expression: string;
  thumbnail?: string;
  min?: number;
  max?: number;
  description?: string;
}

/**
 * New presets should be added with band names wrapped around %
 * @example %BAND_NAME%
 */
const EXPRESSION_PRESETS = {
  NDVI: `(%nir% - %red%) / (%nir% + %red%)`,
  GNDVI: `(%nir% - %green%) /(%nir% + %green%)`,
  AVI: `(%nir% * (1 - %red%) * (%nir% - %red%)) ** (1/3)`,
  SAVI: `((%nir% - %red%) / (%nir% + %red% + 0.5)) * (1.5)`,
  ARVI: `(%nir% - (2 * %red%) + %blue%) / (%nir% + (2 * %red%) + %blue%)`,
  NDMI: `(%nir% - %swir22%) / (%nir% + %swir22%)`,
  MSI: `%swir16% / %nir%`,
  GCI: `(%nir%) / (%green%) - 1`,
  NBR: `(%nir% - %swir22%) / (%nir% + %swir22%)`,
  BSI: `((%red%+%swir22%) - (%nir% + %blue%)) / ((%red% + %swir22%) + (%nir% + %blue%))`,
  NDWI_GAO: `(%nir% - %swir22%) / (%nir% + %swir22%)`,
  NDWI_MCFEETERS: `(%green% - %nir%) / (%green% + %nir%)`,
  NDSI: `(%green% - %swir22%) / (%green% + %swir22%)`,
  NDGI: `(%nir% - %green%) / (%nir% + %green%)`,
  SIPI: `(%nir% - %blue%) / (%nir% - %red%)`,
  SR: `%red% / %nir%`,
  NPCRI: `(%red% - %blue%) / (%red% + %blue%)`,
  EVI: `((%nir% - %red%) / ((%nir% + (%red% * 6) - (7.5 * %blue%)) + 1)) * (2.5)`,
  FAI: `%nir% - (%red% + (%swir16% - %red%) * 0.1873)`,
};

/**
 * Expression details containing displayed name and name value
 */
export const expressionDetails: IExpressionDetails[] = [
  {
    id: 'ndvi',
    expressionName: EXPRESSION_NAMES.NDVI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndvi.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.NDVI.replace(/%/g, ''),
    thumbnail: ndvi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndvi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'gndvi',
    expressionName: EXPRESSION_NAMES.GNDVI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.gndvi.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.GNDVI.replace(/%/g, ''),
    thumbnail: gnvdi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.gndvi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'evi',
    expressionName: EXPRESSION_NAMES.EVI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.evi.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.EVI.replace(/%/g, ''),
    thumbnail: evi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.evi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'avi',
    expressionName: EXPRESSION_NAMES.AVI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.avi.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.AVI.replace(/%/g, ''),
    thumbnail: avi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.avi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'savi',
    expressionName: EXPRESSION_NAMES.SAVI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.savi.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.SAVI.replace(/%/g, ''),
    thumbnail: savi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.savi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'arvi',
    expressionName: EXPRESSION_NAMES.ARVI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.arvi.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.ARVI.replace(/%/g, ''),
    thumbnail: arvi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.arvi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'ndmi',
    expressionName: EXPRESSION_NAMES.NDMI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndmi.title'
    ),
    recommendedColormap: COLORMAP.RDYLBU,
    expression: EXPRESSION_PRESETS.NDMI.replace(/%/g, ''),
    thumbnail: ndmi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndmi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'msi',
    expressionName: EXPRESSION_NAMES.MSI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.msi.title'
    ),
    recommendedColormap: COLORMAP.RDYLBU,
    expression: EXPRESSION_PRESETS.MSI.replace(/%/g, ''),
    thumbnail: msi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.msi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'gci',
    expressionName: EXPRESSION_NAMES.GCI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.gci.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.GCI.replace(/%/g, ''),
    thumbnail: gci_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.gci.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'nbr',
    expressionName: EXPRESSION_NAMES.NBR,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.nbr.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.NBR.replace(/%/g, ''),
    thumbnail: nbr_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.nbr.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'bsi',
    expressionName: EXPRESSION_NAMES.BSI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.bsi.title'
    ),
    recommendedColormap: COLORMAP.SEISMIC,
    expression: EXPRESSION_PRESETS.BSI.replace(/%/g, ''),
    thumbnail: bsi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.bsi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'ndwi_gao',
    expressionName: EXPRESSION_NAMES.NDWI_GAO,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndwi_gao.title'
    ),
    recommendedColormap: COLORMAP.RDYLBU,
    expression: EXPRESSION_PRESETS.NDWI_GAO.replace(/%/g, ''),
    thumbnail: ndwi_gao_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndwi_gao.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'ndwi_mcfeeters',
    expressionName: EXPRESSION_NAMES.NDWI_MCFEETERS,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndwi_mcfeeters.title'
    ),
    recommendedColormap: COLORMAP.RDYLBU,
    expression: EXPRESSION_PRESETS.NDWI_MCFEETERS.replace(/%/g, ''),
    thumbnail: ndwi_mcfeeters_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndwi_mcfeeters.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'ndsi',
    expressionName: EXPRESSION_NAMES.NDSI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndsi.title'
    ),
    recommendedColormap: COLORMAP.BONE,
    expression: EXPRESSION_PRESETS.NDSI.replace(/%/g, ''),
    thumbnail: ndsi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndsi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'ndgi',
    expressionName: EXPRESSION_NAMES.NDGI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndgi.title'
    ),
    recommendedColormap: COLORMAP.BONE,
    expression: EXPRESSION_PRESETS.NDGI.replace(/%/g, ''),
    thumbnail: ndgi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.ndgi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'sipi',
    expressionName: EXPRESSION_NAMES.SIPI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.sipi.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.SIPI.replace(/%/g, ''),
    thumbnail: sipi_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.sipi.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'sr',
    expressionName: EXPRESSION_NAMES.SR,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.sr.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.SR.replace(/%/g, ''),
    thumbnail: sr_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.sr.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'npcri',
    expressionName: EXPRESSION_NAMES.NPCRI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.npcri.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.NPCRI.replace(/%/g, ''),
    thumbnail: npcri_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.npcri.description'
    ),
    algebraType: 'Expression',
  },
  {
    id: 'fai',
    expressionName: EXPRESSION_NAMES.FAI,
    uiName: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.fai.title'
    ),
    recommendedColormap: COLORMAP.RDYLGN,
    expression: EXPRESSION_PRESETS.FAI.replace(/%/g, ''),
    thumbnail: fai_thumbnail,
    description: clientTranslate(
      'datacosmos.layers.bandAlgebra.predefined.index.fai.description'
    ),
    algebraType: 'Expression',
  },
];

/**
 * List of common satellites for both opencosmos and conida with their bands
 * Add new common ones here
 */
const COMMON_SATELLITES_WITH_BANDS: ISatelliteWithBands = {
  'sentinel-2': {
    coastal: 'B01',
    blue: 'B02',
    green: 'B03',
    red: 'B04',
    rededge1: 'B05',
    rededge2: 'B06',
    rededge3: 'B07',
    nir: 'B08',
    nir08: 'B8A',
    nir09: 'B09',
    swir16: 'B11',
    swir22: 'B12',
  },
  'landsat-8': {
    red: 'red',
    green: 'green',
    blue: 'blue',
    cirrus: 'cirrus',
    coastal: 'coastal',
    lwir11: 'lwir11',
    lwir12: 'lwir12',
    nir08: 'nir08',
    pan: 'pan',
    swir16: 'swir16',
    swir22: 'swir22',
  },
};

/**
 * List of conida satellites with their bands
 * Add new conida ones here
 */
const CONIDA_SATELLITES_WITH_BANDS: ISatelliteWithBands = {
  ...COMMON_SATELLITES_WITH_BANDS,
  'sentinel-2': {
    coastal: 'B1',
    blue: 'B2',
    green: 'B3',
    red: 'B4',
    rededge1: 'B5',
    rededge2: 'B6',
    rededge3: 'B7',
    nir: 'B8',
    nir08: 'B8A',
    nir09: 'B9',
    cirrus: 'B10',
    swir16: 'B11',
    swir22: 'B12',
  },
  'landsat-8': {
    coastal: 'B1',
    blue: 'B2',
    green: 'B3',
    red: 'B4',
    nir08: 'B5',
    swir16: 'B6',
    swir22: 'B7',
    pan: 'B8',
    cirrus: 'B9',
    lwir11: 'B10',
    lwir12: 'B11',
  },
  terra: {
    green: 'VNIR_Band1',
    red: 'VNIR_Band2',
    'nir 3N': 'VNIR_Band3N',
    'nir 3B': 'VNIR_Band3B',
    'swir 4': 'SWIR_Band4',
    'swir 5': 'SWIR_Band5',
    'swir 6': 'SWIR_Band6',
    'swir 7': 'SWIR_Band7',
    'swir 8': 'SWIR_Band8',
    'swir 9': 'SWIR_Band9',
    'tir 10': 'TIR_Band10',
    'tir 11': 'TIR_Band11',
    'tir 12': 'TIR_Band12',
    'tir 13': 'TIR_Band13',
    'tir 14': 'TIR_Band14',
  },
  'cbers-2b': {
    blue: 'band1',
    green: 'band2',
    red: 'band3',
    nir: 'band4',
    pan: 'band5',
  },
  'cbers-4a': {
    pan: 'band0',
    blue: 'band1',
    green: 'band2',
    red: 'band3',
    nir: 'band4',
  },
  'kompsat-3': {
    pan: 'pan',
    blue: 'ms1',
    green: 'ms2',
    red: 'ms3',
    nir: 'ms4',
  },
  pleiades: {
    blue: 'B0',
    green: 'B1',
    red: 'B2',
    nir: 'B3',
  },
  spot: {
    pan: 'P',
    blue: 'B0',
    green: 'B1',
    red: 'B2',
    nir: 'B3',
  },
  'kazeosat-1': {
    pan: 'P',
    blue: 'B0',
    green: 'B1',
    red: 'B2',
    nir: 'B3',
  },
  'perusat-1': {
    pan: 'PAN',
    blue: 'B0',
    green: 'B1',
    red: 'B2',
    nir: 'B3',
  },
  noaa14: {
    red: '1',
    blue: '2',
    green: '3',
  },
  noaa16: {
    red: '1',
    blue: '2',
    green: '3b',
  },
  noaa19: {
    red: '1',
    blue: '2',
    green: '3b',
  },
  gaofen: {
    red: 'B1',
    green: 'B2',
    blue: 'B3',
    nir: 'B4',
    pan: 'pan',
  },
};

/**
 * List of OC satellites with their bands
 * Add new OC ones here
 */
const OC_SATELLITES_WITH_BANDS: ISatelliteWithBands = {
  ...COMMON_SATELLITES_WITH_BANDS,
  menut: {
    red: 'R',
    green: 'G',
    blue: 'B',
    nir: 'NIR',
    rededge1: 'RE1',
    rededge2: 'RE2',
    rededge3: 'RE3',
  },
  platero: {
    red: 'R',
    green: 'G',
    blue: 'B',
    nir: 'NIR',
    rededge1: 'RE1',
    rededge2: 'RE2',
    rededge3: 'RE3',
  },
  'geosat-2': {
    nir: 'NIR',
    red: 'R',
    green: 'G',
    blue: 'B',
  },
  mantis: {
    red: 'R',
    green: 'G',
    blue: 'B',
    nir: 'NIR',
  },
};

const SATELLITES_WITH_BANDS: ISatelliteWithBands = CONIDA_SATELLITES_WITH_BANDS;

const getSatelliteNameByPlatform = (platform: string | undefined) => {
  return Object.keys(SATELLITES_WITH_BANDS).find((withBand) =>
    platform?.startsWith(withBand)
  ) as SATELLITE_NAMES;
};

const getBands = (expression: EXPRESSION_NAMES, satellite: SATELLITE_NAMES) => {
  const satelliteBands = Object.keys(SATELLITES_WITH_BANDS[satellite]);
  const expressionBands = [
    ...new Set(
      EXPRESSION_PRESETS[expression]
        ?.match(/%[a-zA-Z0-9]*%/g)
        ?.map((band) => band.replace(/%/g, ''))
    ),
  ];
  return {
    satelliteBands,
    expressionBands,
  };
};

const getSatBandsInExpression = (
  satelliteBands: string[],
  expressionBands: string[]
) => {
  return satelliteBands.filter((band) =>
    expressionBands.some((expBand) => band === expBand)
  );
};

const getIsEveryExpressionBandSupported = (
  satelliteBands: string[],
  expressionBands: string[]
) => {
  return expressionBands.every((expBand) => satelliteBands.includes(expBand));
};

const formatBandsAsNameValueObject = (
  bands: string[],
  satellite: SATELLITE_NAMES
) => {
  return bands.map((band) => {
    return {
      name: band,
      value:
        SATELLITES_WITH_BANDS[satellite][
          band as keyof (typeof SATELLITES_WITH_BANDS)[typeof satellite]
        ],
    };
  });
};

const findSatelliteBandsInExpression = (
  platform: string,
  expression: EXPRESSION_NAMES
) => {
  const satellite = getSatelliteNameByPlatform(platform);
  if (!satellite) return null;

  const { satelliteBands, expressionBands } = getBands(expression, satellite);
  const foundBands = getSatBandsInExpression(satelliteBands, expressionBands);

  return {
    satBandsInExpression: formatBandsAsNameValueObject(foundBands, satellite),
    isEveryExpressionBandSupported: getIsEveryExpressionBandSupported(
      satelliteBands,
      expressionBands
    ),
  };
};

/**
 * @param platform platform name obtained from the current stac item
 * @returns true if supports expressions, false otherwise
 */
export const supportsExpressions = (platform: string | undefined) => {
  if (!platform) return false;

  const satellite = getSatelliteNameByPlatform(platform);

  return Boolean(satellite);
};

/**
 *
 * @param platform platform name obtained from the current stac item
 * @param expression name of the expression to check
 * @returns true if supports specified expression, false otherwise
 */
export const supportsSpecifiedExpression = (
  platform: string | undefined,
  expression: EXPRESSION_NAMES | undefined
) => {
  if (!expression) {
    return false;
  }

  if (!platform) {
    return false;
  }

  if (!supportsExpressions(platform)) {
    return false;
  }

  const bandsInExpression = findSatelliteBandsInExpression(
    platform,
    expression
  );

  if (!bandsInExpression) {
    return false;
  }

  return bandsInExpression.isEveryExpressionBandSupported;
};

/**
 * Formats a specified expression with band values for the specified satellite
 * @param platform platform name obtained from the current stac item
 * @param expression name of the expression to format
 * @returns expression formatted with satellite specific band values
 */
export const formatExpressionForSatellite = (
  platform: string | undefined,
  expression: EXPRESSION_NAMES | undefined
) => {
  if (!expression) {
    return null;
  }

  if (!platform) {
    return null;
  }

  if (!supportsExpressions(platform)) {
    return null;
  }

  const bandsInExpression = findSatelliteBandsInExpression(
    platform,
    expression
  );

  if (!bandsInExpression) {
    return null;
  }

  const { satBandsInExpression } = bandsInExpression;

  const formattedExpression = satBandsInExpression.reduce(
    (band, previous, i, arr) => {
      const regex = new RegExp(`%${arr[i].name}%`, 'g');

      const exp = band.replace(regex, arr[i].value ?? '');

      return exp;
    },
    EXPRESSION_PRESETS[expression]
  );

  return formattedExpression;
};

/**
 *
 * @param expression expression for which to get recommended colormap
 * @returns recommended colormap for specified expression
 */
export const getColormapForExpression = (expression: EXPRESSION_NAMES) => {
  return expressionDetails?.find((exp) => exp.expressionName === expression)
    ?.recommendedColormap;
};

const getMatchingSatelliteBands = (
  bandNames: string[],
  sat: SATELLITE_NAMES
) => {
  return bandNames.map((band) =>
    Object.entries(SATELLITES_WITH_BANDS[sat]).find(([generic, specific]) => {
      return band === generic || band === specific;
    })
  );
};

export const genericToSatelliteSpecificExpression = (
  expressionString: string,
  platform: string | undefined
) => {
  const bandNames = expressionString.replace(/[()\s]/g, '').split(/[,+*/-]/g);

  const sat = platform && getSatelliteNameByPlatform(platform);

  let matchedBands: ([string, string] | undefined)[] = bandNames
    .filter(isItemSpecificBandName)
    .map((name) => [name, name]);
  if (matchedBands.length === 0) {
    matchedBands = sat ? getMatchingSatelliteBands(bandNames, sat) : [];
  }

  if (matchedBands.some((b) => !b)) {
    return null;
  }

  const formattedBands = matchedBands.map((mb) => {
    const generic = mb?.[0];
    const specific = mb?.[1];

    if (isItemSpecificBandName(mb?.[0] ?? '')) {
      return { generic, specific: specific?.slice(1) };
    }

    return {
      generic: generic?.toLowerCase(),
      specific,
    };
  });

  return formattedBands.reduce((exp, bandPair) => {
    return exp.replace(bandPair?.generic ?? '', bandPair?.specific ?? '');
  }, expressionString);
};

export const getItemSpecificBandName = (assetKey: string) => `$${assetKey}`;

export const isItemSpecificBandName = (bandName: string) =>
  bandName.startsWith('$');

export const formatExpressionForCard = (expression: string) => {
  return expression.replace('$', '');
};
