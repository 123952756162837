import type { ICOGSingleBandMetadataStatistics } from 'datacosmos/services/tilingApi';
import { DetailsItem } from 'pages/shared/components/Item';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Props = {
  statistics: ICOGSingleBandMetadataStatistics;
  className?: string;
};

const StatisticsSummary = ({ statistics, className }: Props) => {
  const { translate } = useLocalisation();
  return (
    <div className={className}>
      <h3 className="text-data-dark border-b dark:text-data-text ml-1 font-semibold">
        {translate('datacosmos.catalogAndItems.metadata.summaryStatistics')}
      </h3>

      <DetailsItem
        title={translate('datacosmos.catalogAndItems.metadata.minPixelValue')}
        value={String(statistics.min) ?? 'N/A'}
      />
      <DetailsItem
        title={translate('datacosmos.catalogAndItems.metadata.maxPixelValue')}
        value={String(statistics.max) ?? 'N/A'}
      />
      <DetailsItem
        title={translate('datacosmos.catalogAndItems.metadata.meanValue')}
        value={statistics.mean?.toFixed(2) ?? 'N/A'}
      />
      <DetailsItem
        title={translate('datacosmos.catalogAndItems.metadata.stdValue')}
        value={statistics.std?.toFixed(2) ?? 'N/A'}
      />
      <DetailsItem
        title={translate('datacosmos.catalogAndItems.metadata.variance')}
        value={(statistics.std * statistics.std)?.toFixed(2) ?? 'N/A'}
      />
      <DetailsItem
        title={translate('datacosmos.catalogAndItems.metadata.percentile25')}
        value={String(statistics.percentile_25) ?? 'N/A'}
      />
      <DetailsItem
        title={translate('datacosmos.catalogAndItems.metadata.percentile75')}
        value={String(statistics.percentile_75) ?? 'N/A'}
      />
    </div>
  );
};

export default StatisticsSummary;
