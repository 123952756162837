import type {
  IRole,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';

import {
  getUserPicture,
  getTextForNonStandardUser,
} from '../AdministrationUsers/utils';
import { ENABLE_USER_IMAGES } from 'env';
import { Icon, Button } from 'opencosmos-ui';
import UserApprovalDetails from './UserApprovalDetails';
import { UserWithPermissions } from '_api/users/types';

interface IProps {
  user?: IUserWithPermissions;
  availableRoles?: IRole[];
  onDetailsOpen: () => void;
  isDetailsOpen: boolean;
  onApprove: (user: IUserWithPermissions) => void;
  onReject: (user: IUserWithPermissions) => void;
  isApproving?: boolean;
  isRejecting?: boolean;
  pendingUserId?: string | null;
}

const UserApprovalItem = (props: IProps) => {
  const { user } = props;

  const isFetching = false;
  const userPicture = ENABLE_USER_IMAGES ? (
    getUserPicture(isFetching, user?.picture ?? '', user?.picture)
  ) : (
    <Icon icon="person" />
  );

  const userName = user?.name
    ? user.name
    : getTextForNonStandardUser(isFetching, user?.email ?? '');

  return props.user ? (
    <div className="flex flex-col">
      <li className="flex justify-between mb-2">
        <div
          className="flex grow items-center min-w-[300px]"
          onClick={() => props.onDetailsOpen()}
          style={{ cursor: 'pointer' }}
        >
          <Icon icon={props.isDetailsOpen ? 'chevron-down' : 'chevron-right'} />
          {userPicture}
          <span className="text-sm ml-1">{userName}</span>
        </div>
        <div className="flex gap-1">
          <Button
            className="px-2 w-28"
            icon="tick"
            iconClassName="text-green-500"
            onPress={() => user && props.onApprove(user)}
            loading={props.isApproving && props.pendingUserId === user?.id}
            isDisabled={props.isApproving || props.isRejecting}
          >
            Aprobar
          </Button>
          <Button
            className="px-2 w-28"
            intent="warning"
            icon="cross"
            onPress={() => user && props.onReject(user)}
            loading={props.isRejecting && props.pendingUserId === user?.id}
            isDisabled={props.isApproving || props.isRejecting}
          >
            Rechazar
          </Button>
        </div>
      </li>
      {props.isDetailsOpen && user !== undefined ? (
        <div className="pb-4 px-4 border border-neutral-500 border-t-0 ml-2 mb-4">
          <UserApprovalDetails user={user as UserWithPermissions} />
        </div>
      ) : null}
    </div>
  ) : null;
};

export default UserApprovalItem;
