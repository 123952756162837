import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type MutableRefObject,
} from 'react';
import 'leaflet';
import 'leaflet-draw/dist/leaflet.draw';
import { zIndexValues } from 'opencosmos-ui/constants';
import BottomBarScale from './BottomBar/BottomBarScale';
import type { LatLngBoundsExpression, LatLngExpression, Map } from 'leaflet';
import config from 'datacosmos/config';
import L from 'leaflet';
import MapLayers from 'datacosmos/utils/map-layers';
import { stringToMapView, newGeoJSONLayer } from 'datacosmos/stores/mapHelpers';

export const FullPageMap = () => {
  const [scale, setScale] = useState<{
    kmValue: number;
    scalePxWidth: number;
  }>();

  const mapRef = useRef<Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const searchParams = new URLSearchParams(location.search);
  const bbox = searchParams.get('bbox');
  const showScale = searchParams.get('scale');
  const geojson = searchParams.get('vector');

  const mapBaseLayer = useRef(
    L.tileLayer(MapLayers[0].url, { maxZoom: config.map.maxZoom })
  );

  const initialiseMap = useCallback(
    (containerRef: MutableRefObject<HTMLDivElement | null>) => {
      //Remove previous map
      mapRef.current?.remove();

      const mapContainer = containerRef;

      if (!mapContainer.current) return undefined;
      mapRef.current = (window.L as typeof L).map(mapContainer.current, {
        zoomControl: false,
        minZoom: config.map.minZoom,
        maxZoom: config.map.maxZoom,
        zoomSnap: 0,
        maxBounds: L.latLngBounds([-90, -210], [90, 210]),
        attributionControl: false,
      });

      new ResizeObserver(() => {
        mapRef.current?.invalidateSize();
      }).observe(mapContainer.current);

      mapRef.current.addLayer(mapBaseLayer.current);

      const initialView = config.map.initialView
        .concat(config.map.initialZoom)
        .join(',');
      const mapString = stringToMapView(initialView);
      const location: LatLngExpression = [
        parseFloat(mapString.lat),
        parseFloat(mapString.lng),
      ];
      const zoom = parseInt(mapString.zoom);
      mapRef.current.setView(location, zoom);

      mapRef.current.on('moveend', () => {
        if (!mapRef.current) return;

        const getRoundNum = (num: number) => {
          const pow10 = Math.pow(10, String(Math.floor(num)).length - 1);
          let d = num / pow10;
          // eslint-disable-next-line no-nested-ternary
          d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;
          return pow10 * d;
        };

        const y = mapRef.current.getSize().y / 2;
        const maxMeters = mapRef.current.distance(
          mapRef.current.containerPointToLatLng([0, y]),
          mapRef.current.containerPointToLatLng([100, y])
        );

        setScale({
          kmValue: getRoundNum(maxMeters) / 1000,
          scalePxWidth: Math.round((100 * getRoundNum(maxMeters)) / maxMeters),
        });
      });

      mapRef.current.on('zoomend', () => {
        if (!mapRef.current) return;
        const layerTooltipPairs: { layer: Element; tooltip: Element }[] = [];
        mapRef.current.eachLayer((mapLayer) => {
          if (!mapLayer.getTooltip()) return;
          document
            .querySelectorAll('.leaflet-tooltip')
            .forEach((tooltip, i) => {
              layerTooltipPairs[i] = { ...layerTooltipPairs[i], tooltip };
            });
          document
            .querySelectorAll('.leaflet-interactive')
            .forEach((layer, i) => {
              layerTooltipPairs[i] = { ...layerTooltipPairs[i], layer };
            });
          // Last element is always minimap, which shouldn't be considered and is therefore removed
          layerTooltipPairs.pop();
        });
      });

      return mapRef.current;
    },
    []
  );

  useEffect(() => {
    initialiseMap(mapContainerRef);
  }, [initialiseMap]);

  const setViewToFitBbox = useCallback((bbox: number[]) => {
    if (
      !isFinite(bbox[0]) ||
      !isFinite(bbox[1]) ||
      !isFinite(bbox[2]) ||
      !isFinite(bbox[3])
    ) {
      return;
    }
    const bounds = [
      [bbox[1], bbox[0]],
      [bbox[3], bbox[2]],
    ] as LatLngBoundsExpression;
    mapRef.current?.fitBounds(bounds);
  }, []);

  useEffect(() => {
    if (!bbox) {
      return;
    }
    if (mapRef.current) {
      setViewToFitBbox(JSON.parse(bbox));
    }
  }, [mapRef.current, bbox]);

  useEffect(() => {
    if (!geojson) {
      return;
    }
    if (mapRef.current) {
      const layer = newGeoJSONLayer(JSON.parse(geojson));
      mapRef.current.addLayer(layer);
    }
  }, [mapRef.current, geojson]);

  return (
    <div
      ref={mapContainerRef}
      className="fixed top-0 left-0 right-0 bottom-0 full-page-map"
    >
      {showScale === 'true' && (
        <div
          className="absolute bottom-0 w-full"
          style={{ zIndex: zIndexValues.footer }}
        >
          <div className="flex justify-center my-2 w-full">
            <BottomBarScale map={scale} />
          </div>
        </div>
      )}
    </div>
  );
};
