import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { Route, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../../services/auth/AuthWrapper';
import NoPermissionsPage from '../../pages/shared/NoPermissionsPage';
import Loading from '../common/Loading';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';
import type { RouteType } from 'routes';

const PrivateRoute = ({
  component: Component,
  path,
  auth,
  permissionType,
  ...rest
}: RouteType) => {
  const { loading, user, checkPermissions } = useAuth();

  const [hasPermission, setHasPermission] = useState<boolean>(false);

  const [isGettingPermissionForRoute, setIsGettingPermissionForRoute] =
    useState<boolean>(true);

  const isAuthenticated = Boolean(user);

  const permission = permissionType;
  const location = useLocation();

  const idToCheckPermission =
    permission === 'organisation'
      ? location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
      : undefined;
  useEffect(() => {
    const getHasPermission = async () => {
      setIsGettingPermissionForRoute(true);
      let hasPerm = false;
      const arrPerm = await checkPermissions(
        auth.map((a) => ({
          id: idToCheckPermission,
          type: permission,
          actionScope: a,
        })) as ICheckPermissions[]
      );
      hasPerm = arrPerm.every((p) => p);

      setIsGettingPermissionForRoute(false);
      setHasPermission(hasPerm);
    };

    void getHasPermission();
  }, [auth, checkPermissions, permission]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const render = (props: any): ReactElement => {
    if (loading) {
      return <Loading isLoading={false} />;
    }
    if (!isAuthenticated && !isGettingPermissionForRoute) {
      return <Loading isLoading={false} />;
    }
    if (!hasPermission && !isGettingPermissionForRoute) {
      return <NoPermissionsPage />;
    }

    return <Component {...props} />;
  };

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
