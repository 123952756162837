import type { EmptyParams } from '_api/client';
import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import type { BBox } from 'geojson';
import { toaster } from 'toaster';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type PostGeoPDF = {
  metadata: {
    author: string;
  };
  pages: {
    settings: {
      dpi: string;
      width: string;
      height: string;
      has_logo: boolean;
      has_minimaps?: boolean;
    };
    content: {
      description: string;
      title: string;
      logos: string[];
      minimap: string;
      layers: {
        enabled: boolean;
        georeferencing?: {
          bbox: BBox;
        };
        raster?: string;
      }[];
    };
  }[];
};

export const getGeoPdfFile = apiFetchHandler<Blob, EmptyParams, PostGeoPDF>({
  host: hosts.data.v1,
  endpoint: '/geopdf/generate',
  method: 'POST',
  errorMessage: 'Cannot get GeoPDF file',
  errorDescription: (e) => e.join('; '),
});

export type PostFileParams = {
  projectId: string;
};

export type PostFileBody = FormData;

export type PostFileResponse = {
  detail: string;
  item_id: string;
  files: {
    fileName: string;
    content_type: string;
  }[];
};

export const uploadFileToProject = apiFetchHandler<
  PostFileResponse,
  PostFileParams,
  PostFileBody
>({
  host: hosts.data.v1,
  contentType: 'multipart/form-data',
  method: 'POST',
  endpoint: ({ projectId }) => `/geopdf/upload/${projectId}`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.geopdf.postFile.cannotPost'
  ),
  errorDescription: (e) => e.join('; '),
  onSuccess: (data) => {
    if (data?.files) {
      toaster.show({
        message: clientTranslate(
          'datacosmos.fetchSuccesses.postFile.successfullyUploaded'
        ),
        intent: 'success',
      });
    }
  },
});
