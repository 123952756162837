import AdministrationRoleSelect from '../../../../shared/components/AdministrationRoleSelect';
import type {
  IRole,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';

import { getUserPicture, getTextForNonStandardUser } from './utils';
import { useHistory } from 'react-router-dom';
import { ENABLE_USER_IMAGES } from 'env';
import { Tooltip, Icon } from 'opencosmos-ui';

interface IProps {
  user?: IUserWithPermissions;
  assignRole: (userId: string, roleIds: number[]) => void;
  deleteRoleAssignments: (
    userId: string,
    assignmentIds: number[],
    showDeletedMessage?: boolean
  ) => void;
  availableRoles: IRole[];
}

const UserListItem = (props: IProps) => {
  const { user } = props;
  const history = useHistory();

  const deleteRoleAssignments = (userId: string, assignmentIds: number[]) => {
    props.deleteRoleAssignments(userId, assignmentIds, true);
  };

  const isFetching = false;
  const userPopover = user ? user.email : '';
  const userPicture = ENABLE_USER_IMAGES ? (
    getUserPicture(isFetching, user?.picture ?? '', user?.picture)
  ) : (
    <Icon icon="person" />
  );

  const userName = user?.name
    ? user.name
    : getTextForNonStandardUser(isFetching, user?.email ?? '');

  const userHref = `/portal/users/${encodeURI(
    user?.id.replace(/\./g, ' ') ?? ''
  )}`;

  return props.user ? (
    <li className="flex justify-between mb-2">
      <Tooltip
        placement={'left'}
        content={userPopover}
        shouldFlip={false}
        offset={0}
      >
        <a
          className="flex items-center min-w-[300px] gap-2 hover:no-underline hover:text-accent"
          href={userHref}
          onClick={(e) => {
            if (e.button === 0) {
              //Left click
              e.preventDefault();
              history.push(userHref, user);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          {userPicture}
          <span className="text-sm ml-1">{userName}</span>
        </a>
      </Tooltip>
      <AdministrationRoleSelect
        userId={props.user.id}
        currentId={props.user.id}
        assignRole={props.assignRole}
        deleteRoleAssignments={deleteRoleAssignments}
        availableRoles={props.availableRoles}
        currentAssignments={props.user.assignments}
        disableRemoval
      />
    </li>
  ) : null;
};

export default UserListItem;
