import { useCallback, useState } from 'react';

import conidaLogo from 'public/images/datacosmos/conida-logo.png';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import { Button, Input, ListBoxItem, Select } from 'opencosmos-ui';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import {
  submitChangeDetectionAlgo,
  type ChangeDetectionType,
} from '_api/sampling/service';
import { useRouteMatch } from 'react-router';
import type { StacItem } from 'datacosmos/types/stac-types';

type Props = {
  app: IApplication;
};

export const ChangeDetectionApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate('datacosmos.applications.changeDetection.title'),
  description: clientTranslate(
    'datacosmos.applications.changeDetection.description'
  ),
  inputs: [
    {
      field: 'firstImage',
      example: '',
    },
    {
      field: 'secondImage',
      example: '',
    },
    {
      field: 'type',
      example: '',
    },
    {
      field: 'scale',
      example: '',
    },
  ],
  values: {
    firstImage: { value: null, isError: false, message: '' },
    secondImage: { value: null, isError: false, message: '' },
    type: { value: null, isError: false, message: '' },
    scale: { value: null, isError: false, message: '' },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.changeDetection.shortDescription'
  ),
  renderer: (app: IApplication) => <ChangeDetection app={app} />,
  appScreenshotUrl: '',
  tags: [],
  acronym: clientTranslate('datacosmos.applications.changeDetection.acronym', {
    defaultValue: 'CHD',
  }),
  permissionsResourceId: 'change_detection',
};

const ChangeDetection = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const [isSubmittingDetection, setIsSubmittingDetection] =
    useState<boolean>(false);

  const [cursor, setCursor] = useState<number>(0);

  const { translate } = useLocalisation();

  const setIdValues = (img: SingleBandSTACLayer) => {
    const key = cursor === 0 ? 'firstImage' : 'secondImage';
    setInput(key, img.item, false);
    setCursor(cursor === 0 ? 1 : 0);
    return;
  };

  useClickedStacItem({
    outlineClickedItem: true,
    onImageClick: (img) => {
      setIdValues(img);
    },
  });

  const setInput = useCallback(
    (
      key: string,
      value: IAPIAppValue['value'],
      isError: boolean,
      message?: string
    ) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError, message: message ?? '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const setValue = (key: string, value: IAPIAppValue['value']) => {
    setInput(key, value, false, '');
  };

  const resetInputValues = useCallback(() => {
    setInputData(app.name, {
      firstImage: { value: null, isError: false, message: '' },
      secondImage: { value: null, isError: false, message: '' },
      type: { value: null, isError: false, message: '' },
      scale: { value: null, isError: false, message: '' },
    });
  }, [app.name, setInputData]);

  const urlMatch = useRouteMatch<{ projectId: string }>({
    path: '/data/project/:projectId',
    exact: false,
  });

  const projectId = urlMatch?.params.projectId;

  const inputs = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div className="flex flex-col gap-1">
          <div className="flex justify-between">
            <span>
              {translate(
                'datacosmos.applications.changeDetection.inputs.fullResImg1.title'
              )}
            </span>
            {(app.values.firstImage.value as StacItem)?.id && (
              <Button
                icon="Pencil"
                iconOnly
                isMinimal
                isTransparent
                onPress={() => {
                  setCursor(0);
                  setInput('firstImage', null, false, '');
                }}
              />
            )}
          </div>
          <Input
            type="text"
            value={
              app.values.firstImage.value
                ? ((app.values.firstImage.value as StacItem)?.id as string)
                : ''
            }
            placeholder={translate(
              'datacosmos.applications.changeDetection.inputs.fullResImg1.placeholder'
            )}
            readOnly
            className={
              cursor === 0 && !app.values.firstImage.value
                ? 'bg-surface border-accent-800'
                : ''
            }
          />
        </div>

        <div className="flex flex-col gap-1">
          <div className="flex justify-between">
            <span>
              {translate(
                'datacosmos.applications.changeDetection.inputs.fullResImg2.title'
              )}
            </span>
            {(app.values.secondImage.value as StacItem)?.id && (
              <Button
                icon="Pencil"
                iconOnly
                isMinimal
                isTransparent
                onPress={() => {
                  setCursor(1);
                  setInput('secondImage', null, false, '');
                }}
              />
            )}
          </div>
          <Input
            type="text"
            value={
              app.values.secondImage.value
                ? ((app.values.secondImage.value as StacItem)?.id as string)
                : ''
            }
            readOnly
            placeholder={translate(
              'datacosmos.applications.changeDetection.inputs.fullResImg2.placeholder'
            )}
            className={
              cursor === 1 && !app.values.secondImage.value
                ? 'bg-surface border-accent-800'
                : ''
            }
          />
        </div>

        <div className="flex flex-col gap-1">
          <span>
            {translate(
              'datacosmos.applications.changeDetection.inputs.type.title'
            )}
          </span>
          <Select
            fill
            placeholder={translate(
              'datacosmos.applications.changeDetection.inputs.type.placeholder'
            )}
            onSelectionChange={(val) => {
              setValue('type', val);
            }}
            selectedKey={
              app.values.type.value ? (app.values.type.value as string) : ''
            }
          >
            <ListBoxItem id="vegetation" key="vegetation">
              {translate(
                'datacosmos.applications.changeDetection.inputs.type.vegetation'
              )}
            </ListBoxItem>
            <ListBoxItem id="water" key="water">
              {translate(
                'datacosmos.applications.changeDetection.inputs.type.water'
              )}
            </ListBoxItem>
            <ListBoxItem id="burn" key="burn">
              {translate(
                'datacosmos.applications.changeDetection.inputs.type.burn'
              )}
            </ListBoxItem>
          </Select>
          {app.values.type?.isError && (
            <div>
              <small className="text-warning">{app.values.type.message}</small>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-1">
          <span>
            {translate(
              'datacosmos.applications.changeDetection.inputs.scale.title'
            )}
          </span>
          <Input
            type="text"
            value={
              app.values.scale.value ? (app.values.scale.value as string) : ''
            }
            placeholder={translate(
              'datacosmos.applications.changeDetection.inputs.scale.placeholder'
            )}
            onChange={(e) => {
              setValue('scale', e.target.value);
            }}
          />
          {app.values.scale?.isError && (
            <div>
              <small className="text-warning">{app.values.scale.message}</small>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          if (!projectId) {
            return;
          }
          setIsSubmittingDetection(true);
          await submitChangeDetectionAlgo({
            body: {
              type: app.values.type?.value as ChangeDetectionType,
              scale: Number(app.values.scale?.value),
              target_item: (app.values.secondImage.value as StacItem)?.id,
              target_collection: (app.values.secondImage.value as StacItem)
                .collection!,
              project_id: projectId,
            },
            params: {
              item: (app.values.firstImage.value as StacItem)?.id,
              collection: (app.values.firstImage.value as StacItem).collection!,
            },
          });
          resetInputValues();
          setIsSubmittingDetection(false);
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.submit'
        )}
        loading={isSubmittingDetection}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      isInstalled={getInstalledStatus(app)}
      setIsAppOpened={setIsAppOpened}
      setSelectedInstalledApp={setSelectedInstalledApp}
      toggleAppInstall={toggleAppInstall}
    />
  );
};

export default ChangeDetection;
