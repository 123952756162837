import React from 'react';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useMutation } from '@tanstack/react-query';

import useAllUsers from '../../../services/api/portal/administration/hook/useAllUsers';
import AdministrationLayout from '../shared/components/AdministrationLayout/AdministrationLayout';

import s from './index.module.scss';
import UserApprovalList from './User/components/AdministrationUserApproval/UserApprovalList';
import { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import { PUBLIC_INTERMEDIATE_ROLE_ID } from 'env';
import { toKeycloakUser, updateUser } from '_api/users/service';
import { useAuth } from 'services/auth/AuthWrapper';
import { USER_UPGRADE_REQUEST_STATUS } from '_api/users/types';
import { toaster } from 'toaster';

const ATTRIBUTES_QUERY = `upgrade:${USER_UPGRADE_REQUEST_STATUS.PENDING}`;

const UserApproval = () => {
  const [pendingUserId, setPendingUserId] = React.useState<string | null>(null);
  const {
    users,
    availableRoles,
    assignRole,
    page,
    setPage,
    isFetching,
    getAssignmentsByUser,
  } = useAllUsers(ATTRIBUTES_QUERY);

  const previousPageHandler = () => setPage(page - 1);
  const nextPageHandler = () => setPage(page + 1);

  const { user: me } = useAuth();

  const { mutate: handleApprove, isPending: isApproving } = useMutation({
    mutationFn: async (user: IUserWithPermissions) => {
      setPendingUserId(user.id);
      const results = await Promise.allSettled([
        assignRole(user.id, [Number(PUBLIC_INTERMEDIATE_ROLE_ID)]),
        updateUser({
          params: { userId: user.id },
          body: {
            ...toKeycloakUser(user),
            attributes: {
              ...user.attributes,
              upgrade: USER_UPGRADE_REQUEST_STATUS.APPROVED,
              approved_by: me?.sub ?? me?.id,
              approved_at: new Date().toISOString(),
            },
          },
        }),
      ]);
      const hasError = results.some((result) => result.status === 'rejected');
      if (hasError) {
        toaster.show({
          message: 'Hubo un error aprobando el usuario',
          intent: 'danger',
        });
      }
      return results;
    },
    onSuccess: () => {
      toaster.show({
        message: 'El usuario ha sido aprobado',
        intent: 'success',
        timeout: 3000,
      });
    },
    onSettled: () => {
      setPendingUserId(null);
    },
  });

  const { mutate: handleReject, isPending: isRejecting } = useMutation({
    mutationFn: async (user: IUserWithPermissions) => {
      setPendingUserId(user.id);
      return updateUser({
        params: { userId: user.id },
        body: {
          ...toKeycloakUser(user),
          attributes: {
            ...user.attributes,
            upgrade: USER_UPGRADE_REQUEST_STATUS.REJECTED,
            approved_by: me?.sub ?? me?.id,
            approved_at: new Date().toISOString(),
          },
        },
      });
    },
    onError: () => {
      toaster.show({
        message: 'Hubo un error rechazando el usuario',
        intent: 'danger',
      });
    },
    onSuccess: () => {
      toaster.show({
        message: 'El usuario ha sido rechazado',
        intent: 'success',
        timeout: 3000,
      });
      getAssignmentsByUser();
    },
    onSettled: () => {
      setPendingUserId(null);
    },
  });

  return (
    <AdministrationLayout breadcrumbs={[{ text: 'Aprobación de usuarios' }]}>
      <UserApprovalList
        users={users}
        availableRoles={availableRoles}
        isFetching={isFetching}
        onApprove={handleApprove}
        onReject={handleReject}
        isApproving={isApproving}
        isRejecting={isRejecting}
        pendingUserId={pendingUserId}
      />
      <div className={s.paginator}>
        <Button
          icon={IconNames.CHEVRON_LEFT}
          onClick={previousPageHandler}
          disabled={page === 0}
        />
        <span className={s.paginationNumber}>Page {page + 1}</span>
        <Button icon={IconNames.CHEVRON_RIGHT} onClick={nextPageHandler} />
      </div>
    </AdministrationLayout>
  );
};

export default UserApproval;
