import { useMemo, useState } from 'react';
import UserListItem from './UserListItem';
import type {
  IRole,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { Button, Dialog, SearchInput, Tooltip } from 'opencosmos-ui';
import { debounce } from 'lodash';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { usersExportURL } from '_api/users/service';
import { downloadToFileName } from 'utils/common/CommonUtils';
import moment from 'moment';
import { ISO_FORMAT_NO_MILLISECONDS } from 'constants/datetime';
import UserApprovalItem from './UserApprovalItem';
import UserApprovalDetails from './UserApprovalDetails';

interface IProps {
  availableRoles: IRole[];
  users: IUserWithPermissions[] | undefined;
  isFetching: boolean;
  onApprove: (user: IUserWithPermissions) => void;
  onReject: (user: IUserWithPermissions) => void;
  isApproving?: boolean;
  isRejecting?: boolean;
  pendingUserId?: string | null;
}

const UserApprovalList = (props: IProps) => {
  const [expandedUser, setExpandedUser] = useState('');

  const { translate } = useLocalisation();

  return (
    <div className="">
      <div className="w-full flex flex-col mb-6">
        <div className="flex justify-between mb-2 gap-2">
          <div className="text-base grow">{translate('userPage.users')}</div>
        </div>
      </div>
      <ul>
        {props.isFetching ? (
          <Spinner />
        ) : (
          props.users?.map((user) => (
            <UserApprovalItem
              key={user.id}
              user={user}
              onDetailsOpen={() =>
                setExpandedUser((prev) => (prev === user.id ? '' : user.id))
              }
              isDetailsOpen={expandedUser === user.id}
              onApprove={() => props.onApprove(user)}
              onReject={() => props.onReject(user)}
              isApproving={props.isApproving}
              isRejecting={props.isRejecting}
              pendingUserId={props.pendingUserId}
            />
          ))
        )}
      </ul>
    </div>
  );
};

export default UserApprovalList;
