import axios from 'axios';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { REQUEST_ERROR } from 'constants/errors';
import type { PermissionType } from '_api/administration/permissions';
import type { IRole, IUpdateRole } from './types';

export default (url: string, token: string | undefined) => {
  const createRole = async (role: IUpdateRole): Promise<IRole | undefined> => {
    try {
      const {
        data: { data },
      } = (await axios.post(`${url}/roles`, role, {
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      })) as { data: { data: IRole } };

      return data;
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      return undefined;
    }
  };

  const updateRole = async (role: IUpdateRole): Promise<IRole | undefined> => {
    try {
      const {
        data: { data },
      } = (await axios.put(`${url}/roles/${role.id}`, role, {
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      })) as { data: { data: IRole } };

      return data;
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      return undefined;
    }
  };

  /**
   * Uses the API to obtain a list of roles. When no resource type is provided
   * returns a list of all roles.
   *
   * @param resourceType
   * @returns
   */
  const getRoles = async (
    isAdmin: boolean,
    resourceType?: PermissionType
  ): Promise<IRole[]> => {
    try {
      const {
        data: { data },
      } = (await axios.get(`${url}/roles`, {
        params: {
          all: isAdmin,
          resource: resourceType,
        },
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      })) as { data: { data: IRole[] } };

      return data;
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      return [];
    }
  };

  const getResourceRoles = async (
    resourceType: PermissionType
  ): Promise<IRole[]> => {
    try {
      const {
        data: { data },
      } = (await axios.get(`${url}/roles`, {
        params: {
          resource: resourceType,
        },
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      })) as { data: { data: IRole[] } };

      return data;
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      return [];
    }
  };
  return {
    getResourceRoles,
    getRoles,
    createRole,
    updateRole,
  };
};
