import { useHistory } from 'react-router';
import { useAuth } from 'services/auth/AuthWrapper';
import { getHost } from 'utils/common/CommonUtils';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import { OAUTH2_LOGOUT_URI, ENABLE_DARK_MODE, ENABLE_USER_IMAGES } from 'env';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useQuery } from '_api/useQuery';
import { getRoleAssignments } from '_api/administration/service';
import { findHighestPriorityRole } from 'utils/auth/common';
import {
  Icon,
  MenuItem,
  Menu,
  Popover,
  SubmenuTrigger,
  Text,
  MenuTrigger,
  Button,
} from 'opencosmos-ui';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import { useMemo } from 'react';

interface UserProfileMenuProps {
  hideThemesInUserMenu?: boolean;
  showOnlyLogout?: boolean;
  organisationId?: number;
}
export const UserProfileMenu = ({
  hideThemesInUserMenu,
  showOnlyLogout,
  organisationId,
}: UserProfileMenuProps) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    isAllowedToReadRoles,
    isAllowedToReadUsersRoles,
    isAllowedToReadOrders,
    userOrganisations,
    setUserRole,
  } = useAuth();

  const { translate } = useLocalisation();
  const { sendInfo } = useAnalytics();

  let buttonContent = <Icon icon={'User'} size={32} />;
  if (isAuthenticated && user) {
    buttonContent = ENABLE_USER_IMAGES ? (
      <img
        src={user.picture}
        className="h-[45px] w-[45px] rounded-full"
        alt="avatar"
      />
    ) : (
      buttonContent
    );
  }

  if (showOnlyLogout) {
    return (
      <MenuTrigger>
        <Button className={'rounded-full py-5'}>{buttonContent}</Button>
        <Popover placement="bottom left" className="w-max">
          <Menu
            onAction={() => {
              sendInfo({
                type: 'Logout',
                action: 'Click',
                item: 'Logout button',
                module: 'DataCosmos',
              });
              logout(getHost() + OAUTH2_LOGOUT_URI);
            }}
          >
            <MenuItem id={'logout'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'ChevronRightDouble'} />
                <span>{translate('datacosmos.header.menu.logout')}</span>
              </div>
            </MenuItem>
          </Menu>
        </Popover>
      </MenuTrigger>
    );
  }

  const getAssignmentsQuery = useQuery(getRoleAssignments, {
    initialData: [],
    params: user ? { userId: user.sub, resourceType: 'global' } : undefined,
    skip: !user?.sub,
  });

  const userRole = useMemo(() => {
    if (!getAssignmentsQuery?.data?.length) {
      return;
    }
    const mappedRole = findHighestPriorityRole(getAssignmentsQuery?.data);
    if (mappedRole) {
      setUserRole(mappedRole);
    }
    return mappedRole;
  }, [getAssignmentsQuery?.data]);
  const { setTheme, theme } = useTheme();

  const history = useHistory();

  const handleRolesLinkClick = () => {
    history.push('/portal/roles');
  };

  const handleUsersLinkClick = () => {
    history.push('/portal/users');
  };

  const handleOrdersLinkClick = () => {
    history.push('/data/orders');
  };

  const handleViewsLinkClick = () => {
    history.push('/data/views/');
  };

  const handleOrganisationLinkClick = () => {
    history.push(`/portal/organisations`);
  };

  const { hasPermission: isAllowedToAccessMatomo } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'admin:matomo',
    },
  });

  const { hasPermission: isAllowedToAccessGrafana } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'admin:grafana',
    },
  });

  const { hasPermission: isAllowedToAccessKeyCloak } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'admin:keycloak',
    },
  });

  const organisation = useMemo(() => {
    let name = translate('datacosmos.header.menu.organisations') as string;
    if (userOrganisations.length === 1) {
      name = userOrganisations[0].name;
    }
    if (userOrganisations.length > 1) {
      const org = userOrganisations.find((org) => org.id === organisationId);
      name = org?.name ?? name;
    }
    return name;
  }, [userOrganisations, organisationId]);

  let content = (
    <Menu
      onAction={(key) => {
        if (key === 'login') {
          sendInfo({
            type: 'Login',
            action: 'Click',
            item: 'Login button',
            module: 'DataCosmos',
          });
          void loginWithRedirect();
        }
      }}
    >
      <MenuItem>Anonym</MenuItem>
      <MenuItem id={'login'}>
        <span>Login</span>
      </MenuItem>
    </Menu>
  );

  if (isAuthenticated) {
    if (user) {
      content = (
        <Menu
          onAction={(key) => {
            switch (key) {
              case 'orders':
                handleOrdersLinkClick();
                break;
              case 'roles':
                handleRolesLinkClick();
                break;
              case 'users':
                handleUsersLinkClick();
                break;

              case 'organisation':
                handleOrganisationLinkClick();
                break;
              case 'views':
                handleViewsLinkClick();
                break;
              case 'logout':
                sendInfo({
                  type: 'Logout',
                  action: 'Click',
                  item: 'Logout button',
                  module: 'DataCosmos',
                });
                logout(getHost() + OAUTH2_LOGOUT_URI);
                break;
              default:
                break;
            }
          }}
        >
          <MenuItem
            id={'user'}
            href={`/realms/condatacosmos/account/`}
            target="_blank"
          >
            <div className="flex items-center gap-2" onClick={() => {}}>
              <Icon icon={'User'} />
              <div>
                <span>{user.name}</span>
                <div className="text-sm text-item-contrast-inactive dark:text-item-dark-contrast-inactive flex gap-1 items-center">
                  {userRole}
                </div>
              </div>
            </div>
          </MenuItem>

          <MenuItem id={'organisation'}>
            <div className="flex items-center gap-2" onClick={() => {}}>
              <Icon icon={'office'} className="!stroke-none" />
              <span>{organisation}</span>
            </div>
          </MenuItem>

          {isAllowedToReadOrders && (
            <MenuItem id={'orders'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'ShoppingCart'} />
                <span>{translate('datacosmos.header.menu.orders')}</span>
              </div>
            </MenuItem>
          )}

          <MenuItem id={'views'}>
            <div className="flex items-center gap-2" onClick={() => {}}>
              <Icon icon={'SatelliteTasking'} />
              <span>{translate('datacosmos.header.menu.views')}</span>
            </div>
          </MenuItem>

          {ENABLE_DARK_MODE && !hideThemesInUserMenu ? (
            <SubmenuTrigger>
              <MenuItem id="share">
                <Icon icon="ChevronLeft" />
                <Text slot="label">
                  {translate('datacosmos.header.menu.theme.title')}
                </Text>
              </MenuItem>
              <Popover offset={0}>
                <Menu
                  aria-label={translate('datacosmos.header.menu.theme.title')}
                  selectionMode="single"
                  onSelectionChange={(values) => {
                    if (values === 'all') {
                      throw new Error('Unexpected selection of all items.');
                    }

                    setTheme(
                      values.keys().next().value as 'DARK' | 'LIGHT' | 'AUTO'
                    );
                  }}
                  selectedKeys={[theme]}
                >
                  <MenuItem id="DARK">
                    {translate('datacosmos.header.menu.theme.dark')}
                  </MenuItem>
                  <MenuItem id="LIGHT">
                    {translate('datacosmos.header.menu.theme.light')}
                  </MenuItem>
                  <MenuItem id="AUTO">
                    {translate('datacosmos.header.menu.theme.auto')}
                  </MenuItem>
                </Menu>
              </Popover>
            </SubmenuTrigger>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToReadRoles ? (
            <MenuItem id={'roles'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'Star'} />
                <span>{translate('datacosmos.header.menu.rolesManager')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToReadUsersRoles ? (
            <MenuItem id={'users'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'UserGroup'} />
                <span>{translate('datacosmos.header.menu.users')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToAccessGrafana ? (
            <MenuItem
              id={'grafana'}
              href={`/monitoring/grafana/`}
              target="_blank"
            >
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'File'} />
                <span>{translate('datacosmos.header.menu.grafana')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToAccessMatomo ? (
            <MenuItem id={'matomo'} href={`/matomo/`} target="_blank">
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'File'} />
                <span>{translate('datacosmos.header.menu.matomo')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToAccessKeyCloak ? (
            <MenuItem id={'keycloak'} href={`/admin/`} target="_blank">
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'File'} />
                <span>{translate('datacosmos.header.menu.keycloak')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}
          <MenuItem id={'logout'}>
            <div className="flex items-center gap-2" onClick={() => {}}>
              <Icon icon={'ChevronRightDouble'} />
              <span>{translate('datacosmos.header.menu.logout')}</span>
            </div>
          </MenuItem>
        </Menu>
      );
    }
  }

  return (
    <MenuTrigger>
      <Button className={'rounded-full py-5'}>{buttonContent}</Button>
      <Popover
        placement="bottom left"
        className="w-max tour-profile"
        offset={0}
      >
        {content}
      </Popover>
    </MenuTrigger>
  );
};
