import type { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import type {
  IAsset,
  IStacItem,
  BandTypes,
  CurrencyType,
  StacItem,
} from 'datacosmos/types/stac-types';
import { IMAGE_BAND } from 'datacosmos/types/stac-types';

export const getUserUploadedAssetKey = (item: IStacItem) => {
  for (const assetKey in item?.assets) {
    const asset = item?.assets[assetKey];
    if (asset.type === 'application/octet-stream') {
      return assetKey;
    }
  }
  return undefined;
};

export const getThumbnailAssetKey = (item: IStacItem) => {
  for (const assetKey in item?.assets) {
    const asset = item?.assets[assetKey];
    if (hasAssetRoleThumbnail(asset)) {
      return assetKey;
    }
  }

  return undefined;
};

export const getOverviewAssetKey = (item: IStacItem) => {
  for (const assetKey in item.assets) {
    const asset = item.assets[assetKey];
    if (hasAssetRoleOverview(asset) && isAssetPreviewable(asset)) {
      return assetKey;
    }
  }

  return getThumbnailAssetKey(item);
};

export const getVisualAssetKey = (item: IStacItem) => {
  for (const assetKey in item.assets) {
    const asset = item.assets[assetKey];
    if (hasAssetRoleVisual(asset) && isAssetPreviewable(asset)) {
      return assetKey;
    }
  }

  for (const assetKey in item.assets) {
    const asset = item.assets[assetKey];
    if (hasAssetRoleData(asset) && isAssetPreviewable(asset)) {
      return assetKey;
    }
  }

  return undefined;
};

export const hasOverviewRoleAsset = (item: IStacItem) => {
  return getOverviewAssetKey(item) !== undefined;
};

export const hasVisualHighresRoleAsset = (item: IStacItem) => {
  return getVisualAssetKey(item) !== undefined;
};

export const getImageBandAsset = (item: IStacItem, image: BandTypes) => {
  const assetKeys: string[] = IMAGE_BAND[image];
  if (assetKeys === undefined) {
    return true;
  }
  return Object.keys(item.assets).some((k) => assetKeys.includes(k));
};

export const isRasterImage = (item: IStacItem) => {
  for (const assetName in item.assets) {
    const asset = item.assets[assetName];
    if (isAssetCloudOptimised(asset)) {
      return true;
    }
  }
  return undefined;
};

export const isVectorImage = (item: IStacItem) => {
  for (const assetName in item.assets) {
    const asset = item.assets[assetName];
    if (isAssetKML(asset) || isAssetGeoJson(asset)) {
      return true;
    }
  }
  return undefined;
};

export const isAssetCloudOptimised = (asset: IAsset) => {
  const isCOG =
    asset.type?.includes('image/tiff') &&
    asset.type.includes('application=geotiff') &&
    asset.type.includes('profile=cloud-optimized');
  return Boolean(isCOG);
};

export const isAssetGeoJson = (asset: IAsset) => {
  const isGeojson = asset.type === 'application/geo+json';

  return isGeojson;
};

export const isAssetKML = (asset: IAsset) => {
  return asset.type === 'application/vnd.google-earth.kml+xml';
};

export const isAssetKMZ = (asset: IAsset) => {
  return asset.type === 'application/vnd.google-earth.kmz';
};

export const isAssetNonTiffImage = (asset: IAsset) => {
  if (asset.type) {
    return (
      asset.type === 'image/png' ||
      asset.type === 'image/jpeg' ||
      asset.type === 'image/webp'
    );
  } else {
    return (
      asset.href.split('.').pop() === 'png' ||
      asset.href.split('.').pop() === 'jpg' ||
      asset.href.split('.').pop() === 'jpeg' ||
      asset.href.split('.').pop() === 'webp'
    );
  }
};

export const hasAssetRole = (asset: IAsset, role: string) => {
  return asset.roles?.includes(role);
};

export const hasAssetRoleOverview = (asset: IAsset) => {
  return hasAssetRole(asset, 'overview');
};

export const hasAssetRoleThumbnail = (asset: IAsset) => {
  return hasAssetRole(asset, 'thumbnail');
};

export const hasAssetRoleVisual = (asset: IAsset) => {
  return hasAssetRole(asset, 'visual');
};

export const hasAssetRoleData = (asset: IAsset) => {
  return hasAssetRole(asset, 'data');
};

export const isAssetPreviewable = (asset: IAsset) => {
  const isCog = isAssetCloudOptimised(asset);
  const isNonTiff = isAssetNonTiffImage(asset);
  const isGeoJson = isAssetGeoJson(asset);
  const isKml = isAssetKML(asset);
  const isKmz = isAssetKMZ(asset);

  return isCog || isNonTiff || isGeoJson || isKml || isKmz;
};

export const currencySymbols: CurrencyType = {
  GBP: '£',
  USD: '$ ',
  PEN: 'S/. ',
};

export const isGeoreferencedWithGCP = (item: IStacItem) => {
  return (
    item.properties.platform === 'perusat-1' &&
    item.properties['processing:level']?.includes('PRIMARY')
  );
};

export const getBandAlgebraLayerPreview = (
  layer: BandAlgebraSTACLayer,
  item?: StacItem,
  outputFormat?: string,
  width?: number | 'auto',
  height?: number | 'auto'
) => {
  const formattedExpression =
    layer.expression.split('::').length > 1
      ? layer.expression.split('::')[1]
      : layer.expression.split('::')[0];
  const bandUrl = tilingApi.generateBandAlgebraPreviewUrl(
    item ?? layer.item,
    formattedExpression,
    layer.options.scale,
    layer.options.colormap,
    layer.options.bandAlgebraType,
    layer.options.rgbExpression,
    layer.options.rescaleFalse,
    outputFormat,
    width,
    height
  );
  return bandUrl;
};

export const getAssetPreviewRescale = async (
  item: IStacItem,
  assetKey: string,
  token?: string
) => {
  let rescale: [number, number][] | undefined = item.properties[
    'opencosmos:scale'
  ]
    ? [[0, item.properties['opencosmos:scale']]]
    : undefined;

  if (!rescale) {
    const paramsUrl = `item=${item.id}&collection=${item.collection}&assets=${assetKey}`;
    const cogMetadata = await tilingApi.fetchMetadataForCOG(paramsUrl, token);
    const mins = cogMetadata.getValueForAllBands('percentile_1');
    const maxs = cogMetadata.getValueForAllBands('percentile_99');
    rescale = mins.map((min, i) => [min, maxs[i]]);
  }
  return rescale;
};
