import CreateOrganisationForm from 'components/OrganisationConfiguration/CreateOrganisationForm';
import JoinOrCreateOrganisation from 'components/OrganisationConfiguration/JoinOrCreateOrganisation';
// import InviteUsersToJoinOrganisation from 'components/OrganisationConfiguration/InviteUsers';
import EmptyLayout from 'layouts/EmptyLayout';
import { useState } from 'react';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import { Navbar } from 'ui/Navbar';
import { useLocalisation } from 'utils/hooks/useLocalisation';

// 50px here is the height of the navbar
const CUSTOM_HEIGHT = `calc(100vh - 50px)`;

export enum ConfigurationSteps {
  'JoinOrCreateOrganisation',
  'CreateOrganisationForm',
  'InviteUsers',
}

interface ConfigurationOrganisationProps {
  user: IUserWithPermissions;
}
const ConfigureOrganisation = ({ user }: ConfigurationOrganisationProps) => {
  const [currentStep, setCurrentStep] = useState<ConfigurationSteps>(
    ConfigurationSteps.JoinOrCreateOrganisation
  );

  const { translate } = useLocalisation();

  const handleCreateOrganisation = () => {
    window.location.href = '/data/project/catalog';
  };

  return (
    <EmptyLayout>
      <Navbar
        bgClassName={'mission-page-header'}
        hideThemesInUserMenu
        showOnlyLogout
      />
      <div
        className="flex justify-center items-center flex-col"
        style={{ height: CUSTOM_HEIGHT }}
      >
        <div className="w-96 p-5 bg-surface dark:bg-surface-dark shadow-xl">
          {currentStep === ConfigurationSteps.JoinOrCreateOrganisation && (
            <JoinOrCreateOrganisation
              handleCreate={() => {
                setCurrentStep(ConfigurationSteps.CreateOrganisationForm);
              }}
              handleContinueAfterAddingUser={() => {
                window.location.href = '/data/project/catalog';
              }}
            />
          )}

          {currentStep === ConfigurationSteps.CreateOrganisationForm && (
            <CreateOrganisationForm
              handleSubmit={handleCreateOrganisation}
              handleSkip={() => {
                setCurrentStep(ConfigurationSteps.InviteUsers);
              }}
              user={user}
              submitLabel={translate(
                `onboarding.createOrganisation.form.buttons.next`
              )}
            />
          )}

          {/* TODO: Use this after invite users BE is ready */}
          {/* {currentStep === ConfigurationSteps.InviteUsers && (
            <InviteUsersToJoinOrganisation
              handleSubmit={() => {
                setCurrentStep(ConfigurationSteps.SetupBilling);
              }}
              handleSkip={() => {
                setCurrentStep(ConfigurationSteps.SetupBilling);
              }}
            />
          )} */}
        </div>
      </div>
    </EmptyLayout>
  );
};

export default ConfigureOrganisation;
