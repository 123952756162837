import { useRef } from "react";
import type { AriaDialogProps } from "@react-types/dialog";
import type { OverlayProps } from "@react-aria/overlays";
import {
  FocusScope,
  mergeProps,
  OverlayContainer,
  PressEvent,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
} from "react-aria";
import Button from "../../core/Button/Button";
import { IconName } from "../../icons/Icon";
import Spinner from "../Spinner/Spinner";
import classNames from "classnames";
import { zIndexValues } from "../../../constants";

type Btn = {
  text: string;
  onPress: (e: PressEvent) => Promise<void> | void;
  icon?: IconName;
  shown: boolean;
  showLoadingIndicator?: boolean;
  keepDialogOpenOnPress?: boolean;
  isDisabled?: boolean;
  intent?: "primary" | "secondary" | "warning";
  className?: string;
  href?: string;
};

type DialogProps = AriaDialogProps &
  OverlayProps & {
    children: JSX.Element | JSX.Element[];
    buttons: Btn[];
    title?: string;
    cancelButtonText?: string;
    hideCancelButton?: boolean;
    onClose?: () => void;
    isOpen?: boolean;
    showButtonsInFooter?: boolean;
    style?: object;
    isDismissable?: boolean;
    bodyClassName?: string;
  };

const Modal = (props: DialogProps) => {
  const { children, title, onClose } = props;
  const ref = useRef<HTMLDivElement>(null);

  const { overlayProps, underlayProps } = useOverlay(props, ref);
  usePreventScroll();

  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div
      {...underlayProps}
      className="fixed inset-0 w-full flex justify-center items-center bg-surface-dark/50 backdrop-blur-sm"
      style={{ zIndex: zIndexValues.dialog }}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...mergeProps(dialogProps, overlayProps, modalProps)}
          ref={ref}
          style={{
            maxWidth: "60%",
            width: "100%",
            ...props.style,
          }}
          className="color-surface !opacity-100 max-h-screen lg:max-h-[90vh] 2xl:max-h-[85vh] relative flex flex-col justify-between"
        >
          <div className="h-12 color-header flex justify-between items-center p-2 w-full">
            {title ? (
              <h4 {...titleProps} className="font-medium">
                {title}
              </h4>
            ) : (
              <div />
            )}
            <Button
              icon="Cross"
              onPress={() => onClose?.()}
              size="lg"
              isMinimal
              isTransparent
              iconClassName="stroke-header-contrast"
            />
          </div>
          <div
            className={classNames(
              props.bodyClassName,
              `p-4 overflow-auto max-h-[calc(100vh-3rem)] lg:max-h-[calc(90vh-3rem)] 2xl:max-h-[calc(85vh-3rem)]`
            )}
          >
            {children}
          </div>
          {(props.buttons?.length || !props.hideCancelButton) && (
            <div
              className={classNames(
                "flex flex-col justify-end w-full gap-1",
                {
                  "pt-8 p-4": !props.showButtonsInFooter,
                },
                {
                  "sticky bottom-0 bg-surface dark:bg-surface-dark py-2 px-4 border-t-2":
                    props.showButtonsInFooter,
                }
              )}
            >
              {props.buttons
                .filter((b) => b.shown)
                .map((b) => (
                  <Button
                    key={b.text}
                    onPress={async (e) => {
                      await b.onPress(e);
                      if (!b.keepDialogOpenOnPress) {
                        onClose?.();
                      }
                    }}
                    className={classNames("mb-1 p-4", b.className)}
                    size="sm"
                    fill
                    isDisabled={b.isDisabled}
                    icon={b.icon}
                    intent={b.intent}
                    href={b.href}
                  >
                    {b.showLoadingIndicator ? <Spinner size={16} /> : b.text}
                  </Button>
                ))}
              {!props.hideCancelButton && (
                <Button
                  onPress={() => onClose?.()}
                  className="p-4"
                  fill
                  size="sm"
                >
                  {props.cancelButtonText ?? "Close"}
                </Button>
              )}
            </div>
          )}
        </div>
      </FocusScope>
    </div>
  );
};

/**
 * Dialog is a component that can be used to show a modal dialog over the rest of the page.
 */
const Dialog = (props: DialogProps) => {
  return (
    <>
      {props.isOpen && (
        <OverlayContainer>
          <Modal {...props} />
        </OverlayContainer>
      )}
    </>
  );
};

export default Dialog;
