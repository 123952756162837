import type { GroupedNavItems } from 'opencosmos-ui/src/navigation/Sidebar/types';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from 'services/auth/AuthWrapper';
import { UserProfileMenu } from 'ui/UserMenu';
import { getSelectedNavItem } from 'pages/shared/utils/nav/navItem';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import { IconNames } from '@blueprintjs/icons';

const usePortalNav = () => {
  const h = useHistory();
  const { user, isAllowedToReadRoles, isAllowedToReadUsersRoles } = useAuth();

  const portalNavMain: GroupedNavItems = useMemo(() => {
    return {
      ungrouped: {
        items: [
          {
            key: 'organisations',
            item: 'Organisations',
            onClick: () => h.push(`/portal/organisations`),
            icon: 'office',
            title: clientTranslate(
              'datacosmos.header.menu.tooltip.organisations'
            ),
          },
          {
            key: 'roles',
            item: 'Roles Manager',
            hidden: !isAllowedToReadRoles,
            onClick: () => h.push(`/portal/roles`),
            icon: 'star',
            title: clientTranslate('datacosmos.header.menu.tooltip.roles'),
          },
          {
            key: 'users',
            item: 'Users',
            hidden: !isAllowedToReadUsersRoles,
            onClick: () => h.push(`/portal/users`),
            icon: 'UserGroup',
            title: clientTranslate('datacosmos.header.menu.tooltip.users'),
          },
          {
            key: 'userapproval',
            item: 'Users Approval',
            hidden: !isAllowedToReadUsersRoles,
            onClick: () => h.push(`/portal/userapproval`),
            icon: IconNames.INHERITED_GROUP,
            title: 'Aprobación de usuarios',
          },
        ],
      },
    };
  }, [h]);

  const portalNavFooter: GroupedNavItems = useMemo(() => {
    return {
      ungrouped: {
        items: [
          {
            key: 'home',
            item: 'Home',
            icon: 'home',
            title: clientTranslate('datacosmos.header.menu.tooltip.home'),
            onClick: () => {
              h.push('/');
            },
          },
          {
            divider: {
              above: true,
            },
            key: 'user-menu',
            item: (
              <div className="flex items-center gap-1 h-8">
                <div className="flex rounded-full w-[44px] h-12">
                  <UserProfileMenu />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm"> {user?.name}</span>
                  <span className="text-xs"> {user?.email}</span>
                </div>
              </div>
            ),
            collapsedItem: (
              <div className="flex rounded-full w-[44px] mt-1 ml-1">
                <UserProfileMenu />
              </div>
            ),
            hideTooltip: true,
          },
        ],
      },
    };
  }, [h, user?.email, user?.name]);

  const selectedPortalItemKey = useMemo(
    () => getSelectedNavItem(portalNavMain, h.location.pathname),
    [h.location.pathname, portalNavMain]
  );

  return {
    portalNavMain,
    portalNavFooter,
    selectedPortalItemKey,
  };
};

export default usePortalNav;
