import ResourceLayout from './ResourceLayout';
import ResourceHeader from './ResourceHeader';
import DefaultResourceTypeLayout from 'layouts/datacosmos/DefaultResourceTypesLayout';
import imageCatalogApi from 'datacosmos/services/imageCatalogApi';
import { useAuth } from 'services/auth/AuthWrapper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { StacItem } from 'datacosmos/types/stac-types';
import StacItemDetails from './StacItemDetails';
interface Props {
  resourceId: string;
  resourceType: string;
  isAllowedToReadRoles: boolean;
}
const ItemResources = (props: Props) => {
  const { token } = useAuth();
  const catalogApi = useMemo(() => imageCatalogApi(token), [token]);

  const [itemData, setItemData] = useState<StacItem | undefined>(undefined);
  const fetchItemData = useCallback(async () => {
    if (!props.resourceId) return;
    await catalogApi.fetchItem(props.resourceId).then((res: StacItem) => {
      setItemData(res);
    });
  }, [catalogApi, props.resourceId]);

  useEffect(() => {
    void fetchItemData();
  }, [fetchItemData]);

  return (
    <DefaultResourceTypeLayout
      loading={!itemData?.id}
      middleTitle={<div className="text-lg font-bold">Item</div>}
    >
      <ResourceHeader title={props.resourceId} icon="Image" />
      {itemData && <StacItemDetails stacItem={itemData} />}

      <div className="my-5 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      <ResourceLayout
        resourceId={props.resourceId}
        resourceType={props.resourceType}
        isAllowedToReadRoles={props.isAllowedToReadRoles}
      />
    </DefaultResourceTypeLayout>
  );
};

export default ItemResources;
