import { ENABLE_DARK_MODE } from 'env';
import React, { useCallback, useEffect, useMemo } from 'react';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import useMatchMedia from 'utils/hooks/useMatchMedia';

interface IProps {
  children: React.ReactNode;
}

export type Theme = 'LIGHT' | 'DARK' | 'AUTO';

export interface IThemeContext {
  isDarkmode: boolean;
  setTheme: (theme: Theme) => void;
  theme: Theme;
}

export const ThemeContext = React.createContext<IThemeContext>(
  null as unknown as IThemeContext
);
export const useTheme = () => React.useContext<IThemeContext>(ThemeContext);

const setDarkMode = () => {
  window.document.documentElement.classList.add('dark');
  window.document.documentElement.classList.remove('light');
};

const setLightMode = () => {
  window.document.documentElement.classList.add('light');
  window.document.documentElement.classList.remove('dark');
};

const ThemeProvider = ({ children }: IProps) => {
  const [theme, setTheme] = useLocalStorage<Theme>('theme', 'LIGHT');

  const matchesSystemDarkmode = useMatchMedia('(prefers-color-scheme:dark)');

  const handleAutoThemeSet = useCallback(() => {
    if (matchesSystemDarkmode) {
      setDarkMode();
    } else {
      setLightMode();
    }
    setTheme('AUTO');
  }, [matchesSystemDarkmode, setTheme]);

  const handleDarkThemeSet = useCallback(() => {
    setDarkMode();
    setTheme('DARK');
  }, [setTheme]);

  const handleLightThemeSet = useCallback(() => {
    setLightMode();
    setTheme('LIGHT');
  }, [setTheme]);

  const isDarkmode = useMemo(() => {
    if (!ENABLE_DARK_MODE) return false;
    if (theme === 'DARK') return true;
    if (theme === 'LIGHT') return false;
    return false;
  }, [theme]);

  // Only handle themes if ENABLE_DARK_MODE env var is enabled, else force lightmode
  useEffect(() => {
    if (ENABLE_DARK_MODE) {
      if (theme === 'DARK') {
        handleDarkThemeSet();
      } else if (theme === 'LIGHT') {
        handleLightThemeSet();
      } else {
        handleAutoThemeSet();
      }
    } else {
      handleLightThemeSet();
    }
  }, [theme, handleAutoThemeSet, handleDarkThemeSet, handleLightThemeSet]);

  return (
    <ThemeContext.Provider value={{ isDarkmode, setTheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
