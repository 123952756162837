import type { SearchInputProps } from '_atoms/SearchInput/SearchInput';
import SearchInput from '_atoms/SearchInput/SearchInput';
import { Button, Dialog, Icon } from 'opencosmos-ui';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import Fuse from 'fuse.js';
import type { StacItem } from 'datacosmos/types/stac-types';
import moment from 'moment';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import { hosts } from '_api/hosts';
import { getViewItemLink } from 'utils/common/CommonUtils';

type Props = {
  /**
   * Data to be displayed in the modal list.
   * Accepted format is in the form of key-value pairs
   */
  data: StacItem;

  /**
   * Text for the button responsible for modal opening
   */
  buttonText: string;

  /**
   * Icon for the button responsible for modal opening. If omitted, Expand will be used by default
   */
  buttonIcon: IconName;
  /**
   * Search bar input props. If omitted, search bar will not be present in the modal
   */
  searchBarProps?: SearchInputProps;
  /**
   * Optional title to display in the modal
   */
  modalTitle?: string;
};

type MetadataValue = boolean | string | number | object;

const getTranslationKey = (key: string) => {
  return `datacosmos.catalogAndItems.metadataModal.${key.replace(
    /:/g,
    '_'
  )}` as unknown as TemplateStringsArray;
};

const itemLinkRegex = new RegExp(
  `${hosts.data.v0}/stac/collections/([^/]+)/items/([^/]+)`
);

const parseHref = (href: string) => {
  const match = href.match(itemLinkRegex);
  if (match) {
    return getViewItemLink(match[1], match[2]);
  }
  return href;
};

const getHrefLabel = (href: string) => {
  const match = href.match(itemLinkRegex);
  if (match) {
    return `${match[1]}/${match[2]}`;
  }
  return href;
};

/**
 * MetadataModal component should be used when displaying arbitrary key-value pair data
 * in a separate overlay in the app.
 *
 */
const MetadataModal = ({
  data,
  modalTitle,
  searchBarProps,
  buttonText,
  buttonIcon,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const enrichedProperties = useCallback(
    (si: StacItem) => {
      return {
        ...si.properties,
        location: data.location(),
        item: si.id,
        collection: si.collection ?? '',
      };
    },
    [data]
  );

  const [metadata, setMetadata] = useState<StacItem['properties']>(
    enrichedProperties(data)
  );

  const { translate, translateWithExplicitFallback } = useLocalisation();

  const getFormattedTranslationLabel = (key: string) => {
    const formattedKey = key
      .replace(/^opencosmos:/, '')
      .replace(/_/g, ' ')
      .replace(/:/g, ' ');

    return translateWithExplicitFallback(getTranslationKey(key), formattedKey);
  };

  const getFormattedValue = (value: MetadataValue): string | number => {
    if (
      typeof value === 'string' &&
      moment(value, 'YYYY-MM-DDTHH:mm:ss[Z]', true).isValid()
    ) {
      return `${value} / ${moment(value).unix()}`;
    }
    if (typeof value === 'string' && !moment(value).isValid()) {
      // Workaround for values containing ':', which is the namespace separator in i18next.
      // TODO: Handle this in i18next options if we use namespaces in the future.
      const safeValue = value.replaceAll(':', '.');
      return translateWithExplicitFallback(
        `datacosmos.catalogAndItems.metadata.values.${safeValue}` as unknown as TemplateStringsArray,
        value
      );
    }
    if (typeof value === 'boolean') {
      return translate(`SOSTable.${value ? 'yes' : 'no'}`);
    }
    if (typeof value === 'object') return JSON.stringify(value);
    return value;
  };

  const metadataToSearchableArray = (meta: StacItem['properties']) =>
    Object.entries(meta).map(([key, val]) => ({
      id: key,
      val: val as string,
    }));

  const searchableMetadataToDisplayableObject = (
    metaArray: { id: string; val: string }[]
  ) =>
    metaArray.reduce((acc, item) => {
      acc = { ...acc, [item.id]: item.val };
      return acc;
    }, []);

  const fuse = useMemo(
    () =>
      new Fuse(metadataToSearchableArray(enrichedProperties(data)), {
        keys: ['id', 'val'],
        minMatchCharLength: 2,
        threshold: 0.4,
      }),
    [data, enrichedProperties]
  );

  useEffect(() => {
    setMetadata(
      searchTerm === ''
        ? enrichedProperties(data)
        : searchableMetadataToDisplayableObject(fuse.search(searchTerm))
    );
  }, [data, data.properties, fuse, searchTerm, enrichedProperties]);

  return (
    <>
      <Dialog
        title={modalTitle}
        isOpen={open}
        onClose={() => setOpen(false)}
        buttons={[]}
        hideCancelButton
        bodyClassName="flex flex-col"
      >
        <>
          {searchBarProps && (
            <SearchInput
              {...searchBarProps}
              onChange={(text) => setSearchTerm(text)}
              onSubmit={(text) => setSearchTerm(text)}
            />
          )}
          <div className="overflow-auto mt-2">
            <ul className="flex flex-col items-center">
              <li className="grid !shadow-none grid-cols-2 items-center color-header pl-2 py-3 w-full gap-4">
                <span className="font-bold">
                  {translate(
                    'datacosmos.catalogAndItems.metadataModal.properties'
                  )}
                </span>
                <span className="font-bold">
                  {translate('datacosmos.catalogAndItems.metadataModal.values')}
                </span>
              </li>
              {Object.entries(metadata).map(([key, val]) => {
                return (
                  <li
                    key={key}
                    className="grid !shadow-none grid-cols-2 items-center border-b pl-2 py-1 w-full gap-4"
                  >
                    <span
                      className="font-bold border-slate-300 capitalize"
                      data-translation-key={getTranslationKey(key)}
                    >
                      {getFormattedTranslationLabel(key)}
                    </span>
                    <span className="break-words">
                      {getFormattedValue(val as MetadataValue)}
                    </span>
                  </li>
                );
              })}
            </ul>
            <ul className="flex flex-col items-center">
              <li className="grid !shadow-none grid-cols-2 items-center color-header pl-2 py-3 w-full gap-4">
                <span className="font-bold">Links</span>
                <span className="font-bold">
                  {translate('datacosmos.catalogAndItems.metadataModal.values')}
                </span>
              </li>
              {data.links
                .filter(({ href }) => Boolean(href))
                .map(({ title, href, rel }) => {
                  return (
                    <li
                      key={title}
                      className="grid !shadow-none grid-cols-2 items-center border-b pl-2 py-1 w-full gap-4"
                    >
                      <span
                        className="font-bold border-slate-300 capitalize"
                        data-translation-key={getTranslationKey(title ?? '')}
                      >
                        {translateWithExplicitFallback(
                          `datacosmos.catalogAndItems.metadataModal.links.${rel}` as unknown as TemplateStringsArray,
                          title ?? rel
                        )}
                      </span>
                      <span className="break-words">
                        <a
                          href={parseHref(href)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {getHrefLabel(href)}
                        </a>
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </>
      </Dialog>
      <Icon
        icon={buttonIcon}
        className="stroke-item-contrast dark:stroke-item-dark-contrast align-middle"
      />
      <Button onPress={() => setOpen(true)} isMinimal size="sm" isTransparent>
        {buttonText}
      </Button>
    </>
  );
};

export default MetadataModal;
