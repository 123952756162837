import { useEffect, useState } from 'react';
import { find, isEmpty } from 'lodash/fp';
import {
  Button,
  ControlGroup,
  Intent,
  MenuItem,
  Classes,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ItemRenderer } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import s from './index.module.scss';
import { isNil } from 'lodash';
import type {
  IRole,
  IRoleAssignment,
} from 'services/api/portal/administration/api/types';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Tooltip } from 'opencosmos-ui';

/**
 * IRoleAssignmentItem is used in the MultiSelect to keep track of the roles that
 * are assigned and the relevant information.
 *
 * To add a new role assignment, only the roleId is necessary, as the assignment
 * ID is created by the backend. When a role is first assigned, it uses
 * optimistic rendering and the role is added without an assignmentId. The
 * assignmentId is added as soon as the post request completes with the
 * appropriate information.
 *
 * To delete role assignments, please use the assignment ID.
 *
 * The name and description are used for rendering.
 *
 * Note that, in creation mode, the items will not have an assignmentID, as the
 * assignments will not only be created on saving the new user.
 *
 * This interface is intended to use only within this component.
 */
interface IRoleAssignmentItem {
  assignmentId?: number;
  roleId: number;
  roleName: string;
  roleDescription: string;
}

export interface CommonProps {
  userId: string;
  currentId?: number | string;
  creationMode?: boolean;
  availableRoles: IRole[];
  disableRemoval?: boolean;
  onCancel?: () => void;
  assignRole: (userId: string, roleIds: number[]) => void;
  deleteRoleAssignments?: (
    userId: string,
    assignmentIds: number[],
    showMessage?: boolean
  ) => void;
}

export type IProps = CommonProps &
  (
    | {
        currentAssignments?: IRoleAssignmentItem[];
        isRoleAssignmentItemFormatted?: true;
      }
    | {
        currentAssignments?: IRoleAssignment[];
        isRoleAssignmentItemFormatted?: false;
      }
  );

const RoleMultiSelect = MultiSelect.ofType<IRoleAssignmentItem>();

const AdministrationRoleSelect = (props: IProps) => {
  const [selectedRoleAssignments, setSelectedRoleAssignments] = useState<
    IRoleAssignmentItem[]
  >([]);

  const { translate } = useLocalisation();

  useEffect(() => {
    if (!isNil(props.currentAssignments)) {
      if (props.isRoleAssignmentItemFormatted) {
        setSelectedRoleAssignments(
          props.currentAssignments as IRoleAssignmentItem[]
        );
        return;
      }
      setSelectedRoleAssignments(
        (props.currentAssignments as IRoleAssignment[]).map((a) => ({
          assignmentId: a.id,
          roleId: a.roleId,
          roleName: a.role.name,
          roleDescription: a.role.description,
        }))
      );
    }
  }, [props.currentAssignments, props.isRoleAssignmentItemFormatted]);

  const handleRemoveRoleAssignment = (item: IRoleAssignmentItem) => {
    if (props.creationMode) {
      const clearedRoles = selectedRoleAssignments.filter(
        (element) => element.roleId !== item.roleId
      );

      setSelectedRoleAssignments(clearedRoles);
      return;
    }

    if (props.deleteRoleAssignments && item.assignmentId)
      props.deleteRoleAssignments(props.userId, [item.assignmentId]);
  };

  const handleRemoveAllRoleAssignments = () => {
    if (props.deleteRoleAssignments)
      props.deleteRoleAssignments(
        props.userId,
        selectedRoleAssignments.map((r) => r.assignmentId ?? 0),
        true
      );
  };

  const handleSelectRole = (item: IRoleAssignmentItem) => {
    const foundRole = selectedRoleAssignments.find(
      (element) => element.roleId === item.roleId
    );

    if (props.creationMode) {
      if (foundRole) {
        const clearedRoles = selectedRoleAssignments.filter(
          (element) => element.roleId !== item.roleId
        );

        setSelectedRoleAssignments(clearedRoles);
        return;
      }

      setSelectedRoleAssignments([...selectedRoleAssignments, item]);
      return;
    }

    if (props.deleteRoleAssignments && foundRole?.assignmentId) {
      props.deleteRoleAssignments(props.userId, [foundRole.assignmentId]);
      return;
    }

    props.assignRole(props.userId, [item.roleId]);
  };

  const handleSaveClick = () => {
    props.assignRole(
      props.userId,
      selectedRoleAssignments.map((item) => item.roleId)
    );
    if (props.onCancel) props.onCancel();
  };

  const renderRole: ItemRenderer<IRoleAssignmentItem> = (
    item,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const selected = find(['id', item.roleId], selectedRoleAssignments);

    return (
      <MenuItem
        className={s.roleMenuItem}
        key={item.roleId}
        active={modifiers.active}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
        text={
          <div style={{ margin: 3 }}>
            <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
              {item.roleName}
            </p>
            <p style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}>
              {item.roleDescription}
            </p>
          </div>
        }
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  };

  const clearButton = selectedRoleAssignments.length > 0 && (
    <Button
      icon={IconNames.CROSS}
      minimal
      onClick={handleRemoveAllRoleAssignments}
    />
  );

  const itemStyle = props.creationMode ? s.newItemWidth : s.itemWidth;

  const isSaveDisabled = isEmpty(selectedRoleAssignments) || !props.userId;

  const errorContent = !props.userId
    ? translate('userPage.assignedPermissions.errors.cannotSaveEmptyUser')
    : translate(
        'userPage.assignedPermissions.errors.cannotSaveUserwithoutRole'
      );

  const sortedRoles = props.availableRoles.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  return (
    <div className={s.roleContainer}>
      <ControlGroup className={Classes.FILL}>
        <RoleMultiSelect
          className={[s.rolePicker, itemStyle, Classes.FILL].join(' ')}
          items={sortedRoles.map((r) => ({
            roleId: r.id,
            roleName: r.name,
            roleDescription: r.description,
          }))}
          placeholder={translate('userPage.roles')}
          itemRenderer={renderRole}
          tagRenderer={(it) => it.roleName}
          onItemSelect={handleSelectRole}
          selectedItems={selectedRoleAssignments}
          onRemove={handleRemoveRoleAssignment}
          tagInputProps={{
            rightElement: clearButton as JSX.Element,
            className: s.tag,
          }}
        />
        {props.creationMode ? (
          <>
            <Tooltip isDisabled={!isSaveDisabled} content={errorContent}>
              <Button
                className={isSaveDisabled ? '' : s.button}
                disabled={isSaveDisabled}
                icon={IconNames.SAVED}
                intent={isSaveDisabled ? Intent.NONE : Intent.PRIMARY}
                onClick={handleSaveClick}
              />
            </Tooltip>
            <Button icon={IconNames.CROSS} onClick={props.onCancel} />
          </>
        ) : (
          <Button
            className={s.button}
            icon={IconNames.Trash}
            intent={Intent.DANGER}
            onClick={handleRemoveAllRoleAssignments}
            disabled={props.disableRemoval}
          />
        )}
      </ControlGroup>
    </div>
  );
};

export default AdministrationRoleSelect;
