import { useMemo, useState } from 'react';
import UserListItem from './UserListItem';
import type {
  IRole,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { Button, SearchInput, Tooltip } from 'opencosmos-ui';
import { debounce } from 'lodash';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { usersExportURL } from '_api/users/service';
import { downloadToFileName } from 'utils/common/CommonUtils';
import moment from 'moment';
import { ISO_FORMAT_NO_MILLISECONDS } from 'constants/datetime';

interface IProps {
  availableRoles: IRole[];
  users: IUserWithPermissions[] | undefined;
  assignRole: (userId: string, roleIds: number[]) => void;
  deleteRoleAssignments: (
    userId: string,
    assignmentIds: number[],
    showDeletedMessage?: boolean
  ) => void;
  setUserSearchQuery: (query?: string) => void;
  userSearchQuery?: string;
  isFetching: boolean;
}

const AdministrationUsers = (props: IProps) => {
  const [showHiddenRoles, setShowHiddenRoles] = useState(false);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);

  const filteredRoles = useMemo(
    () =>
      props.availableRoles.filter((r) => showHiddenRoles || r.userAssignable),
    [props.availableRoles, showHiddenRoles]
  );

  const { translate } = useLocalisation();

  const debouncedOnChange = debounce((value: string | undefined): void => {
    props.setUserSearchQuery(value);
  }, 100);

  const handleDownload = async () => {
    setIsLoadingCSV(true);
    await downloadToFileName(
      usersExportURL,
      translate('userPage.downloadAllFilename', {
        date: moment().format(ISO_FORMAT_NO_MILLISECONDS),
      }),
      'text/csv'
    );
    setIsLoadingCSV(false);
  };

  return (
    <div className="">
      <div className="w-full flex flex-col mb-6">
        <div className="flex justify-between mb-2 gap-2">
          <div className="text-base grow">{translate('userPage.users')}</div>
          <label className="flex items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={showHiddenRoles}
              onChange={() => setShowHiddenRoles((p) => !p)}
            />
            <span>{translate('userPage.showHiddenRoles')}</span>
          </label>
          <Tooltip
            content={translate('userPage.downloadAllTooltip')}
            placement="top"
          >
            <Button
              icon="Download"
              onPress={handleDownload}
              loading={isLoadingCSV}
              className="min-w-[10rem]"
            >
              {translate('userPage.downloadAll')}
            </Button>
          </Tooltip>
        </div>

        <SearchInput
          fullWidth={true}
          onClear={() => {
            debouncedOnChange(undefined);
          }}
          onChange={(val) => {
            debouncedOnChange(val);
          }}
          value={props.userSearchQuery}
          placeholder={translate('userPage.searchPlaceholder')}
        />
      </div>
      <ul>
        {props.isFetching ? (
          <Spinner />
        ) : (
          props.users?.map((user) => (
            <UserListItem
              key={user.id}
              user={user}
              assignRole={props.assignRole}
              deleteRoleAssignments={props.deleteRoleAssignments}
              availableRoles={filteredRoles}
            />
          ))
        )}
      </ul>
    </div>
  );
};

export default AdministrationUsers;
