import type { PermissionType } from '_api/administration/permissions';

export type User = {
  id: string;
  name: string;
  email: string;
  email_verified: boolean;
  picture: string;
};
export type Role = {
  createdAt: string;
  description: string;
  id: number;
  name: string;
  permissions: string[];
  resourceType: PermissionType;
  userAssignable: boolean;
};

export type RoleAssignment = {
  id: number;
  resourceId: string;
  role: Role;
  roleId: number;
  type: PermissionType;
  userId: string;
  createdAt: string;
};

export type UpdateRole = {
  id: number;
  name: string;
  permissions: string[];
  userAssignable: boolean;
  description: string;
  resourceType: PermissionType;
};

export type CreateRoleAttachment = {
  parentResourceType: string;
  parentResourceId: string;
  childRoleId: number;
  childResourceType: string;
  childResourceId: string;
};

export type RoleAttachment = {
  parentResourceType: string;
  childRoleId: number;
  parentRoleId: number;
  childResourceType: string;
  childResourceId: string;
  id: number;
  createdAt: string;
};

export type UserWithPermissions = {
  sub: string;
  id: string;
  name: string;
  email: string;
  email_verified: boolean;
  picture: string;
  assignments?: RoleAssignment[];
  attributes?: UserAttributes;
};

export const USER_UPGRADE_REQUEST_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
} as const;

export type UserUpgradeRequestStatus =
  (typeof USER_UPGRADE_REQUEST_STATUS)[keyof typeof USER_UPGRADE_REQUEST_STATUS];

export type UserAttributes = {
  upgrade?: UserUpgradeRequestStatus | UserUpgradeRequestStatus[];
  organisation_name?: string | string[];
  organisation_area?: string | string[];
  organisation_activities?: string | string[];
  organisation_applications?: string | string[];
  is_directive_accepted?: boolean | boolean[];
  approved_by?: string | string[];
  [key: string]: any;
};

export type KeycloakUser = {
  id: string;
  username?: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  attributes?: UserAttributes;
  userProfileMetadata?: object;
};
