import type { IRole } from 'services/api/portal/administration/api/types';

export const PERMISSION_TYPE_LIST = [
  'datacosmos_app',
  'datacosmos_scenario',
  'datacosmos_view',
  'datacosmos_stac_collection',
  'datacosmos_stac_item',
  'global',
  'mission',
  'organisation',
] as const;

export type PermissionType = (typeof PERMISSION_TYPE_LIST)[number];

export type AttachedRoleAssignment = {
  id: number;
  parentRoleId: number;
  parentResourceType: string;
  parentResourceId: number;
  childRoleId: number;
  childResourceType: string;
  childResourceId: number;
  createdAt: string;
  parentRole: IRole;
  childRole: IRole;
};

export type PermissionScope =
  | 'admin:grafana'
  | 'admin:keycloak'
  | 'admin:matomo'
  | 'data:app:create'
  | 'data:app:execute'
  | 'data:app:read'
  | 'data:app:write'
  | 'data:app'
  | 'data:catalog:internal_filters'
  | 'data:credit:balance:read:own'
  | 'data:credit:balance:read'
  | 'data:credit:credit:read'
  | 'data:credit:credit:write'
  | 'data:credit:debit:read'
  | 'data:credit:debit:write'
  | 'data:credit'
  | 'data:customer:create'
  | 'data:customer'
  | 'data:docs'
  | 'data:feature:docs'
  | 'data:feature'
  | 'data:image:external:assignment:read'
  | 'data:image:external:read'
  | 'data:order:confirm'
  | 'data:order:create'
  | 'data:order:read:own'
  | 'data:processing:assignment:read'
  | 'data:processing:assignment:write'
  | 'data:processing:read'
  | 'data:processing:write'
  | 'data:processing'
  | 'data:scenario:assignment:read'
  | 'data:scenario:assignment:write'
  | 'data:scenario:assignment'
  | 'data:scenario:create'
  | 'data:scenario:read:own'
  | 'data:scenario:read'
  | 'data:scenario:write'
  | 'data:scenario'
  | 'data:stac_collection:assignment:read'
  | 'data:stac_collection:assignment:write'
  | 'data:stac_collection:read'
  | 'data:stac_collection:write'
  | 'data:stac_collection'
  | 'data:ticket:create'
  | 'data:ticket:write:create'
  | 'data:ticket'
  | 'data:view:assignment:read'
  | 'data:view:assignment:write'
  | 'data:view:assignment'
  | 'data:view:create'
  | 'data:view:read:own'
  | 'data:view:read'
  | 'data:view:write'
  | 'data:view'
  | 'data'
  | 'hil'
  | 'organisation:assignment:read'
  | 'organisation:assignment:write'
  | 'organisation:billing-details:write'
  | 'organisation:create'
  | 'organisation:read:own'
  | 'organisation:read'
  | 'organisation:update'
  | 'organisation'
  | 'portal:cloudstorage:file:create'
  | 'portal:cloudstorage:file:delete'
  | 'portal:cloudstorage:file:read'
  | 'portal:cloudstorage:folder:create'
  | 'portal:cloudstorage:folder:read'
  | 'portal:cloudstorage'
  | 'portal:ephemeris:read'
  | 'portal:ephemeris:write'
  | 'portal:gs:ephemeris:read'
  | 'portal:gs:ephemeris:write'
  | 'portal:gs:mission:read'
  | 'portal:gs:mission:write'
  | 'portal:gs:opportunities:read'
  | 'portal:gs:pass:read'
  | 'portal:gs:pass:write'
  | 'portal:gs:provider_config:read'
  | 'portal:gs:site_antenna:write'
  | 'portal:gs'
  | 'portal:none'
  | 'portal'
  | 'relationship:write'
  | 'role:read:user-assignable'
  | 'role:read'
  | 'role:write'
  | 'role'
  | 'subject:read'
  | 'subject'
  | 'user-is-public'
  | 'user:assignment:read:own'
  | 'user:assignment:read'
  | 'user:assignment:write'
  | 'user:assignment'
  | 'user:read:by-email'
  | 'user:read:own'
  | 'user:read'
  | 'user';
