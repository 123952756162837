import { UserWithPermissions } from '_api/users/types';
import React from 'react';

const UserApprovalDetails = ({ user }: { user: UserWithPermissions }) => {
  const {
    organisation_activity,
    organisation_application,
    organisation_area,
    organisation_name,
  } = user.attributes ?? {};
  return (
    <div>
      <ul className="flex flex-col gap-2">
        <li className="flex justify-between">
          <strong className="basis-2/5">Dirección de e-mail:</strong>
          <span className="basis-3/5 text-left">{user.email}</span>
        </li>
        <li className="flex justify-between">
          <strong className="basis-2/5">Nombre de la institución:</strong>
          <span className="basis-3/5 text-left">{organisation_name}</span>
        </li>
        <li className="flex justify-between">
          <strong className="basis-2/5">Área o dependencia de trabajo:</strong>
          <span className="basis-3/5 text-left">{organisation_area}</span>
        </li>
        <li className="flex justify-between">
          <strong className="basis-2/5">
            Actividades dentro de la institución:
          </strong>
          <span className="basis-3/5 text-left">{organisation_activity}</span>
        </li>
        <li className="flex justify-between">
          <strong className="basis-2/5">Aplicaciones que desarrolla:</strong>
          <span className="basis-3/5 text-left">
            {organisation_application}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default UserApprovalDetails;
