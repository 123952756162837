import RadioInput from '_molecules/RadioInput/RadioInput';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import type { StacItem } from 'datacosmos/types/stac-types';
import { HOST_URL } from 'env';
import { Checkbox, Dialog } from 'opencosmos-ui';
import { useCallback, useState } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import { copyToClipBoard } from 'utils/common/CommonUtils';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface ExportStacItemLinkProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  stacItem: StacItem;
}

const ExportStacItemLink = ({
  isOpen,
  setIsOpen,
  stacItem,
}: ExportStacItemLinkProps) => {
  const themeProvider = useTheme();
  const { token } = useAuth();
  const { translate } = useLocalisation();
  const [selectedLinkType, setSelectedLinkType] = useState<string | undefined>(
    undefined
  );
  const [isTokenChecked, setIsTokenChecked] = useState<boolean>(false);

  const RadioItemsList = [
    {
      name: 'QGIS',
      value: 'QGIS',
      disabled: false,
    },
    {
      name: 'ArcGIS',
      value: 'ArcGIS',
      disabled: false,
    },
  ];

  const handleCopyLink = useCallback(() => {
    if (!stacItem.tileURL) {
      return;
    }
    const urlToCopy = stacItem.expressionURL ?? stacItem.tileURL;
    const modifiedTileURL = urlToCopy
      .replace('{z}', '{level}')
      .replace('{x}', '{col}')
      .replace('{y}', '{row}');
    let link = selectedLinkType === 'QGIS' ? urlToCopy : modifiedTileURL;
    if (isTokenChecked) {
      link += `&access_token=${token}`;
    }
    copyToClipBoard(link);
  }, [selectedLinkType, isTokenChecked]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setIsTokenChecked(false);
        setSelectedLinkType(undefined);
      }}
      buttons={[
        {
          onPress: () => handleCopyLink(),
          text: translate('datacosmos.layers.exportLink.copyLink'),
          shown: true,
          isDisabled: !selectedLinkType,
        },
      ]}
      title={translate('datacosmos.layers.exportLink.title')}
      showButtonsInFooter
      style={{
        maxWidth: '500px',
        width: '100%',
      }}
      cancelButtonText={translate('datacosmos.buttons.close')}
    >
      <div className="flex flex-col gap-2">
        <RadioInput
          style={{ height: '100%' }}
          onChange={(event) => {
            setSelectedLinkType(event.target.value);
          }}
          radioName="linkType"
          radioItems={RadioItemsList}
          label={{
            text: translate('datacosmos.layers.exportLink.label'),
            position: 'left',
          }}
          className="my-2 flex items-center gap-1"
        />

        <Checkbox
          themeProvider={themeProvider}
          onChange={(selected) => {
            setIsTokenChecked(Boolean(selected));
          }}
          isSelected={isTokenChecked}
        >
          {translate('datacosmos.layers.exportLink.includeToken')}
        </Checkbox>
      </div>
    </Dialog>
  );
};

export default ExportStacItemLink;
