import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import type { KeycloakUser, User, UserWithPermissions } from '_api/users/types';
import { ORDER_SERVICE_ENDPOINT } from 'env';

export type GetUserParams = {
  subject: string;
};

export const getUser = apiFetchHandler<User, GetUserParams>({
  host: hosts.portal.v0,
  endpoint: ({ subject }) => `/users/${subject}`,
  method: 'GET',
  errorMessage: 'Could not get user',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});

export type GetUsersByEmailParams = {
  email: string;
};

export const getUsersByEmail = apiFetchHandler<User, GetUsersByEmailParams>({
  host: hosts.portal.v0,
  endpoint: ({ email }) => `/users?email=${encodeURIComponent(email)}`,
  method: 'GET',
  errorMessage: 'Could not get user by email',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});

export type GetUsersByQuery = {
  query?: string;
  resultsPerPage?: number;
  page?: number;
  sort?: boolean;
  includeRoles?: boolean;
  filterByRoleType?: string;
  attributesQuery?: string;
};

export const getAllUsers = apiFetchHandler<
  UserWithPermissions[],
  GetUsersByQuery
>({
  host: hosts.portal.v0,
  endpoint: ({
    query,
    resultsPerPage,
    page,
    sort,
    includeRoles,
    filterByRoleType,
    attributesQuery,
  }) =>
    `/users?query=${encodeURIComponent(
      query ?? ''
    )}&perPage=${encodeURIComponent(
      resultsPerPage ?? ''
    )}&page=${encodeURIComponent(page ?? '')}&sort=${encodeURIComponent(
      sort ?? ''
    )}&includeRoles=${encodeURIComponent(
      includeRoles ?? ''
    )}&filterByRoleType=${encodeURIComponent(
      filterByRoleType ?? ''
    )}&attr=${encodeURIComponent(attributesQuery ?? '')}`,
  method: 'GET',
  errorMessage: 'Could not get user by query',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});

export type UpgradeUsersParams = {
  permission: string;
};

export const UpdateUsersLevel = apiFetchHandler<User, UpgradeUsersParams>({
  host: hosts.data.v0,
  endpoint: ({ permission }) =>
    `/${ORDER_SERVICE_ENDPOINT}/users/upgrade?type=${encodeURIComponent(
      permission
    )}`,
  method: 'PUT',
  errorMessage: 'Could not upgrade user level',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});

export type JoinOrganisationParams = {
  organisationId: string;
};

export const usersExportURL = `${hosts.portal.v0}/users/export`;

export type UserUpgradeParams = {
  [key: string]: string;
};

export const getKeycloakUser = apiFetchHandler<KeycloakUser, {}>({
  host: hosts.keycloak.account,
  endpoint: () => '/',
  method: 'GET',
  errorMessage: 'Hubo un error consultando los detalles del usuario',
  errorDescription: (e) => e.join('; '),
});

export const updateKeycloakUser = apiFetchHandler<
  null,
  UserUpgradeParams,
  KeycloakUser
>({
  host: hosts.keycloak.account,
  endpoint: () => '/',
  method: 'POST',
  errorMessage: 'Hubo un error enviando la solicitud',
  onError: ({ state }) => {
    if (state.status === 204) {
      return true;
    }
    return false;
  },
  errorDescription: (e) => e.join('; '),
});

export type PostUserParams = {
  userId: string;
};

export const toKeycloakUser = (user: User): KeycloakUser => {
  return {
    ...user,
    firstName: user.name.split(' ')[0],
    lastName: user.name.split(' ').slice(1).join(' '),
    emailVerified: user.email_verified,
  };
};

export const updateUser = apiFetchHandler<null, PostUserParams, KeycloakUser>({
  host: hosts.portal.v0,
  endpoint: ({ userId }) => `/users/${userId}`,
  method: 'PUT',
  errorMessage: 'Could not get user',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});
