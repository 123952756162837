const env = import.meta.env;

export const MODE = (() => {
  let mode = env.MODE;

  // When using storybook, the variable is set with extra quotes around it,
  // and I cannot find how to remove it. The variables are defined in
  // .storybook/main.ts. If you can solve that problem, please remove this.
  if (mode === '"development"') {
    mode = 'development';
  }
  if (mode === '"production"') {
    mode = 'production';
  }

  return mode;
})() as 'development' | 'production';

/**
 * Takes an environment value and returns it as a boolean,
 * performing any transformations required given the current build system
 * @param s the environment value, string.
 * @returns the input as a boolean
 */
const booleanForBuildSystem = (s: string): boolean => {
  return s === 'true';
};

export const GOOGLE_API_KEY = env.APP_GOOGLE_API_KEY as string;
export const BACKEND_BASE_URL_MSD = env.APP_BACKEND_BASE_URL_MSD as string;
export const BACKEND_BASE_URL = env.APP_BACKEND_BASE_URL as string;
export const IMAGE_CATALOG_API = env.APP_IMAGE_CATALOG_API as string;
export const EPHEMERIDES_SERVICE_URL =
  env.APP_EPHEMERIDES_SERVICE_URL as string;
export const NODE_RED_INSTANCE_URL = env.APP_NODE_RED_INSTANCE_URL as string;
export const IMAGE_TILE_SERVER = env.APP_IMAGE_TILE_SERVER as string;

export const TILES_URL = env.APP_TILES_URL as string;
export const DOWNSAMPLE = env.APP_DOWNSAMPLE as string;
export const DOWNSAMPLE_BOOLEAN = env.APP_DOWNSAMPLE_BOOLEAN as string;
export const MOCK_API = booleanForBuildSystem(env.APP_MOCK_API as string);
export const MIN_AREA = env.APP_MIN_AREA as string;
export const BOTTOM_CORVE_OFFSET = env.APP_BOTTOM_CORVE_OFFSET as string;

export const LIMITATION_MAX_SATELLITES =
  env.APP_LIMITATION_MAX_SATELLITES as string;
export const LIMITATION_MAX_DAYS = env.APP_LIMITATION_MAX_DAYS as string;
export const LIMITATION_MAX_COMBINED =
  env.APP_LIMITATION_MAX_COMBINED as string;

export const DEFAULT_HOURS_IN_DATA_ZOOM =
  env.APP_DEFAULT_HOURS_IN_DATA_ZOOM as string;
export const MAXIMUM_POINT_IN_PLOTS = env.APP_MAXIMUM_POINT_IN_PLOTS as string;

export const SHOW_INTERNAL_SATELLITES = booleanForBuildSystem(
  env.APP_SHOW_INTERNAL_SATELLITES as string
);

export const SHOW_TOASTER_ON_TYPE_ASSERT_ERROR = booleanForBuildSystem(
  env.APP_SHOW_TOASTER_ON_TYPE_ASSERT_ERROR as string
);

export const LOGO = env.APP_LOGO_URL as string;

export const ENABLE_DARK_MODE = booleanForBuildSystem(
  env.APP_ENABLE_DARKMODE as string
);

export const ENABLE_SEND_FEEDBACK = booleanForBuildSystem(
  env.APP_ENABLE_SEND_FEEDBACK as string
);

export const ENABLE_UPGRADE_USER_ROLE = booleanForBuildSystem(
  env.APP_ENABLE_UPGRADE_USER_ROLE as string
);

export const ENABLE_USER_IMAGES = booleanForBuildSystem(
  env.APP_ENABLE_USER_IMAGES as string
);

export const AUTHENTICATION_PROVIDER =
  env.APP_AUTHENTICATION_PROVIDER as string;

export const DOCS_ENABLE = booleanForBuildSystem(env.APP_DOCS_ENABLE as string);

export const OPERATIONS_ENABLE = booleanForBuildSystem(
  env.APP_OPERATIONS_ENABLE as string
);
export const TERMS_AND_CONDITIONS_ENABLE = booleanForBuildSystem(
  env.APP_TERMS_AND_CONDITIONS_ENABLE as string
);
export const SENSOR_TYPE_FILTER_ENABLE = booleanForBuildSystem(
  env.APP_SENSOR_TYPE_FILTER_ENABLE as string
);

export const HOURS_OF_THE_DAY_FILTER_ENABLE = booleanForBuildSystem(
  env.APP_HOURS_OF_THE_DAY_FILTER_ENABLE as string
);

export const ANALYTICS_CLIENT = env.APP_ANALYTICS_CLIENT as string;
export const PLATFORM_TYPE_FILTER_ENABLE = booleanForBuildSystem(
  env.APP_PLATFORM_TYPE_FILTER_ENABLE as string
);

export const ORDER_SERVICE_ENDPOINT = env.APP_ORDER_SERVICE_ENDPOINT as string;

export const DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER = booleanForBuildSystem(
  env.APP_DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER as string
);

export const BANK_ACCOUNT_SOL = env.APP_BANK_ACCOUNT_SOL as string;
export const BANK_ACCOUNT_USD = env.APP_BANK_ACCOUNT_USD as string;

export const DATACOSMOS_CHECKOUT_CARD = booleanForBuildSystem(
  env.APP_DATACOSMOS_CHECKOUT_CARD as string
);

export const DATACOSMOS_SKIP_HOME = booleanForBuildSystem(
  env.APP_DATACOSMOS_SKIP_HOME as string
);

export const ENABLE_VECTOR_OPERATIONS = booleanForBuildSystem(
  env.APP_ENABLE_VECTOR_OPERATIONS as string
);

export const LANGUAGE = env.APP_LANGUAGE as string;

export const SHOW_MAIN_APP_TOUR = booleanForBuildSystem(
  env.APP_SHOW_MAIN_APP_TOUR as string
);

export const OAUTH2_LOGOUT_URI = env.OAUTH2_LOGOUT_URI as string;

export const PROJECT_ID_FOR_PDGS_LINK =
  env.APP_PROJECT_ID_FOR_PDGS_LINK as string;

export const FRONTEND_AUTHENTICATION_BASE_URL =
  env.APP_FRONTEND_AUTHENTICATION_BASE_URL as string;

export const INCLUDE_CHECKBOX_FOR_PURCHASE = booleanForBuildSystem(
  env.APP_INCLUDE_CHECKBOX_FOR_PURCHASE as string
);

export const HOST_URL = env.APP_HOST_BASE_URL as string;

export const PUBLIC_INTERMEDIATE_ROLE_ID =
  env.APP_PUBLIC_INTERMEDIATE_ROLE_ID as string;
