import type { ProviderView } from 'datacosmos/stores/ViewsProvider';
import { Button } from 'opencosmos-ui';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Props = {
  view?: ProviderView;
};

const ViewDescriptionCard = ({ view }: Props) => {
  const history = useHistory();
  const url = new URLSearchParams(window.location.search);
  url.delete('mode');

  const [isShown, setIsShown] = useState<boolean>(true);

  const { translate } = useLocalisation();

  if (!isShown) {
    return (
      <div
        className={
          'flex flex-col max-w-md absolute right-2 top-[60px] justify-between gap-2'
        }
      >
        <Button
          icon="ChevronLeftDouble"
          size={'base'}
          onPress={() => {
            setIsShown(!isShown);
          }}
        >
          {translate('datacosmos.views.expandDescription')}
        </Button>
      </div>
    );
  }

  return (
    <div
      className={
        ' dark:bg-surface-dark bg-surface dark:text-item-dark-contrast p-2 flex flex-col min-h-[128px] min-w-[450px] max-w-md absolute right-2 top-[60px] justify-between gap-2'
      }
    >
      <div className="flex flex-col gap-1 w-full text-sm break-all">
        <div className="text-lg">{view?.name}</div>
        <div>{view?.description}</div>
      </div>

      <div className="flex items-center justify-between">
        <Button
          onPress={() => {
            history.replace(window.location.pathname + '?' + url.toString());
          }}
        >
          {translate('datacosmos.views.editMode')}
        </Button>
        <Button
          icon="ChevronRightDouble"
          size={'lg'}
          isMinimal
          isTransparent
          onPress={() => {
            setIsShown(!isShown);
          }}
        />
      </div>
    </div>
  );
};

export default ViewDescriptionCard;
