import { Button, Intent, MenuItem } from '@blueprintjs/core';
import type { ItemRenderer } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import type { PermissionType } from '_api/administration/permissions';
import { useEffect, useState } from 'react';
import type { IRole } from 'services/api/portal/administration/api/types';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';

interface ResourceProps {
  selectedPermission: PermissionType | undefined;
  permissionTypes: PermissionType[];
  setSelectedPermission: (permission: PermissionType) => void;
  selectedRole: IRole | undefined | null;
  setSelectedRole: (role: IRole | null) => void;
  setResourceId: (resourceId: string | number) => void;
  getAvailableRolesByType: (type: PermissionType) => IRole[];
  resourceId: string | number;
  handleAdd: () => void;
}
const ResourceAndRoleAllocation = (props: ResourceProps) => {
  const [availableRoles, setAvailableRoles] = useState<IRole[]>([]);

  const { translateWithExplicitFallback } = useLocalisation();

  const ResourceSelect = Select.ofType<PermissionType>();
  const RoleSelect = Select.ofType<IRole>();

  const resourceRenderer: ItemRenderer<PermissionType> = (
    item,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const selected = props.selectedPermission === item;

    return (
      <MenuItem
        text={translateWithExplicitFallback(
          `datacosmos.resources.${item.toString()}` as unknown as TemplateStringsArray,
          item.toString()
        )}
        onClick={handleClick}
        active={selected}
        key={item}
      />
    );
  };

  const roleRenderer: ItemRenderer<IRole> = (
    item,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const selected = props.selectedRole?.id === item.id;
    return (
      <MenuItem
        text={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <b>{item.name}</b>
            <p style={{ whiteSpace: 'pre-wrap' }}>{item.description}</p>
          </div>
        }
        onClick={handleClick}
        active={selected}
        key={item.id}
      />
    );
  };

  useEffect(() => {
    if (!props.selectedPermission) return;
    setAvailableRoles(props.getAvailableRolesByType(props.selectedPermission));
  }, [props]);

  return (
    <div>
      <div>
        <label htmlFor="permissionSelect">
          {clientTranslate('userPage.resource')}
        </label>
        <div className={'grid shadow-none grid-cols-2 gap-2'}>
          <div className="bp4-select bp4-fill">
            <ResourceSelect
              itemRenderer={resourceRenderer}
              items={props.permissionTypes as unknown as PermissionType[]}
              onItemSelect={(perm) => {
                props.setSelectedPermission(perm);
                props.setSelectedRole(null);
              }}
              popoverProps={{ fill: true, minimal: true }}
              filterable={false}
            >
              <Button
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <span>
                  {translateWithExplicitFallback(
                    `datacosmos.resources.${
                      props.selectedPermission ?? ''
                    }` as unknown as TemplateStringsArray,
                    props.selectedPermission ?? ''
                  )}
                </span>
              </Button>
            </ResourceSelect>
          </div>

          <input
            type="text"
            placeholder={clientTranslate('userPage.resourceIdPlaceholder')}
            onKeyUp={(e) => props.setResourceId(e.currentTarget.value)}
            disabled={
              props.selectedPermission === 'global' || !props.selectedPermission
            }
            className="bp4-input"
          />
        </div>
      </div>

      <div className="mt-2">
        <label htmlFor="roleSelect">{clientTranslate('userPage.role')}</label>
        <div>
          <div className="bp4-select bp4-fill">
            <RoleSelect
              itemRenderer={roleRenderer}
              items={availableRoles
                .filter((r) => r.userAssignable)
                .sort((a, b) => a.name.localeCompare(b.name))}
              onItemSelect={(role) => props.setSelectedRole(role)}
              popoverProps={{ fill: true, minimal: true }}
              filterable={false}
            >
              <Button
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <span>
                  {props.selectedRole
                    ? props.selectedRole.name
                    : clientTranslate(
                        'userPage.assignedPermissions.selectARole'
                      )}
                </span>
              </Button>
            </RoleSelect>
          </div>

          <div className={'mt-4'}>
            <Button intent={Intent.PRIMARY} onClick={props.handleAdd}>
              {clientTranslate('userPage.buttons.add')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceAndRoleAllocation;
