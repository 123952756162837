import React, { useMemo } from 'react';
import { useAuthentication } from 'services/auth/useAuthentication';
import { useAuthorisation } from 'services/auth/useAuthorisation';
import { AuthContext } from 'services/auth/AuthWrapper';
import { newOpencosmosAuthBacked } from './opencosmosAuthBackend';
import ConfigureOrganisation from 'pages/shared/ConfigureOrganisation';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import conidaLogo from 'public/images/datacosmos/conida-logo.png';

interface IProps {
  children: React.ReactNode;
}

export const AuthProvider = (props: IProps) => {
  const authBackendProvider = useMemo(
    () => () => newOpencosmosAuthBacked(),
    []
  );

  const authentication = useAuthentication(authBackendProvider);
  const authorisation = useAuthorisation(
    authentication.user?.sub,
    authentication.token
  );

  if (authentication.loading || authorisation.loading) {
    return (
      <div className="w-full h-[100vh] flex flex-col items-center justify-center gap-8">
        <img src={conidaLogo} className="h-[10rem]" alt="Conida Logo" />
        <Spinner size={'10rem'} />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        ...authentication,
        ...authorisation,
        loading: false,
      }}
    >
      {authentication.user && !authorisation.isUserPartOfOrganisation ? (
        <ConfigureOrganisation user={authentication.user} />
      ) : (
        props.children
      )}
    </AuthContext.Provider>
  );
};
