import { flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { usePortalService } from 'services/api/portal/organisation/usePortalService';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import { getScenarios } from '_api/scenarios/service';
import { useQuery } from '_api/useQuery';
import { getViews } from '_api/views/service';

export const useAllResources = (user: IUserWithPermissions) => {
  const [resources, setResources] = useState<
    { id: string | number; name: string; type: string }[]
  >([]);

  const { organisationsList } = usePortalService(user.id);

  const { data: userScenarios } = useQuery(getScenarios, {
    initialData: [],
  });

  const { data: userViews } = useQuery(getViews, {
    initialData: [],
  });

  useEffect(() => {
    const organisation = organisationsList.map((org) => {
      return { name: org.name, id: org.id.toString(), type: 'organisation' };
    });

    const datacosmos_scenario = userScenarios.map((scen) => {
      return { name: scen.title, id: scen.id, type: 'datacosmos_scenario' };
    });
    const datacosmos_views = userViews.map((view) => {
      return { name: view.name, id: view.id, type: 'datacosmos_view' };
    });

    setResources(
      flatten([
        Object.values(datacosmos_scenario),
        Object.values(organisation),
        Object.values(datacosmos_views),
      ])
    );
  }, [organisationsList, userScenarios, userViews]);

  return { resources };
};
