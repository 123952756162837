import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useState } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { getKeycloakUser, UpdateUsersLevel } from '_api/users/service';
import RadioInput from '_molecules/RadioInput/RadioInput';
import { Dialog } from 'opencosmos-ui';
import UpgradeUserForm from './UpgradeUserForm';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'services/auth/AuthWrapper';

export type UpgradeUserPermissionProps = {
  text: string;
  openModalByDefault: boolean;
  closeModal?: (open: boolean) => void;
};

export const UpgradeUserPermission = ({
  text,
  openModalByDefault,
  closeModal,
}: UpgradeUserPermissionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(openModalByDefault);
  const [selecteduserType, setSelectedUserType] = useState<string>('');
  const { translate } = useLocalisation();
  const { token } = useAuth();

  const { data: detailsQueryData } = useQuery({
    queryKey: ['userDetails', token],
    queryFn: () =>
      getKeycloakUser({
        params: {},
        headers: { Authorization: `Bearer ${token ?? ''}` },
      }),
  });

  const handleUserUpgradeRequest = () => {
    void UpdateUsersLevel({
      params: { permission: selecteduserType },
    });
    setIsOpen(false);
    if (closeModal) {
      closeModal(false);
    }
    window.location.reload();
  };

  const RadioItemsList = [
    {
      name: translate('datacosmos.upgradePermissionsDialog.userType.private'),
      value: 'private',
      disabled: false,
      description: translate(
        'datacosmos.upgradePermissionsDialog.userType.privateTooltipText'
      ),
    },
    {
      name: translate(
        'datacosmos.upgradePermissionsDialog.userType.institutional'
      ),
      value: 'institution',
      disabled: false,
      description: translate(
        'datacosmos.upgradePermissionsDialog.userType.institutionalTooltipText'
      ),
    },
  ];

  const title = `${translate(
    'datacosmos.upgradePermissionsDialog.upgradePermissionsHeader'
  )}${
    selecteduserType === 'institution'
      ? ` > ${translate(
          'datacosmos.upgradePermissionsDialog.userType.institutional'
        )}`
      : ''
  }`;

  return (
    <>
      <a
        target="_blank"
        className="flex items-center p-2 dark:text-item-dark-contrast hover:no-underline whitespace-nowrap"
        rel="noreferrer"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Icon className="mr-1" icon={IconNames.CircleArrowUp} />
        {text}
      </a>

      <Dialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (closeModal) {
            closeModal(false);
          }
        }}
        title={title}
        buttons={[
          {
            text: translate(
              'datacosmos.upgradePermissionsDialog.sendButtonText'
            ),
            onPress: () => handleUserUpgradeRequest(),
            shown: selecteduserType === 'private',
          },
          {
            text: 'Volver',
            onPress: () => setSelectedUserType(''),
            shown: selecteduserType === 'institution',
            keepDialogOpenOnPress: true,
          },
        ]}
        cancelButtonText={translate('datacosmos.buttons.cancel')}
        style={{ maxWidth: '35%' }}
      >
        <div className="px-4">
          {selecteduserType !== 'institution' && (
            <>
              <div>
                {translate(
                  'datacosmos.upgradePermissionsDialog.permissionsBody'
                )}
              </div>
              <div className="mt-8">
                <RadioInput
                  style={{ height: '100%' }}
                  onChange={(event) => {
                    setSelectedUserType(event.target.value);
                  }}
                  radioName="userType"
                  radioItems={RadioItemsList}
                  label={{
                    text: translate(
                      'datacosmos.upgradePermissionsDialog.typeOfUser'
                    ),
                    position: 'top',
                  }}
                  className="my-2 flex items-center gap-2"
                />
              </div>
            </>
          )}

          {selecteduserType === 'institution' &&
            detailsQueryData?.data !== undefined && (
              <UpgradeUserForm
                user={detailsQueryData?.data}
                userError={
                  !detailsQueryData?.success ||
                  (detailsQueryData?.status ?? 0) >= 400
                }
              />
            )}
        </div>
      </Dialog>
    </>
  );
};

export default UpgradeUserPermission;
