import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';
import { useActivePage } from '../Toolbar/ActivePageProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { ListBoxItem, Select } from 'opencosmos-ui';
import type { LayerTool } from 'datacosmos/stores/LayerToolsProvider';
import {
  LAYER_TOOLS,
  useLayerTools,
} from 'datacosmos/stores/LayerToolsProvider';
import LayerGridCard from '../Layers/LayerCards/LayerGridCard';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { ScatterPlotForm } from './ScatterPlot';
import { CovarianceForm } from './Covariance';
import type { Key } from 'react';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import LayerListCard from '../Layers/LayerCards/LayerListCard';
import { HistogramForm } from './Histogram';
import { useMap } from 'datacosmos/stores/MapProvider';

const TOOL_FORMS = {
  [LAYER_TOOLS.SCATTER]: ScatterPlotForm,
  [LAYER_TOOLS.COVARIANCE]: CovarianceForm,
  [LAYER_TOOLS.SPECTRAL_ANALYSIS]: HistogramForm,
} as const;

const HEADER_HEIGHT = 60;

const LayerTools = () => {
  const { setIsLayerToolsOpen } = useActivePage();
  const { setLayersMenuOpen, layers } = useMapLayers();
  const { mapContainerRef } = useMap();
  const { translate } = useLocalisation();
  const { enabledTool, setEnabledTool, setData } = useLayerTools();
  const [isLayerGridView] = useLocalStorage('isLayerGridView', true);

  const selectedLayer = layers.find(
    (l) => l.options.isSelected && l instanceof SingleBandSTACLayer
  ) as SingleBandSTACLayer;

  const ToolForm = enabledTool ? TOOL_FORMS[enabledTool] : null;

  const handleToolSelection = (value: Key) => {
    setEnabledTool(value as LayerTool);
    setData(null);
  };

  const maxHeight = mapContainerRef?.current
    ? mapContainerRef?.current?.clientHeight - HEADER_HEIGHT
    : 'auto';

  return (
    <div style={{ maxHeight: 'inherit', overflowY: 'hidden' }}>
      <SidebarHeader
        title={translate('datacosmos.layers.tools.title')}
        rightIcon={{
          iconName: 'ChevronRight',
          onIconClick: () => {
            setLayersMenuOpen(true);
            setIsLayerToolsOpen(false);
          },
          text: translate('datacosmos.layers.backToLayers'),
        }}
        height={HEADER_HEIGHT}
      />
      <div
        style={{
          maxHeight,
          overflowY: 'auto',
        }}
      >
        {selectedLayer && (
          <div className="mb-0 m-2 pb-2 border-b border-b-surface-contrast dark:border-b-surface-dark-contrast">
            {isLayerGridView ? (
              <LayerGridCard layer={selectedLayer} readOnly />
            ) : (
              <LayerListCard layer={selectedLayer} readOnly />
            )}
          </div>
        )}

        <div className="p-4 flex flex-col gap-4">
          <Select
            label={translate('datacosmos.layers.tools.buttons.tool')}
            name="tool"
            selectedKey={enabledTool}
            onSelectionChange={handleToolSelection}
            placeholder={translate('datacosmos.layers.tools.selectPlaceholder')}
          >
            {Object.keys(TOOL_FORMS).map((t) => (
              <ListBoxItem id={t} key={t}>
                {translate(
                  `datacosmos.layers.tools.${t as unknown as LayerTool}.title`
                )}
              </ListBoxItem>
            ))}
          </Select>

          {ToolForm && selectedLayer && <ToolForm layerItem={selectedLayer} />}
        </div>
      </div>
    </div>
  );
};

export default LayerTools;
