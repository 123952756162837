import React, { useState } from 'react';
import { Input, Select, Button, ListBoxItem } from 'opencosmos-ui';
import { KeycloakUser, USER_UPGRADE_REQUEST_STATUS } from '_api/users/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateKeycloakUser } from '_api/users/service';
import { useAuth } from 'services/auth/AuthWrapper';

const ACTIVITIES = [
  'Personal de investigación',
  'Técnico o personal gestor',
  'Otros',
] as const;
const APPLICATIONS = [
  'No he utilizado imágenes con productos de valor agregado y/o derivado',
  'Gestión agrícola',
  'Conservación y biodiversidad',
  'Vigilancia costera',
  'Gestión de recursos culturales/antropología/arqueología',
  'Educación',
  'Academia e investigación',
  'Gestión de riesgos de desastres',
  'Ingeniería (construcción, topografía, geodesia)',
  'Oceanografia',
  'Pesqueria',
  'Defensa/seguridad nacional',
  'Desarrollo de software',
  'Meteorologia',
  'Planificación urbana y ordenamiento del territorio',
  'Cambio climatico',
  'Medio ambiente',
  'Criosfera',
  'Monitoreo de actividades ilegales y legales',
  'Salud publica',
  'Transporte y comunicaciones',
  'Gestion de recursos hídricos',
  'Geología/minería/petroleo',
  'Otro',
] as const;

function UpgradeUserForm({
  user,
  userError,
}: {
  user: KeycloakUser;
  userError?: boolean;
}) {
  const [formState, setFormState] = useState({
    organisation_name: '',
    organisation_area: '',
    organisation_activity: '',
    organisation_application: '',
  });
  const { token } = useAuth();
  const queryClient = useQueryClient();

  const {
    mutate: updateUserMutation,
    isPending,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: ({ userDetails }: { userDetails: KeycloakUser }) =>
      updateKeycloakUser({
        body: userDetails,
        params: {},
        headers: { Authorization: `Bearer ${token ?? ''}` },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userDetails', token] });
    },
  });

  const handleChange = (name: string, value: string | boolean) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const attributes = {
      ...user.attributes,
      ...formState,
      upgrade: USER_UPGRADE_REQUEST_STATUS.PENDING,
    };
    updateUserMutation({
      userDetails: { ...user, attributes },
    });
  };

  if (userError) {
    return (
      <span>
        Ha ocurrido un error al cargar los datos del usuario. Por favor intenta
        más tarde o contacta a un administrador.
      </span>
    );
  }

  if (isError) {
    return (
      <span>
        Ha ocurrido un error al enviar la solicitud. Por favor intenta más tarde
        o contacta a un administrador.
      </span>
    );
  }

  if (user.attributes?.upgrade || isSuccess) {
    return (
      <span>
        Has solicitado la actualización de nivel de usuario y la misma está
        siendo procesada.
      </span>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <fieldset
        disabled={isPending}
        className="flex flex-col gap-4 border-none"
      >
        <Input
          type="text"
          id="organisationName"
          name="organisation_name"
          label={{ text: 'Nombre de la institución', position: 'top' }}
          value={formState.organisation_name}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          required
        />
        <Input
          type="text"
          id="organisationArea"
          name="organisation_area"
          label={{
            text: 'Área o dependencia de trabajo dentro de la institución',
            position: 'top',
          }}
          value={formState.organisation_area}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          required
        />
        <Select
          fill
          id="organisationActivity"
          name="organisation_activity"
          label="Actividad"
          selectedKey={formState.organisation_activity}
          onSelectionChange={(value) =>
            handleChange('organisation_activity', value as string)
          }
          isRequired
        >
          {ACTIVITIES.map((activity) => (
            <ListBoxItem key={activity} id={activity}>
              {activity}
            </ListBoxItem>
          ))}
        </Select>
        <Select
          fill
          id="organisationApplication"
          name="organisation_application"
          label="Aplicación"
          selectedKey={formState.organisation_application}
          onSelectionChange={(value) =>
            handleChange('organisation_application', value as string)
          }
          isRequired
        >
          {APPLICATIONS.map((application) => (
            <ListBoxItem key={application} id={application}>
              {application}
            </ListBoxItem>
          ))}
        </Select>
        <span>
          Al enviar este formulario confirmas que estás de acuerdo con la{' '}
          <a
            href="https://cdn.www.gob.pe/uploads/document/file/1225819/dir_014_compressed__1_.pdf?v=1597110644"
            target="_blank"
            rel="noreferrer"
            className="font-bold underline text-accent"
          >
            {' '}
            Directiva
          </a>{' '}
          y que trabajas para una institución pública
        </span>
        <Button
          className="w-full"
          type="submit"
          intent="primary"
          isDisabled={Object.values(formState).some((value) => !value)}
          loading={isPending}
        >
          Enviar
        </Button>
      </fieldset>
    </form>
  );
}

export default UpgradeUserForm;
