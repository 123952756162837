import area from '@turf/area';
import type { PolygonLayer } from '_api/views/types';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import type { COGMetadataForRange } from 'datacosmos/services/tilingApi/cogMetadata';
import {
  useLayerTools,
  isHistogramData,
} from 'datacosmos/stores/LayerToolsProvider';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import type { Feature, Polygon } from 'geojson';
import { Button, MultiSelect } from 'opencosmos-ui';
import { useMemo, useRef, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  ReferenceLine,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
  LineChart,
  Line,
  ResponsiveContainer,
} from 'recharts';
import { useAuth } from 'services/auth/AuthWrapper';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import StatisticsSummary from './StatisticsSumary';
import { Colors } from '@blueprintjs/core';
import type { IAsset } from 'datacosmos/types/stac-types';

export type HistogramChartProps = {
  data: { [key: string]: COGMetadataForRange } | null;
  isMaximized?: boolean;
};

const BLUES = [Colors.BLUE1, Colors.BLUE2, Colors.BLUE3, Colors.BLUE4] as const;
const GREENS = [
  Colors.GREEN1,
  Colors.GREEN2,
  Colors.GREEN3,
  Colors.GREEN4,
] as const;
const REDS = [Colors.RED1, Colors.RED2, Colors.RED3, Colors.RED4] as const;

const QUALITATIVE_COLOR_SCHEME = [
  '#D1980B',
  '#9D3F9D',
  '#00A396',
  '#DB2C6F',
  '#8EB125',
  '#946638',
  '#7961DB',
] as const;

const getColorways = () => ({
  qualitative: [...QUALITATIVE_COLOR_SCHEME],
  red: [...REDS],
  green: [...GREENS],
  blue: [...BLUES],
});

const getHistogramColor = (
  name: string,
  colorway: { [key: string]: string[] }
) => {
  let colorKey = 'qualitative';
  if (name.toLowerCase().includes('red')) colorKey = 'red';
  if (name.toLowerCase().includes('blue')) colorKey = 'blue';
  if (name.toLowerCase().includes('green')) colorKey = 'green';

  if (colorway[colorKey].length > 0) {
    return colorway[colorKey].shift()!;
  }
  if (colorway.qualitative.length > 0) {
    return colorway.qualitative.shift()!;
  }
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export const HistogramChart = ({ data, isMaximized }: HistogramChartProps) => {
  const colorwaysRef = useRef({});
  const { isDarkmode } = useTheme();
  const { translate } = useLocalisation();
  const series = useMemo(() => {
    if (!data) return [];
    colorwaysRef.current = getColorways();
    const histograms =
      Object.entries(data).map(([name, metadata]) => {
        const seriesData = metadata.histogramData([name]).map((point) => {
          const xKey =
            Object.keys(point).find((key) => key.endsWith('X')) ?? 'X';
          const yKey =
            Object.keys(point).find((key) => key.endsWith('Y')) ?? 'Y';
          return { value: point[xKey], frequency: point[yKey] };
        });
        const colorSeed =
          metadata.metadata?.colorinterp?.at(0) !== 'gray'
            ? metadata.metadata?.colorinterp?.at(0) ?? name
            : name;
        return {
          name,
          data: seriesData,
          color: getHistogramColor(colorSeed, colorwaysRef.current),
        };
      }) ?? [];
    return histograms;
  }, [data]);
  const onlyOneBandSelected = data && Object.keys(data).length === 1;
  return (
    <div
      className="flex flex-col justify-center m-2"
      style={
        isMaximized ? { height: '90%' } : { width: '25dvw', height: '25dvh' }
      }
    >
      <ResponsiveContainer width="100%">
        <LineChart barSize={'25px'}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="value"
            stroke={isDarkmode ? '#E6E1DC' : '#191919'}
            tickFormatter={(d: string) => Number(d).toFixed(2)}
            domain={[0, 'maxData']}
            type="number"
            allowDataOverflow={true}
            allowDuplicatedCategory={false}
            tickSize={3}
            tick={{ fontSize: 13 }}
            label={{
              value: translate(
                'datacosmos.catalogAndItems.metadata.xAxisLabel'
              ),
              position: 'insideBottom',
              dy: 6,
              className: 'dark:fill-surface-dark-contrast',
            }}
          />
          <YAxis
            dataKey="frequency"
            stroke={isDarkmode ? '#E6E1DC' : '#191919'}
            tickSize={1}
            tick={{ fontSize: 13 }}
            label={{
              value: translate(
                'datacosmos.catalogAndItems.metadata.yAxisLabel'
              ),
              position: 'insideLeft',
              angle: -90,
              dy: 20,
              dx: -5,
              className: 'dark:fill-surface-dark-contrast',
            }}
          />
          {series.map(({ name, data: seriesData, color }) => (
            <Line
              key={name}
              name={name}
              data={seriesData}
              dataKey={`frequency`}
              stroke={color}
              fill={color}
              legendType={onlyOneBandSelected ? 'none' : 'plainline'}
              isAnimationActive={false}
            />
          ))}
          {onlyOneBandSelected && (
            <>
              <>
                <ReferenceLine
                  x={Object.values(data)
                    ?.at(0)
                    ?.getBandStatistics(1)
                    .mean?.toFixed(2)}
                  stroke={isDarkmode ? '#ffffff' : '#17202A'}
                />

                <Line
                  name={`${translate(
                    'datacosmos.catalogAndItems.metadata.meanValue'
                  )} ${
                    Number(
                      Object.values(data)
                        ?.at(0)
                        ?.getBandStatistics(1)
                        .mean?.toFixed(2)
                    ) ?? ''
                  }`}
                  dataKey="null"
                  stroke={isDarkmode ? '#ffffff' : '#17202A'}
                  legendType="plainline"
                />
              </>
              <>
                <ReferenceLine
                  x={Number(
                    Object.values(data)
                      ?.at(0)
                      ?.getBandStatistics(1)
                      .percentile_25?.toFixed(2)
                  )}
                  stroke={'#8E44AD'}
                ></ReferenceLine>

                <Line
                  name={`${translate(
                    'datacosmos.catalogAndItems.metadata.percentile25'
                  )} ${
                    Number(
                      Object.values(data)
                        ?.at(0)
                        ?.getBandStatistics(1)
                        .percentile_25?.toFixed(2)
                    ) ?? ''
                  }`}
                  dataKey="null"
                  stroke="#8E44AD"
                  legendType="plainline"
                />
              </>
              <>
                <ReferenceLine
                  x={Number(
                    Object.values(data)
                      ?.at(0)
                      ?.getBandStatistics(1)
                      .percentile_75?.toFixed(2)
                  )}
                  stroke={'#FFCA33'}
                ></ReferenceLine>

                <Line
                  name={`${translate(
                    'datacosmos.catalogAndItems.metadata.percentile75'
                  )} ${
                    Number(
                      Object.values(data)
                        ?.at(0)
                        ?.getBandStatistics(1)
                        .percentile_75?.toFixed(2)
                    ) ?? ''
                  }`}
                  dataKey="null"
                  stroke="#FFCA33"
                  legendType="plainline"
                />
              </>
            </>
          )}

          <Legend
            wrapperStyle={{
              paddingTop: '14px',
              fontSize: '12px',
            }}
          />
          <ChartTooltip />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

type ToolProps = {
  layerItem: SingleBandSTACLayer;
};

type SelectedBand = { id: string; value: string };

const isValidAsset = (asset: IAsset) => {
  return (
    asset.roles?.includes('data') &&
    !asset.roles?.includes('visual') &&
    Boolean(asset.href) &&
    asset.type?.includes('image/tiff') &&
    asset.type?.includes('application=geotiff') &&
    asset.type?.includes('profile=cloud-optimized')
  );
};

export const HistogramForm = ({ layerItem }: ToolProps) => {
  const [status, setStatus] = useState<'success' | 'loading' | 'error'>(
    'success'
  );
  const { layers, removeLayersBySourceType, addLayer } = useMapLayers();
  const statisticsAoi = useMemo(
    () =>
      layers.find((l) => l.sourceType === LayerSourceType.STATISTICS_AOI) as
        | PolygonLayer
        | undefined,
    [layers]
  );
  const [aoi, setAoi] = useState<Feature<Polygon> | null>(
    (statisticsAoi?.data as Feature<Polygon>) ?? null
  );
  const { translate } = useLocalisation();
  const { setData, selectedBands, setSelectedBands, data } = useLayerTools();
  const { token } = useAuth();
  const { drawPolygon } = useMap();

  const bands = useMemo(() => {
    return (
      Object.entries(layerItem?.item.assets ?? {})
        .filter(([_id, value]) => isValidAsset(value))
        .map(([id, value]) => ({ id, value: value.title ?? id })) ?? []
    );
  }, [layerItem]);

  const toggleBandSelection = (bandItem: SelectedBand) => {
    if (selectedBands?.find((band) => band.id === bandItem.id)) {
      setSelectedBands(selectedBands.filter((band) => band.id !== bandItem.id));
    } else {
      setSelectedBands([...selectedBands, bandItem]);
    }
  };

  const handleAoiChange = async () => {
    if (aoi) {
      setAoi(null);
      removeLayersBySourceType(LayerSourceType.STATISTICS_AOI);
    } else {
      const { polygon, polygonMetadata } = await drawPolygon();
      const aoiLayer = PolygonLayerFactory(
        LayerSourceType.STATISTICS_AOI,
        translate('datacosmos.layers.names.statisticsAoi'),
        polygon,
        area(polygon),
        polygonMetadata
      );
      addLayer(aoiLayer);
      setAoi(polygon);
    }
  };

  const handleGetAssetStatistics = async () => {
    if (!layerItem?.item?.collection) {
      return;
    }
    setStatus('loading');
    const paramsUrl = `item=${layerItem?.item?.id}&collection=${layerItem?.item?.collection}`;
    const selectedBandsIds = selectedBands.map(({ id }) => id);
    const assets = Object.entries(layerItem.item.assets)
      .filter(([key]) => selectedBandsIds.includes(key))
      .map(([id, value]) => ({ id, ...value }));
    // This request could fetch all bands at once, but sometimes
    // the tiling service timeouts (especially with large AoIs),
    // so we fetch each band separately
    const cogData = await Promise.all(
      assets.map((asset) =>
        tilingApi.fetchMetadataForCOG(
          `${paramsUrl}&assets=${asset.id}`,
          token,
          aoi ?? undefined
        )
      )
    );

    if (!cogData || cogData.some((x) => !x)) {
      setStatus('error');
      return;
    }

    setData(
      Object.fromEntries(
        cogData.map((value, i) => [
          assets.at(i)?.title ?? assets.at(i)?.id ?? `Band ${i}`,
          value,
        ])
      )
    );
    setStatus('success');
  };

  return (
    <div className="flex flex-col w-full gap-4">
      <MultiSelect
        fill
        isDismissible
        autoAdjustHeight
        name="bands"
        label={translate('datacosmos.layers.tools.buttons.bands')}
        className="flex flex-col justify-start items-start"
        items={bands}
        defaultSelectedKeys={selectedBands.map(({ id }) => id)}
        onSelectionChange={toggleBandSelection}
        onRemove={toggleBandSelection}
        onRemoveAll={() => setSelectedBands([])}
        onSelectAll={() => setSelectedBands([...bands])}
        optionLabels={{
          selectAll: translate('datacosmos.layers.tools.selectAll'),
          removeAll: translate('datacosmos.layers.tools.removeAll'),
        }}
        placeholder={translate('datacosmos.layers.tools.selectPlaceholder')}
      />
      <Button
        onPress={handleAoiChange}
        className={`w-full ${status === 'loading' ? 'cursor-not-allowed' : ''}`}
        icon={aoi ? 'Trash' : 'AoiAdd'}
      >
        {translate(
          `datacosmos.buttons.${
            aoi ? 'removeAoiForStatistics' : 'drawAoiForStatistics'
          }`
        )}
      </Button>
      <Button
        onPress={handleGetAssetStatistics}
        className={`w-full ${status === 'loading' ? 'cursor-not-allowed' : ''}`}
        loading={status === 'loading'}
        intent="primary"
      >
        {translate('datacosmos.layers.tools.buttons.submit')}
      </Button>
      {status === 'error' && (
        <span className="text-warning">
          {translate('datacosmos.layers.tools.errors.request')}
        </span>
      )}
      {isHistogramData(data) && Object.values(data).length === 1 && (
        // Only show statistics summary if only one band is selected
        <StatisticsSummary
          statistics={Object.values(data).at(0)?.getBandStatistics(1)!}
        />
      )}
    </div>
  );
};
