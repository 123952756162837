import { apiFetchHandler } from '_api/clientFetch';
import type { Customer, Order, Price, PaymentMethod } from './types';
import { hosts } from '_api/hosts';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import { ORDER_SERVICE_ENDPOINT } from 'env';
import { toaster } from 'toaster';
import { momentObjToISOString } from 'utils/common/dateUtils';
import moment from 'moment';
import { ISO_FORMAT_NO_MILLISECONDS } from 'constants/datetime';

export type GetImagePriceParams = {
  collection: string;
  itemId: string;
};

export const getImagePrice = apiFetchHandler<Price, GetImagePriceParams>({
  host: hosts.data.v0,
  endpoint: ({ collection, itemId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/price?collection=${collection}&item=${itemId}`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.price.cannotGet'
  ),
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

export type GetOrdersListParams = {
  page: number;
  start_time?: Date;
  end_time?: Date;
};

export type GetOrdersResponse = {
  data: Order[];
  errors: null;
  meta: {
    current_page: number;
    per_page: number;
    total_pages: number;
    total_results: number;
    has_more: boolean;
    paginated: boolean;
  };
};

export const getOrdersList = apiFetchHandler<
  GetOrdersResponse,
  GetOrdersListParams
>({
  host: hosts.data.v0,
  endpoint: ({ page, start_time, end_time }) => {
    let baseAPIURL = `/${ORDER_SERVICE_ENDPOINT}/orders?page=${page}&limit=20`;
    if (start_time) {
      baseAPIURL += `&start_time=${momentObjToISOString(
        moment(start_time),
        ISO_FORMAT_NO_MILLISECONDS
      )}`;
    }
    if (end_time) {
      baseAPIURL += `&end_time=${moment(end_time)
        .endOf('day')
        .format(ISO_FORMAT_NO_MILLISECONDS)}`;
    }
    return baseAPIURL;
  },
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.orderList.cannotGet'
  ),
  method: 'GET',
  errorDescription: (e) => e.join('; '),
  omitCaptureDataKey: true,
});

export type GetCheckoutResponse = {
  checkout_url: string;
};
export type GetCheckoutParams = {
  orderId: string;
};
export const getOrderCheckoutURL = apiFetchHandler<
  GetCheckoutResponse,
  GetCheckoutParams
>({
  host: hosts.data.v0,
  endpoint: ({ orderId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}/checkout`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.checkout.cannotGetURL'
  ),
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

export type GetCusomerByIdParams = {
  id: string;
};

export const getCustomerById = apiFetchHandler<Customer, GetCusomerByIdParams>({
  endpoint: ({ id }) => `/${ORDER_SERVICE_ENDPOINT}/customer/${id}`,
  host: hosts.data.v0,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.customers.cannotGet'
  ),
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

export type CreateOrderItemBody = {
  collection: string;
  item: string;
  level: string;
};

export type CreateOrderBody = {
  type: string;
  data: {
    order_line_items: CreateOrderItemBody[];
    payment_method: PaymentMethod;
  };
  organisation: number;
  external_payment_id?: string;
};

export const createOrder = apiFetchHandler<Order, {}, CreateOrderBody>({
  endpoint: `/${ORDER_SERVICE_ENDPOINT}/orders`,
  method: 'POST',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotPost'
  ),
  host: hosts.data.v0,
  onSuccess: () => {
    toaster.show({
      message: clientTranslate(
        'datacosmos.fetchSuccesses.orders.successfullyCreated'
      ),
      intent: 'success',
    });
  },
  errorDescription: (e) => e.join('; '),
});

export type CreatePreferencesParams = {
  orderId: string;
};

export type CreatePreferencesRes = {
  preference_id: string;
};

export const createPreferences = apiFetchHandler<
  CreatePreferencesRes,
  CreatePreferencesParams
>({
  endpoint: ({ orderId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}/mercado-pago`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotPostPreferences'
  ),
  host: hosts.data.v0,
  method: 'POST',
  errorDescription: (e) => e.join('; '),
});

export type UpdateOrderParams = CreatePreferencesParams;
export type UpdateOrderBody = Partial<Order>;

export const updateOrder = apiFetchHandler<
  undefined,
  CreatePreferencesParams,
  UpdateOrderBody
>({
  endpoint: ({ orderId }) => `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}`,
  method: 'PATCH',
  host: hosts.data.v0,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotPatch'
  ),
  errorDescription: (e) => e.join('; '),
});

export type GetBillingPortalResponse = {
  portal_link: string;
};

export type GetBillingPortalParams = {
  organisationId: string;
};

type uploadOrderPaymentImageBody = FormData;

export const postOrderPaymentImage = apiFetchHandler<
  undefined,
  CreatePreferencesParams,
  uploadOrderPaymentImageBody
>({
  endpoint: ({ orderId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}/payment-image`,
  method: 'POST',
  contentType: 'multipart/form-data',
  host: hosts.data.v0,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotPatch'
  ),
  errorDescription: (e) => e.join('; '),
});

type OrderPaymentImageResponse = Blob;

export const getOrderPaymentImage = apiFetchHandler<
  OrderPaymentImageResponse,
  CreatePreferencesParams
>({
  endpoint: ({ orderId }) =>
    `/${ORDER_SERVICE_ENDPOINT}/orders/${orderId}/payment-image`,
  method: 'GET',
  host: hosts.data.v0,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.orders.order.cannotGetPaymentImage'
  ),
  errorDescription: (e) => e.join('; '),
  onError: ({ state }) => {
    if (state.status !== 200) {
      // This indicates no payment image uploaded. No toaster for this case
      return true;
    }
    return false;
  },
});
