import React, {
  useContext,
  createContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import extent from '@turf/bbox';
import imageCatalogApi from 'datacosmos/services/imageCatalogApi';
import { useAuth } from 'services/auth/AuthWrapper';
import { useRouteMatch } from 'react-router';
import { routeCatalog } from 'datacosmos/components/routePath';
import { useStacAdapter } from 'datacosmos/services/stacAdapter';
import { transformRBushBBox } from 'datacosmos/utils/ItemBush';

export type IImageCatalogContext = ReturnType<typeof useImageCatalogProvider>;

export const ImageCatalogContext = createContext<IImageCatalogContext>(
  null as unknown as IImageCatalogContext
);

export const useImageCatalog = () =>
  useContext<IImageCatalogContext>(ImageCatalogContext);

export const useImageCatalogProvider = () => {
  const { token } = useAuth();
  const catalogApi = useMemo(() => imageCatalogApi(token), [token]);
  const stacAdapter = useStacAdapter(catalogApi);

  const {
    setStacSearchPageCursor,
    indexedScenarioItems,
    fetchImages,
    setSearchResults,
  } = stacAdapter;

  const catalogRouteMatch = useRouteMatch<{ projectId?: string }>(routeCatalog);
  const getItemsInFootprint = useCallback(
    (squareFootprint: GeoJSON.GeoJSON) => {
      if (!catalogRouteMatch?.isExact) {
        return [];
      }
      return indexedScenarioItems.search(
        transformRBushBBox(extent(squareFootprint))
      );
    },
    [catalogRouteMatch, indexedScenarioItems]
  );

  /**
   * Updates the displayed images as well as supporting information
   * such as the user's scenarios, currently selected scenario
   */
  const updateDisplayedImages = async () => {
    setStacSearchPageCursor(undefined);
    const images = await fetchImages();
    if (images) {
      setSearchResults([...images.features]);
    }
  };

  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (!catalogRouteMatch?.isExact) return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      void updateDisplayedImages();
    }, 200);
  }, [
    catalogRouteMatch?.isExact,
    fetchImages,
    setStacSearchPageCursor,
    setSearchResults,
  ]);

  return {
    getItemsInFootprint,
    stacAdapter,
    updateDisplayedImages,
  };
};

export const ImageCatalogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ImageCatalogContext.Provider value={useImageCatalogProvider()}>
      {children}
    </ImageCatalogContext.Provider>
  );
};
